import {
  requiredValidator, emailValidator, passwordLengthValidator, equipmentValidator,
} from './validators';

export const loginClientValidatorsMap = {
  username: [requiredValidator],
  password: [passwordLengthValidator],
};

export const signUpClientValidatorsMap = {
  name: [requiredValidator],
  customer: [requiredValidator],
  email: [emailValidator],
  password: [passwordLengthValidator],
};

export const recoveryValidatorsMap = {
  email: [emailValidator],
};

export const createTaskValidatorsMap = {
  title: [requiredValidator],
  taskDescription: [requiredValidator],
  annualSavings: [requiredValidator],
  capitalRequired: [requiredValidator],
  statusData: [requiredValidator],
  categoryData: [requiredValidator],
  associatedEquipmentData: [requiredValidator, equipmentValidator],
};
