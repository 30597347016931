import React, { useEffect, useState, memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SVG from '../SVG';
import {
  DatepickerWrapper,
  GlobalStyle,
  CloseIcon,
} from './styles';

const DatePickerContainer = memo(({ date, onDatesSelect }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  }, [date]);

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
    onDatesSelect(dates);
  };

  const handleResetClick = () => {
    onDatesSelect([null, null]);
  };

  return (
    <DatepickerWrapper>
      <GlobalStyle />
      {
        startDate && endDate && <CloseIcon onClick={handleResetClick}>
          <SVG.CloseIcon />
        </CloseIcon>
      }
      <DatePicker
        inline
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
      />
    </DatepickerWrapper>
  );
});

export default DatePickerContainer;
