import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import logo1 from './images/logo1.png';
import logo2 from './images/logo2.png';

import {
  LogoContainer,
  ImageContainer,
} from './styles';

const LogoAuthentication = ({ location, history }) => {
  const isSignUp = useMemo(() => location.pathname === '/sign-up', []);

  const handleLogoClick = () => {
    history.push('/');
  };

  return (
    <LogoContainer>
      <ImageContainer
        isSignUp={isSignUp}
        onClick={isSignUp && handleLogoClick}
      >
        <img src={logo1} />
        <img src={logo2} />
      </ImageContainer>
    </LogoContainer >
  );
};

export default withRouter(LogoAuthentication);
