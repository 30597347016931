import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import format from 'date-fns/format';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS, TRANSITIONS } from '../../../styles/Theme';
import FacilityInfoBlock from '../../components/FacilityInfoBlock/FacilityInfoBlock.jsx';
import MyUtilityBlock from '../../components/MyUtilityBlock/MyUtilityBlock.jsx';
import LineHorizontal2Chart from '../../components/charts/LineHorizontal2Chart/LineHorizontal2Chart.jsx';
import getUtilityEnergyCost from '../../../services/getUtilityEnergyCost';
import { BACKGROUND_COLOR, BORDER_COLOR, LABELS_MONTH } from '../../../constants/ui';
import Typography from '../../components/Typography';
import getUtilityEnergyUsageIndex from '../../../services/getUtilityEnergyUsageIndex';
import DoughnutChart2 from '../../components/charts/DoughnutChart2/DoughnutChart2.jsx';
import getUtilityTotalEnergyCost from '../../../services/getUtilityTotalEnergyCost';
import getUtilityEnergyUsageBTU from '../../../services/getUtilityEnergyUsageBTU';
import getUtilities from '../../../services/getUtilities';
import formatToStringGap from '../../../utils/formatToStringGap';
import Footer from '../../components/Footer/Footer.jsx';
import { less } from '../../../styles';

const LABELS = { labels: LABELS_MONTH };
const TITLES = ['Cost', 'Usage'];

const UtilityDetailPage = memo(({ isVisible }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);
  const [totalEnergyCost, setTotalEnergyCost] = useState(LABELS);
  const [energyUsageIndex, setEnergyUsageIndex] = useState(LABELS);
  const [energyCost, setEnergyCost] = useState([]);
  const [energyUsageBTU, setEnergyUsageBTU] = useState([]);
  const [currentlyUsageIndex, setCurrentlyUsageIndex] = useState('0');

  // TOTALS
  const [totalsChartsBlock, setTotalsChartsBlock] = useState([]);

  useEffect(() => {
    if (organizationId) {
      Promise.allSettled([
        dispatch(getUtilityTotalEnergyCost()),
        dispatch(getUtilityEnergyUsageIndex()),
        dispatch(getUtilityEnergyCost()),
        dispatch(getUtilityEnergyUsageBTU()),
        dispatch(getUtilities()),
      ])
        .then(([
          { value: totalEnergyCost },
          { value: usageIndex },
          { value: energyCost },
          { value: energyUsageBTU },
          { value: totalCharts },
        ]) => {
          const totalEnergyCostData = getChartData(totalEnergyCost, 'cost');
          const energyUsageIndexData = getChartData(usageIndex, 'energyUsageIndex');
          // TOTAL CHARTS
          const totalChartBlock = !isEmpty(totalCharts) && totalCharts
            .map((res) => {
              const energyCost = getChartData(res.energyCost, 'cost', true);
              const energyUsage = getChartData(res.energyUsage, 'usage', true);

              const accounts = res.accounts.map((account) => ({
                ...account,
                costMonthly: {
                  ...account.costMonthly,
                  ...LABELS,
                  datasets: getChartData(account.costMonthly, 'costPerUnit', true),
                },
              }));

              const chartEnergyCost = ({ ...res.energyCost, ...LABELS, datasets: energyCost });
              const chartEnergyUsage = ({ ...res.energyUsage, ...LABELS, datasets: energyUsage });

              return ({
                name: res.name,
                accounts,
                charts: [
                  chartEnergyCost,
                  chartEnergyUsage,
                ],
              });
            });
          setTotalsChartsBlock(totalChartBlock);
          setTotalEnergyCost({ ...totalEnergyCost, datasets: totalEnergyCostData });
          setEnergyUsageIndex({ ...energyUsageIndex, datasets: energyUsageIndexData });
          setCurrentlyUsageIndex(() => (!isEmpty(energyUsageIndexData)
            ? Math.trunc(energyUsageIndexData[energyUsageIndexData.length - 1]
              .data[energyUsageIndexData[energyUsageIndexData.length - 1].data.length - 1])
            : '0'));
          setEnergyCost(() => energyCost.map(({ type, percentage }) => ({
            name: type,
            value: percentage,
          })));
          setEnergyUsageBTU(() => energyUsageBTU.map(({ type, percentage }) => ({
            name: type,
            value: percentage,
          })));
        });
    }
  }, [organizationId]);

  const getUniqueYear = (data) => [...new Set(data.map((item) => item.year))]
    .sort((a, b) => (a > b ? 1 : -1));

  const getInitialChartData = (i, year) => ({
    type: 'line',
    borderColor: BORDER_COLOR[i],
    backgroundColor: BACKGROUND_COLOR[i],
    fill: 'start',
    label: year,
    borderWidth: 2,
  });

  const getChartData = (data, name, isField) => {
    const charts = getUniqueYear(data).map((year, i) => {
      const newData = data.filter((item) => item.year === year);
      const dataWithIndex = [];
      newData.forEach((i) => {
        dataWithIndex[+i.month - 1] = !isField
          ? +i[name].value.toFixed(2)
          : +i[name].toFixed(2);
      });
      return ({
        ...getInitialChartData(i, year),
        data: dataWithIndex,
      });
    });

    return charts;
  };

  return (
    <MainPageContainer isVisible={isVisible}>
      <MainContentGrid>

        <FacilityInfoBlock />

        <FacilityContainer>
          <MyUtilityBlock />
          <TotalEnergyCostBlock>
            <Typography.TextS cssUnique={BlockChartTitle}>
              Total Energy Cost ($)
            </Typography.TextS>
            <LineHorizontal2Chart data={totalEnergyCost} />
          </TotalEnergyCostBlock>
        </FacilityContainer>

        <EnergyUsageContainer>
          <EnergyUsageIndex>
            <Typography.TextS cssUnique={BlockChartTitle}>
              {`Energy Usage Index (Currently ${currentlyUsageIndex || '0'} kBtu / f2 / yr)`}
            </Typography.TextS>
            <LineHorizontal2Chart leftTitle="kBtu / f2 / yr" data={energyUsageIndex} />
          </EnergyUsageIndex>
          <EnergyUsageDoughnuts>
            {
              !isEmpty(energyUsageBTU)
              && <DoughnutChartContainer>
                <Typography.TextS cssUnique={BlockChartTitle}>
                  Energy Usage (BTU) by Utility (%)
                </Typography.TextS>
                <DoughnutChart2 data={energyUsageBTU} />
              </DoughnutChartContainer>
            }
            {
              !isEmpty(energyCost)
              && <DoughnutChartContainer>
                <Typography.TextS cssUnique={BlockChartTitle}>
                  Energy Cost by Utility (%)
                </Typography.TextS>
                <DoughnutChart2 data={energyCost} />
              </DoughnutChartContainer>
            }
          </EnergyUsageDoughnuts>
        </EnergyUsageContainer>
        <ChartsContainer>
          {
            totalsChartsBlock && totalsChartsBlock.map((block) => <>
              <MainTitleBlockChart>
                <Typography.H6 cssUnique={BlockTitle}>
                  {`${block.name} Utilities`}
                </Typography.H6>
              </MainTitleBlockChart>
              {
                block.charts.map((chart, i) => (
                  <ChartContainer key={i}>
                    <TotalEnergyCostBlock>
                      <Typography.TextS cssUnique={BlockChartTitle}>
                        {`${block.name} Energy ${TITLES[i]}`}
                      </Typography.TextS>
                      <LineHorizontal2Chart data={chart} />
                    </TotalEnergyCostBlock>
                  </ChartContainer>
                ))
              }
              {
                block.accounts.map((account, i) => (
                  <>
                    <AccountTitle key={i}>
                      <Typography.TextS cssUnique={BlockSubTitle}>
                        {`${account.name === 'NA' ? 'Utility Provider Not Set' : account.name}: ${account.number}`}
                      </Typography.TextS>
                    </AccountTitle>
                    <TotalBlockContainer>
                      <TotalChartData>
                        <InfoContainer>
                          <ItemContainer>
                            <Typography.TextS
                              cssUnique={ItemTitle}
                            >
                              Total Cost
                            </Typography.TextS>

                            <Typography.TextS
                              cssUnique={ItemValue}>
                              {`$${formatToStringGap(account.totalCost)}`}
                            </Typography.TextS>
                          </ItemContainer>

                          <ItemContainer>
                            <Typography.TextS
                              cssUnique={ItemTitle}
                            >
                              Data Range
                            </Typography.TextS>

                            <Typography.TextS
                              cssUnique={ItemValue}
                            >
                              {`${format(new Date(account.startDate), 'MM/dd/yyyy')} - ${format(new Date(account.endDate), 'MM/dd/yyyy')}`}
                            </Typography.TextS>
                          </ItemContainer>
                          <ItemContainer>
                            <Typography.TextS
                              cssUnique={ItemTitle}
                            >
                              Units
                            </Typography.TextS>

                            <Typography.TextS
                              cssUnique={ItemValue}
                            >
                              {`${account.units.join(', ').slice(0, -2)}`}
                            </Typography.TextS>
                          </ItemContainer>
                        </InfoContainer>
                        <TotalEnergyCostBlock>
                          <Typography.TextS cssUnique={BlockChartTitle}>
                            Utility Contact Information
                          </Typography.TextS>
                          <TotalTable>
                            <YearItem>
                              <YearTitleItem>
                                <Typography.TextS cssUnique={TotalItemTitle}>
                                  Year
                                </Typography.TextS>
                              </YearTitleItem>
                              <YearCostItem>
                                <Typography.TextS cssUnique={TotalItemTitle}>
                                  Cost
                                </Typography.TextS>
                              </YearCostItem>
                            </YearItem>
                            {
                              account.costYearly.slice(0, -1).map((year, i) => (
                                <YearItem key={i}>
                                  <YearTitleItem>
                                    <Typography.TextS>
                                      {year.year}
                                    </Typography.TextS>
                                  </YearTitleItem>
                                  <YearCostItem>
                                    <Typography.TextS>
                                      {`$${formatToStringGap(year.cost)}`}
                                    </Typography.TextS>
                                  </YearCostItem>
                                </YearItem>
                              ))
                            }
                          </TotalTable>
                          <TotalItem>
                            <YearItem>
                              <Typography.TextS cssUnique={TotalItemTitle}>
                                Total
                              </Typography.TextS>
                            </YearItem>
                            <YearItem>
                              <Typography.TextS cssUnique={TotalItemTitle}>
                                {`$${formatToStringGap(account.costYearly.slice(-1)[0].cost)}`}
                              </Typography.TextS>
                            </YearItem>
                            {Array(3).fill('').map((i) => <YearItem key={i} />)}
                          </TotalItem>
                        </TotalEnergyCostBlock>
                      </TotalChartData>
                    </TotalBlockContainer>
                    <TotalBlockContainer>
                      <TotalEnergyCostBlock>
                        <Typography.TextS cssUnique={BlockChartTitle}>
                          {`Cost per Therm (${format(new Date(account.startDate), 'MM/dd/yyyy')} - ${format(new Date(account.endDate), 'MM/dd/yyyy')})`}
                        </Typography.TextS>
                        <LineHorizontal2Chart data={account.costMonthly} />
                      </TotalEnergyCostBlock>
                    </TotalBlockContainer>
                  </>
                ))
              }
            </>)
          }
        </ChartsContainer>
      </MainContentGrid>
      <Footer />
    </MainPageContainer>
  );
});

UtilityDetailPage.propTypes = {
  isVisible: PropTypes.bool,
};

export default UtilityDetailPage;

const MainPageContainer = styled.div`
    width: 100%;
    transition: ${TRANSITIONS.FAST};
    padding: 17px 24px 16px;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    margin-top: 38px;
    min-height: calc(100vh - 110px);
`;

const MainContentGrid = styled.div`
    display: grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    grid-template-rows: 94px 1fr;
    grid-template-areas: "facilityInfo facilityInfo"
                         "facilityAccount energyUsageIndex"
                         "charts charts";
    grid-gap: 16px;
    height: 100%;
`;

const FacilityContainer = styled.div`
    grid-area: facilityAccount;
    display: flex;
    flex-direction: column;
`;
const EnergyUsageContainer = styled.div`
    grid-area: energyUsageIndex;
    display: flex;
    flex-direction: column;
`;

const BlockChartTitle = css`
    color: ${COLORS.BLUE};
    font-weight: bold;
    text-align: center;
    padding: 6px 0;
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 8px;
    background: ${COLORS.WHITE};
`;

const TotalEnergyCostBlock = styled.div`
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    background: ${COLORS.WHITE};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    /* height: -webkit-fill-available; */

    canvas {
      width: 100% !important;
      padding: 16px;
      margin: auto;
    }
`;

const TotalChartData = styled.div`
  box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
  background: ${COLORS.WHITE};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  justify-content: space-around;

  & > div:last-child {
    height: auto;
  }
`;

const EnergyUsageIndex = styled.div`
  box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
  border-radius: 16px;
  background: ${COLORS.WHITE};
  /* height: calc(60% - 16px); */
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  canvas {
    width: 100% !important;
    padding: 16px;
    margin: auto;
  }
`;

const EnergyUsageDoughnuts = styled.div`
  display: flex;
  height: 40%;
`;

const DoughnutChartContainer = styled.div`
  box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
  border-radius: 16px;
  background: ${COLORS.WHITE};
  width: calc(50% - 8px);

  &:not(:last-child) {
    margin-right: 16px;
  };
`;

const ChartsContainer = styled.div`
  grid-area: charts;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 8px;
`;

const AccountTitle = styled.div`
  background: ${COLORS.LIGHTER_BLUE};
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;

  & + div {
    & + div {
      padding-left: 8px;
    }
    padding-right: 8px;
  }

  ${less.tabletL(css`
    & + div {
      & + div {
        padding: 0;
      }
      padding: 0;
      margin-bottom: 8px;
    }
  `)}
`;

const BlockSubTitle = css`
  color: ${COLORS.BLUE};
  font-weight: bold;
`;

const BlockTitle = css`
  font-weight: bold;
  color: white;
`;

const MainTitleBlockChart = styled.div`
  background: linear-gradient(51.64deg, #3772B6 -15.06%, #1D4E86 139.87%);
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 28px;
  margin-top: 16px;

  & + div {
    & + div {
      padding-left: 8px;
    }
    padding-right: 8px;
  }

  ${less.tabletL(css`
    & + div {
      & + div {
        padding: 0;
      }
      padding: 0;
      margin-bottom: 8px;
    }
  `)}
`;

const ChartContainer = styled.div`
  width: 50%;

  ${less.tabletL(css`
    width: 100%;
  `)}
`;

const TotalBlockContainer = styled.div`
  width: 50%;

  ${less.tabletL(css`
    width: 100%;
  `)}
`;

const InfoContainer = styled.div`
  margin-bottom: 16px;
`;

const ItemContainer = styled.div`
  display: flex;
  margin-bottom: 5px;
  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemTitle = css`
  margin-right: 8px;
  font-weight: bold;
  color: ${COLORS.BLACK_LIGHT};
  min-width: 75px;
`;

const ItemValue = css`
  color: ${COLORS.BLACK_LIGHT};
`;

const TotalTable = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 24px;
`;

const YearItem = styled.div`
  min-width: 65px;
  white-space: nowrap;
`;
const YearTitleItem = styled.div``;
const YearCostItem = styled.div``;

const TotalItemTitle = css`
  font-weight: bold;
  color: ${COLORS.BLACK_LIGHT};
`;

const TotalItem = styled.div`
  display: flex;
  padding: 10px 24px;
  border-top: 1px solid ${COLORS.LIGHTER_BLUE};
`;
