import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Typography from '../Typography';

import {
  LoginContainer,
  LoginInnerContainer,
  LoginTitle,
  LoginFormContainer,
  StyledInput,
  ForgotPassTitle,
  ButtonSubmit,
  ErrorMessage,
} from './styles';
import { loginClientValidatorsMap } from '../../../utils/validatorsMap';
import login from '../../../services/login';
import account from '../../../services/account';
import loginSync from '../../../services/loginSync';

const FIELDS = ['username', 'password'];

const LoginForm = memo(({ history }) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isUsernameValid, setIsUsernameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [error, setError] = useState('');
  const [isSubmitBlocked, setIsSubmitBlocked] = useState(true);

  useEffect(() => {
    setIsSubmitBlocked(validate(true));
  }, [username, password]);

  const handleUsernameChanges = useCallback((value) => {
    setUsername(value);
  }, []);

  const handlePasswordChanges = useCallback((value) => {
    setPassword(value);
  }, []);

  const redirectToMain = () => {
    history.push('/');
  };

  const validate = (isButton) => {
    let isValid = true;

    const field = {
      username,
      password,
    };

    const fieldChangeState = {
      username: setIsUsernameValid,
      password: setIsPasswordValid,
    };

    FIELDS.forEach((name) => {
      let errorTexts;
      const validators = loginClientValidatorsMap[name];

      validators && validators.forEach((validator) => {
        if (errorTexts) {
          return;
        }

        const filedErrorTexts = validator(field[name]);

        if (!filedErrorTexts) {
          isValid = false;
          errorTexts = filedErrorTexts;
        }
      });

      !isButton && fieldChangeState[name](errorTexts === undefined);
    });
    return !isValid;
  };

  const onBlur = (name) => () => {
    const field = {
      username,
      password,
    };

    const fieldChangeState = {
      username: setIsUsernameValid,
      password: setIsPasswordValid,
    };

    let errorTexts;
    const validators = loginClientValidatorsMap[name];

    validators
      && validators.forEach((validator) => {
        if (errorTexts) {
          return;
        }

        const isEmptyString = field[name].trim() === '';
        const filedErrorTexts = isEmptyString || validator(field[name]);

        if (filedErrorTexts) {
          errorTexts = filedErrorTexts;
        }
      });

    fieldChangeState[name](!!errorTexts);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isNotValid = validate();

    if (isNotValid) {
      return;
    }

    const data = {
      username,
      password,
    };

    setError('');

    Promise.all([
      login(data),
    ])
      .then(() => dispatch(account()))
      .then(({ name: username }) => {
        loginSync({
          username,
          password,
        });
        redirectToMain();
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      });
  };

  return (
    <LoginContainer>
      <LoginInnerContainer>
        <Typography.H3 cssUnique={LoginTitle}>
          Log In
        </Typography.H3>
        <LoginFormContainer autoComplete="off" onSubmit={handleSubmit}>
          <StyledInput
            labelText="Username"
            id="input-email"
            value={username}
            onChange={handleUsernameChanges}
            isError={!isUsernameValid}
            onBlur={onBlur('username')}
          />

          <StyledInput
            labelText="Password"
            type="password"
            id="input-password"
            value={password}
            onChange={handlePasswordChanges}
            isError={!isPasswordValid}
            onBlur={onBlur('password')}
            lastEl
          />

          <Typography.A cssUnique={ForgotPassTitle}>
            <a href="/recovery">
              Forgot password?
            </a>
          </Typography.A>

          <ButtonSubmit
            onClick={handleSubmit}
            blocked={isSubmitBlocked}
          >
            Log In
          </ButtonSubmit>
          {error && <ErrorMessage>
            <Typography.TextS>
              {error}
            </Typography.TextS>
          </ErrorMessage>}
        </LoginFormContainer>
      </LoginInnerContainer>
    </LoginContainer>
  );
});

LoginForm.propTypes = {
  history: PropTypes.object,
};

export default withRouter(LoginForm);
