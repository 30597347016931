import React, { useEffect, useState, memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  DatepickerWrapper,
  GlobalStyle,
} from './styles';

const DatePickerContainer = memo(({ startDate: date, onDateSelect }) => {
  const [startDate, setStartDate] = useState(date || new Date());

  useEffect(() => {
    setStartDate(date);
  }, [date]);

  const onChange = (date) => {
    setStartDate(date);
    onDateSelect(date);
  };

  return (
    <DatepickerWrapper>
      <GlobalStyle />
      <DatePicker
        inline
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
      />
    </DatepickerWrapper>
  );
});

export default DatePickerContainer;
