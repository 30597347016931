import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { isEmpty } from 'lodash';

import Select from 'react-select';
import { COLORS, FONTS, TRANSITIONS } from '../../../styles/Theme';
import useOutsideClick from '../../../utils/useOutsideClick';

const SelectFieldForm = memo(({
  value,
  options,
  onChange,
  labelText,
  isError,
  id,
  large,
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [initialValue, setInitialValue] = React.useState(value);

  const dropdown = React.useRef(null);

  React.useEffect(() => {
    if (value) {
      setInitialValue(value);
      onChange(value);
    }
  }, [value]);

  useOutsideClick(dropdown, () => {
    if (isMenuOpen) onMenuClose();
  });

  const colourStyles = React.useMemo(() => ({
    control: (styles) => ({
      ...styles,
      border: 'none',
      width: '100%',
      height: '44px',
      minHeight: '0',
      background: COLORS.WHITE,
      boxShadow: '0px 0px 10px rgba(55, 114, 182, 0.13)',
      borderRadius: '8px',
      '&:last-child span': {
        display: 'none !important',
      },
    }),
    option: (styles) => ({
      ...styles,
      fontSize: '12px',
      lineHeight: '150.68%',
      padding: '4px 16px',
      color: COLORS.BLACK_LIGHT,
      cursor: 'pointer',
      width: '100%',
      height: '30px',
      fontStyle: 'normal',
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',

      ...large && ({
        maxWidth: '50%',
        borderRadius: '8px',
      }),
      ':hover': {
        fontStyle: 'normal',
        background: COLORS.WHITE,
        color: COLORS.BLACK_LIGHT,
      },
      ':focus': {
        border: 'none',
      },
      ':active': {
        ...styles[':active'],
        border: 'none',
        fontStyle: 'normal',
      },
    }),
    input: (styles) => ({
      ...styles,
      display: 'none',
    }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({
      ...styles,
      fontWeight: 'normal',
      fontFamily: FONTS.OPEN_SANS,
      color: COLORS.BLACK_LIGHT,
      fontSize: '12px',
      lineHeight: '150.68%',
      width: '100%',
      height: '30px',
      margin: '0',
      top: '16px',
      maxWidth: '100%',
      padding: '6px 16px',
    }),
    menu: (styles) => ({
      ...styles,
      background: 'linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%)',
      boxShadow: '0px 0px 25px rgb(55 114 182 / 20%)',
      borderRadius: '8px',
      overflow: 'hidden',
      '& > div': {
        margin: '4px 0',
        '@media(max-height: 768px)': {
          maxHeight: '200px',
        },

        ...large && ({
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          margin: '12px 6px',
        }),

        '&::-webkit-scrollbar': {
          width: '5px',
          background: COLORS.LIGHTER_BLUE,
          borderRadius: '8px',
        },

        '&::-webkit-scrollbar-thumb': {
          background: COLORS.LIGHT_BLUE,
          borderRadius: '8px',
        },

        '&::-webkit-scrollbar-thumb:hover': {
          background: COLORS.BLUE,
          cursor: 'pointer',
        },
      },
    }),
    indicatorSeparator: (styles, { isFocused }) => ({
      ...styles,
      display: 'none',
      '& + div': {
        '& path': {
          fill: COLORS.LIGHT_BLUE,
        },
        alignItems: 'center',
        height: '44px',
        cursor: 'pointer',
        transition: `${TRANSITIONS.FAST}`,
        transform: isFocused ? 'rotateX(180deg)' : null,
        marginTop: '-3px',
        width: '100%',
        justifyContent: 'flex-end',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontWeight: 'normal',
      height: '44px',
      padding: 0,
      width: '100%',
      top: '6px',
      '& + div': {
        position: 'absolute',
        right: '0',
        width: '100%',
        height: '44px',
      },
    }),
  }), []);

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const handleChange = (value) => {
    setInitialValue((prevState) => ({ ...prevState, ...value }));
    onChange(value);
  };

  return (
    <SelectContainer ref={dropdown}>
      <Select
        inputId={id}
        name="aria-live-color"
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onChange={handleChange}
        defaultValue={initialValue}
        value={initialValue}
        menuIsOpen={isMenuOpen}
        styles={colourStyles}
        {...!isEmpty(options) && { options: options.filter((i) => i.value !== value?.value) }}
      />
      <Label
        value={value}
        isVisible={isMenuOpen}
        isError={isError}
        htmlFor={id}>
        {labelText}
      </Label>
    </SelectContainer>
  );
});

SelectFieldForm.propTypes = {
  value: PropTypes.object,
  optionsLabel: PropTypes.array,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  large: PropTypes.bool,
};

export default SelectFieldForm;

const SelectContainer = styled.div`
  position: relative;
`;

const Label = styled.label`
  top: 31px;
  left: 16px;
  font-size: 13px;
  line-height: 149.18%;
  position: absolute;
  transform: translateY(-100%);
  transition: ${TRANSITIONS.FAST};
  pointer-events: none;
  color: ${COLORS.LIGHT_BLUE};

  ${({ isError }) => (!isError
    ? css`
        color: ${COLORS.LIGHT_BLUE};
      `
    : css`
        color: ${COLORS.RED_LIGHT};
      `)};

  ${({ isVisible }) => isVisible && css`
    top: -2px;
    left: 16px;
    font-size: 12px;
    line-height: 150.68%;
    transform: translateY(-100%);
  `}

  ${({ value }) => value && Object.keys(value).length > 0 && css`
    top: -2px;
    left: 16px;
    font-size: 12px;
    line-height: 150.68%;
    transform: translateY(-100%);
  `}
`;
