import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import {
  COLUMNS_TASK_MANAGER,
  DOWNLOAD_DATA,
  SELECT_NUMBER_DATA,
  STATUS_DATA,
  RESPONSIBLE_DATA,
  INITIATED_DATA,
  CATEGORY_DATA,
  TASK_STATUSES,
} from '../../../constants/ui';

import { COLORS, TRANSITIONS } from '../../../styles/Theme';
import FacilityInfoBlock from '../../components/FacilityInfoBlock/FacilityInfoBlock.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import FieldDropdown from '../../components/FieldDropdown/FieldDropdown.jsx';
import Typography from '../../components/Typography';
import SVG from '../../components/SVG';
import TaskManagerTable from '../../components/TaskManagerTable/TaskManagerTable.jsx';
import search from '../../components/Header/images/search.svg';
import DatePickerRangeContainer from '../../components/DatePickerRangeContainer/index.jsx';

import getTaskManagerItems from '../../../services/getTaskManagerItems';
import useOutsideClick from '../../../utils/useOutsideClick';
import getTasksDownload from '../../../services/getTasksDownload';
import getTasksStats from '../../../services/getTasksStats';
import { setTasksManager } from '../../../store/actions';
import { less } from '../../../styles';

const tableTasksColumns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => (
      <CellExtended {...row.getToggleRowExpandedProps()} />
    ),
    isVisible: true,
  },
  {
    Header: 'Identifier',
    value: 'id',
    accessor: 'col1',
    isVisible: true,
  },
  {
    Header: 'Associated Equipment',
    value: 'associatedEquipment',
    accessor: 'col2',
    isVisible: true,
  },
  {
    Header: 'Dynamic ID',
    value: 'dynamicId',
    accessor: 'col3',
    isVisible: false,
  },
  {
    Header: 'Task',
    value: 'title',
    accessor: 'col4',
    isVisible: true,
  },
  {
    Header: 'Category',
    value: 'categoryName',
    accessor: 'col5',
    isVisible: true,
  },
  {
    Header: 'Status Name',
    value: 'statusName',
    accessor: 'col6',
    isVisible: false,
  },
  {
    Header: 'Last User Name',
    value: 'lastUserName',
    accessor: 'col7',
    isVisible: false,
  },
  {
    Header: 'Manager',
    value: 'manager',
    accessor: 'col8',
    isVisible: false,
  },
  {
    Header: 'Assigned Person',
    value: 'assignedPerson',
    accessor: 'col9',
    isVisible: false,
  },
  {
    Header: 'Est. Implementation On-Site Labor',
    value: 'estImplementationOnSite',
    accessor: 'col10',
    isVisible: false,
  },
  {
    Header: 'Custom Group',
    value: 'customGroup',
    accessor: 'col11',
    isVisible: false,
  },
  {
    Header: 'Completed Date',
    value: 'completedDate',
    accessor: 'col12',
    isVisible: true,
  },
  {
    Header: 'Capital Required',
    value: 'capitalRequired',
    accessor: 'col13',
    isVisible: false,
  },
  {
    Header: 'Date Entered',
    value: 'dateEntered',
    accessor: 'col14',
    isVisible: false,
  },
  {
    Header: 'Expected Completion Date',
    value: 'expectedCompletion',
    accessor: 'col15',
    isVisible: false,
  },
  {
    Header: 'Task Source',
    value: 'taskSource',
    accessor: 'col16',
    isVisible: false,
  },
  {
    Header: 'Task Description',
    value: 'taskDescription',
    accessor: 'col17',
    isVisible: true,
  },
  {
    Header: 'Est. Implementation Cost',
    value: 'estImplementationCost',
    accessor: 'col18',
    isVisible: false,
  },
  {
    Header: 'Est. Annual Saving ($)',
    value: 'saving',
    accessor: 'col19',
    isVisible: true,
  },
  {
    Header: 'Mapped Items',
    value: 'mappedItems',
    accessor: 'col20',
    isVisible: false,
  },
  {
    Header: 'Rebates',
    value: 'rebates',
    accessor: 'col21',
    isVisible: false,
  },
  {
    Header: 'Created On',
    value: 'createdOn',
    accessor: 'col22',
    isVisible: true,
  },
  {
    Header: 'Last updated',
    value: 'lastUpdated',
    accessor: 'col23',
    isVisible: true,
  },
  {
    Header: 'Responsible Party',
    value: 'responsiblePartyName',
    accessor: 'col24',
    isVisible: true,
  },
  {
    Header: 'Initiated by',
    value: 'initiatedByName',
    accessor: 'col25',
    isVisible: true,
  },
  {
    Header: () => null,
    value: 'column_button',
    accessor: 'col26',
    isVisible: true,
  },
  {
    Header: 'Status',
    value: 'statusId',
    accessor: 'col27',
    isVisible: true,
  },
];

const TaskManagerPage = memo(({ isVisible, history }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);

  const [isVisibleExport, setIsVisibleExport] = useState(false);
  const [isVisibleSelect, setIsVisibleSelect] = useState(false);
  const [choosedSelectNumber, setChoosedSelectNumber] = useState(() => SELECT_NUMBER_DATA[4]);
  const [tableColumns, setTableColumns] = useState([]);
  const [searchValue, setSeachValue] = useState('');
  const [chartData, setChartData] = useState([]);
  const [totalItems, setTotalItems] = useState('0');
  // CHECKBOXES
  const [columns, setColumnCheckboxes] = useState(() => COLUMNS_TASK_MANAGER);
  const [statusCheckboxes, setStatusCheckboxes] = useState(() => STATUS_DATA);
  const [categoryCheckboxes, setCategoryCheckboxes] = useState(() => CATEGORY_DATA);
  const [responsibleCheckboxes, setResponsibleCheckboxes] = useState(() => RESPONSIBLE_DATA);
  const [initiatedCheckboxes, setInitiatedCheckboxes] = useState(() => INITIATED_DATA);
  // TABLE
  const [tasks, setTasks] = useState([]);
  const [metaTotal, setMetaTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  // PICKER
  const [isVisiblePickerCreate, setVisiblePickerCreate] = useState(false);
  const [isVisiblePickerUpdate, setVisiblePickerUpdate] = useState(false);

  const [selectedStartDateFromCreate, setSelectedStartDateFromCreate] = useState(null);
  const [selectedStartDateToCreate, setSelectedStartDateToCreate] = useState(null);

  const [selectedStartDateFromUpdate, setSelectedStartDateFromUpdate] = useState(null);
  const [selectedStartDateToUpdate, setSelectedStartDateToUpdate] = useState(null);

  const [sortBy, setSortBy] = useState('lastUpdated');
  const [sortSide, setSortSide] = useState(-1);
  const [offset, setOffset] = useState(0);

  const [isLoading, setLoading] = useState(true);

  const exportDropdown = useRef(null);
  const selectNumberDropdown = useRef(null);
  const datePickerCreate = useRef(null);
  const datePickerUpdate = useRef(null);

  const timer = useRef(null);

  const getSearchTask = (value) => () => {
    handleGetTasks(
      columns,
      value,
      statusCheckboxes,
      categoryCheckboxes,
      responsibleCheckboxes,
      initiatedCheckboxes,
    );
  };

  useOutsideClick(datePickerCreate, () => {
    if (isVisiblePickerCreate) handleSetVisiblePickerCreate();
  });

  useOutsideClick(datePickerUpdate, () => {
    if (isVisiblePickerUpdate) handleSetVisiblePickerUpdate();
  });

  useEffect(() => {
    handleGetTasks(
      columns,
      searchValue,
      statusCheckboxes,
      categoryCheckboxes,
      responsibleCheckboxes,
      initiatedCheckboxes,
      choosedSelectNumber.value,
      offset,
      sortSide,
      sortBy,
      selectedStartDateFromCreate,
      selectedStartDateToCreate,
      selectedStartDateFromUpdate,
      selectedStartDateToUpdate,
    );
  }, [
    statusCheckboxes,
    categoryCheckboxes,
    responsibleCheckboxes,
    initiatedCheckboxes,
    choosedSelectNumber,
    sortSide,
    sortBy,
    selectedStartDateToCreate,
    selectedStartDateToUpdate,
    organizationId,
  ]);

  useEffect(() => {
    if (organizationId) {
      const columns = tableTasksColumns.filter(({ isVisible }) => isVisible);
      setTableColumns(columns);

      dispatch(getTasksStats())
        .then((data) => {
          const customData = !!data && data.map((item) => ({
            ...item,
            value: item.tasksCount,
          }));
          setChartData(customData);
        });
    }
  }, [organizationId]);

  useEffect(() => {
    !isEmpty(chartData) ? setTotalItems(chartData.reduce((acc, res) => acc + res.tasksCount, 0)) : setTotalItems('0');
  }, [chartData]);

  const handleSetVisiblePickerCreate = () => {
    setVisiblePickerCreate(!isVisiblePickerCreate);
  };

  const handleSetVisiblePickerUpdate = () => {
    setVisiblePickerUpdate(!isVisiblePickerUpdate);
  };

  const handleGetTasks = (
    value,
    search = '',
    statusCheckboxe = statusCheckboxes,
    categoryCheckboxe = categoryCheckboxes,
    responsibleCheckboxe = responsibleCheckboxes,
    initiatedCheckboxe = initiatedCheckboxes,
    limit = choosedSelectNumber.value,
    offse = offset,
    sortSid = sortSide,
    sortB = sortBy,
    createdOnFrom,
    createdOnTo,
    updatedOnFrom,
    updatedOnTo,
  ) => {
    if (organizationId) {
      const columns = value
        .filter(({ isChecked }) => isChecked)
        .map(({ id }) => id);
      const statusCheck = getChoosedColumns(statusCheckboxe);
      const categoryCheck = getChoosedColumns(categoryCheckboxe);
      const responsibleCheck = getChoosedColumns(responsibleCheckboxe);
      const initiatedCheck = getChoosedColumns(initiatedCheckboxe);

      setColumnCheckboxes(value);
      setLoading(true);
      dispatch(getTaskManagerItems({
        columns,
        search,
        statusCheck,
        categoryCheck,
        responsibleCheck,
        initiatedCheck,
        limit,
        offse,
        sortSid,
        sortB,
        createdOnFrom,
        createdOnTo,
        updatedOnFrom,
        updatedOnTo,
      }))
        .then(({ tasks = [], total = 0 }) => {
          const visibleColumns = handleGetAccureColumn(columns)
            .filter(({ isVisible }) => isVisible);
          const lastPage = Math.ceil(total / limit);

          setTableColumns(visibleColumns);
          setTasks(tasks);
          setMetaTotal(total);
          setLastPage(lastPage);
          setLoading(false);
        });

      dispatch(setTasksManager(tasks));
    }
  };

  const getChoosedColumns = (columns) => columns
    .filter(({ isChecked }) => isChecked)
    .map(({ id }) => id)
    .filter((column) => column);

  const handleChangeVisabilityExport = () => {
    if (isEmpty(tasks)) return;
    setIsVisibleExport(!isVisibleExport);
  };

  const handleChangeVisabilitySelectNumber = () => {
    setIsVisibleSelect(!isVisibleSelect);
  };

  useOutsideClick(exportDropdown, () => {
    if (isVisibleExport) handleChangeVisabilityExport();
  });

  useOutsideClick(selectNumberDropdown, () => {
    if (isVisibleSelect) handleChangeVisabilitySelectNumber();
  });

  const handleChooseSelectNumber = (id) => () => {
    const value = SELECT_NUMBER_DATA.find((item) => item.id === id);
    setChoosedSelectNumber(value);
    setCurrentPage(1);
  };

  const handleGetAccureColumn = (data) => (!!tableColumns.length
    ? tableColumns
    : tableTasksColumns)
    .map((column) => ({
      ...column,
      isVisible: data.some((item) => item === column.value),
    }));

  const mapTask = ({
    id,
    associatedEquipment,
    title,
    categoryName,
    statusName,
    completedDate,
    taskDescription,
    saving,
    createdOn,
    lastUpdated,
    responsiblePartyName,
    initiatedByName,
    statusId,
  }) => ({
    col1: id || '—',
    col2: associatedEquipment || '—',
    col3: '—',
    col4: title || '—',
    col5: categoryName || '—',
    col6: statusName || '—',
    col7: '—',
    col8: '—',
    col9: '—',
    col10: '—',
    col11: '—',
    col12: completedDate || '—',
    col13: '—',
    col14: '—',
    col15: '—',
    col16: '—',
    col17: taskDescription || '—',
    col18: '—',
    col19: saving || '0',
    col20: '—',
    col21: '—',
    col22: createdOn && format(new Date(createdOn), 'MM/dd/yyyy HH:mm') || '—',
    col23: lastUpdated && format(new Date(lastUpdated), 'MM/dd/yyyy HH:mm') || '—',
    col24: responsiblePartyName || '—',
    col25: initiatedByName || '—',
    col26: <ColumnItem />,
    col27: TASK_STATUSES[statusId],
  });

  const handleSearch = ({ target: { value } }) => {
    clearTimeout(timer.current);
    setSeachValue(value);
    timer.current = setTimeout(getSearchTask(value), 1000);
  };

  const handleDownloadClick = (value) => () => {
    const columnsRes = columns
      .filter(({ isChecked }) => isChecked)
      .map(({ id }) => id);

    const statusCheck = getChoosedColumns(statusCheckboxes);
    const categoryCheck = getChoosedColumns(categoryCheckboxes);
    const responsibleCheck = getChoosedColumns(responsibleCheckboxes);
    const initiatedCheck = getChoosedColumns(initiatedCheckboxes);

    dispatch(getTasksDownload({
      columns: columnsRes,
      search: searchValue,
      statusCheck,
      categoryCheck,
      responsibleCheck,
      initiatedCheck,
      limit: choosedSelectNumber.value,
      offse: offset,
      sortSid: sortSide,
      sortB: sortBy,
      createdOnFrom: selectedStartDateFromCreate,
      createdOnTo: selectedStartDateToCreate,
      updatedOnFrom: selectedStartDateFromUpdate,
      updatedOnTo: selectedStartDateToUpdate,
      value,
    }));
  };

  const handleOnAcceptClickStatus = useCallback((value) => {
    setStatusCheckboxes(value);
  }, []);

  const handleOnAcceptClickResponsible = useCallback((value) => {
    setResponsibleCheckboxes(value);
  }, []);

  const handleOnAcceptClickInitiated = useCallback((value) => {
    setInitiatedCheckboxes(value);
  }, []);

  const handleOnAcceptClickCategory = useCallback((value) => {
    setCategoryCheckboxes(value);
  }, []);

  const pageClick = (index, lastPage) => () => {
    const nextPage = lastPage || (currentPage + index > 1 ? currentPage + index : 1);
    const offset = (nextPage - 1) * choosedSelectNumber.value;

    if (nextPage > lastPage) return;
    setCurrentPage(nextPage);
    setOffset(offset);

    handleGetTasks(
      columns,
      '',
      statusCheckboxes,
      categoryCheckboxes,
      responsibleCheckboxes,
      initiatedCheckboxes,
      choosedSelectNumber.value,
      offset,
    );
  };

  const getSortSide = (sortSide) => (sortSide > 0 ? -1 : 1);

  const handleSortClick = useCallback((sort) => {
    if (sort === sortBy) {
      setSortSide(getSortSide(sortSide));
    } else {
      setSortBy(sort);
      setSortSide(getSortSide(sortSide));
    }
  }, [sortSide]);

  const handleCreateClick = () => {
    history.push('/project-center/task-manager/create-task');
  };

  const handleStartDateSelectCreate = (dates) => {
    setSelectedStartDateFromCreate(dates[0]);
    setSelectedStartDateToCreate(dates[1]);

    setSelectedStartDateFromUpdate(null);
    setSelectedStartDateToUpdate(null);

    if (dates[1]) {
      handleGetTasks(
        columns,
        '',
        statusCheckboxes,
        categoryCheckboxes,
        responsibleCheckboxes,
        initiatedCheckboxes,
        choosedSelectNumber.value,
        '',
        '',
        '',
        dates[0],
        dates[1],
      );
    }
  };

  const handleStartDateSelectUpdate = (dates) => {
    setSelectedStartDateFromUpdate(dates[0]);
    setSelectedStartDateToUpdate(dates[1]);

    setSelectedStartDateFromCreate(null);
    setSelectedStartDateToCreate(null);

    if (dates[1]) {
      handleGetTasks(
        columns,
        '',
        statusCheckboxes,
        categoryCheckboxes,
        responsibleCheckboxes,
        initiatedCheckboxes,
        choosedSelectNumber.value,
        '',
        '',
        '',
        '',
        '',
        dates[0],
        dates[1],
      );
    }
  };

  const mapTasks = useMemo(() => tasks.map((task) => mapTask(task)), [tasks, choosedSelectNumber]);

  return (
    <MainPageContainer isVisible={isVisible}>
      <MainContentGrid>
        <FacilityInfoBlock chartData={chartData} />
        <Block2 isVisible={isVisible}>
          <HeaderTable isVisible={isVisible}>
            <LeftColumn isVisible={isVisible}>
              <CreatedButton onClick={handleCreateClick}>
                <Typography.TextS cssUnique={ButtonTitle}>
                  Create new Task
                </Typography.TextS>
              </CreatedButton>
              <FieldDropdown
                data={statusCheckboxes}
                title={'Status'}
                width={86}
                cWidth={203}
                onAcceptClick={handleOnAcceptClickStatus}
              />
              <FieldDropdown
                data={responsibleCheckboxes}
                title={'Responsible Party'}
                width={159}
                cWidth={159}
                onAcceptClick={handleOnAcceptClickResponsible}
              />
              <FieldDropdown
                data={initiatedCheckboxes}
                title={'Initiated by'}
                width={121}
                cWidth={121}
                onAcceptClick={handleOnAcceptClickInitiated}
              />
              <FieldDropdown
                data={categoryCheckboxes}
                title={'Category'}
                width={146}
                cWidth={146}
                onAcceptClick={handleOnAcceptClickCategory}
              />
              <CreatedOnContainer ref={datePickerCreate}>
                <CreatedOnHeader onClick={handleSetVisiblePickerCreate}>
                  <Typography.TextS cssUnique={CalendarTitle}>
                    Created On
                  </Typography.TextS>
                  <SVG.CalendarIcon />
                </CreatedOnHeader>
                <DatePicker isVisible={isVisiblePickerCreate}>
                  <DatePickerRangeContainer
                    date={[selectedStartDateFromCreate, selectedStartDateToCreate]}
                    onDatesSelect={handleStartDateSelectCreate}
                  />
                </DatePicker>
              </CreatedOnContainer>
              <CreatedOnContainer>
                <CreatedOnHeader onClick={handleSetVisiblePickerUpdate}>
                  <Typography.TextS cssUnique={CalendarTitle}>
                    Updated On
                  </Typography.TextS>
                  <SVG.CalendarIcon />
                </CreatedOnHeader>
                <DatePicker isVisible={isVisiblePickerUpdate} ref={datePickerUpdate}>
                  <DatePickerRangeContainer
                    date={[selectedStartDateFromUpdate, selectedStartDateToUpdate]}
                    onDatesSelect={handleStartDateSelectUpdate}
                  />
                </DatePicker>
              </CreatedOnContainer>
            </LeftColumn>
            <RightColumn>
              <ButtonsContainer isVisible={isVisible}>
                <LeftContainer>
                  <InputContainer>
                    <InputEl
                      id="test"
                      value={searchValue}
                      onChange={handleSearch}
                    />
                    <SearchIcon htmlFor="search" icon={search} />
                  </InputContainer>
                  <ExportButtonContainer onClick={handleChangeVisabilityExport}>
                    <ExportButton isVisible={isVisibleExport} isDisabled={isEmpty(tasks)}>
                      <SVG.DownloadIconBigger />
                    </ExportButton>
                    <ExportButtonDropdown isVisible={isVisibleExport} ref={exportDropdown}>
                      <ExportButtonDropdownInner>
                        {
                          DOWNLOAD_DATA.map(({
                            id, name, value,
                          }) => (
                            <DownloadItem key={id} onClick={handleDownloadClick(value)}>
                              <Typography.TextS>{name}</Typography.TextS>
                            </DownloadItem>
                          ))
                        }
                      </ExportButtonDropdownInner>
                    </ExportButtonDropdown>
                  </ExportButtonContainer>
                </LeftContainer>

                <RightContainer>
                  <SelectNumberContainer onClick={handleChangeVisabilitySelectNumber}>
                    <SelectNumber isVisible={isVisibleSelect}>
                      <Typography.TextS>
                        {choosedSelectNumber?.label}
                      </Typography.TextS>
                      <SVG.ArrowDropdown />
                    </SelectNumber>
                    <SelectNumberDropdown isVisible={isVisibleSelect} ref={selectNumberDropdown}>
                      <SelectNumberDropdownInner>
                        {
                          SELECT_NUMBER_DATA.map(({
                            id, label,
                          }) => (
                            <SelectNumberItem key={id} onClick={handleChooseSelectNumber(id)}>
                              <Typography.TextS>{label}</Typography.TextS>
                            </SelectNumberItem>
                          ))
                        }
                      </SelectNumberDropdownInner>
                    </SelectNumberDropdown>
                  </SelectNumberContainer>
                  <PaginationContainer>
                    <FullLeftButtonContainer onClick={pageClick(-1, 1)}>
                      <SVG.PaginationArrowLeft />
                      <SVG.PaginationArrowLeft />
                    </FullLeftButtonContainer>

                    <LeftButtonContainer onClick={pageClick(-1)}>
                      <SVG.PaginationArrowLeft />
                    </LeftButtonContainer>

                    <RightButtonContainer onClick={pageClick(+1)}>
                      <SVG.PaginationArrowRight />
                    </RightButtonContainer>

                    <FullRightButtonContainer onClick={pageClick(1, lastPage)}>
                      <SVG.PaginationArrowRight />
                      <SVG.PaginationArrowRight />
                    </FullRightButtonContainer>
                  </PaginationContainer>
                </RightContainer>
              </ButtonsContainer>
            </RightColumn>
          </HeaderTable>
          <TaskManagerTable
            meta={metaTotal}
            columnCheckboxes={columns}
            getTasks={handleGetTasks}
            tableColumns={tableColumns}
            tableRows={mapTasks}
            initPageSize={choosedSelectNumber.value}
            onSort={handleSortClick}
            sortBy={sortBy}
            isVisible={isVisible}
            totalItems={totalItems}
            isLoading={isLoading}
          />
        </Block2>
      </MainContentGrid>
      <Footer />
    </MainPageContainer>
  );
});

TaskManagerPage.propTypes = {
  isVisible: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default withRouter(TaskManagerPage);

const MainPageContainer = styled.div`
    width: 100%;
    transition: ${TRANSITIONS.FAST};
    padding: 17px 24px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 38px;
    height: -webkit-fill-available;
`;

const MainContentGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 94px;
    grid-template-areas: "facilityInfo"
                         "taskManagerTable";
    grid-gap: 16px;
    position: relative;
    width: 100%;

    & + div {
      padding: 16px 0;
    }

    ${less.tabletL(css`
      grid-template-rows: auto;
    `)}
`;

const Block2 = styled.div`
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 16px;
    background: ${COLORS.WHITE};
    grid-area: taskManagerTable;
    width: 100%;
    /* width: calc(100vw - 48px); */
    /* width: calc(100vw - 48px); */

    /* ${({ isVisible }) => isVisible && css`
      width: calc(100vw - 48px - 208px);
    `} */

    ${less.laptopS(css`
      ${({ isVisible }) => isVisible && css`
        /* width: calc(100vw - 48px - 208px); */
      `}
    `)}

    ${less.tabletL(css`
      width: calc(100vw - 48px);
    `)}
`;

const LeftContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTable = styled.div`
    height: 48px;
    width: 100%;
    box-shadow: 0px 0px 25px rgba(55, 114, 182, 0.2);
    border-radius: 8px;
    background: ${COLORS.WHITE};
    padding: 8px;
    display: flex;
    justify-content: space-between;

    a {
        text-decoration: none;
    }

    ${less.laptop(css`
      ${({ isVisible }) => isVisible && css`
        height: auto;
        padding-bottom: 0;
      `}
  `)}

    ${less.laptopS(css`
      height: auto;
      padding-bottom: 0;
    `)}
`;

const CreatedButton = styled.div`
    height: 32px;
    width: 141px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLORS.BLUE};
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
`;

const ButtonTitle = css`
    font-weight: bold;
    color: ${COLORS.WHITE};
`;

const LeftColumn = styled.div`
    display: flex;

    ${less.laptop(css`
      ${({ isVisible }) => isVisible && css`
        flex-wrap: wrap;
        width: 60%;
        
        & > div {
          margin-bottom: 8px;
        }
      `}
  `)}

    ${less.laptopS(css`
      flex-wrap: wrap;
      width: 60%;
      
      & > div {
        margin-bottom: 8px;
      }
    `)};
`;

const RightColumn = styled.div`
    display: flex;
    align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  z-index: 2;
  align-items: center;

  ${less.laptop(css`
    ${({ isVisible }) => isVisible && css`
      flex-direction: column-reverse;
      align-items: flex-end;
      justify-content: space-evenly;
      height: 100%;
    `}
  `)}
 
  ${less.laptopS(css`
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-evenly;
    height: 100%;
  `)}
`;

const ExportButtonContainer = styled.div`
  position: relative;
`;

const ExportButtonDropdown = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: ${TRANSITIONS.FAST};
  left: calc(-43px / 4);

  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    pointer-events: auto;
  `}
`;

const ExportButtonDropdownInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  padding: 6px 0;
  flex-direction: column;
  background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
  box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
  border-radius: 8px;
  margin-top: 4px;
`;

const ExportButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    width: 1px;
    background: ${COLORS.LIGHTER_BLUE};
    height: 23px;
    position: absolute;
    right: -17px;
  }
  svg path {
      transition: ${TRANSITIONS.FAST};
  }
  &:hover {
      svg path {
          fill: ${COLORS.LIGHT_BLUE};
      };
  }
  ${({ isVisible }) => isVisible && css`
    svg path {
      fill: ${COLORS.LIGHT_BLUE};
    }
  `}

  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
  `}
`;

const DownloadItem = styled.div`
  display: flex;
  padding: 4px 0;
  cursor: pointer;
  width: 100%;
  justify-content: center;

  & > div {
    font-size: 12px;
    line-height: 150.68%;
  }
`;

const SelectNumberContainer = styled.div`
  position: relative;
  width: 53px;
  height: 24px;
  background: ${COLORS.WHITE};
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: 0 8px;
  margin-left: 37px;
`;

const SelectNumber = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & div {
    font-size: 12px;
    line-height: 150.68%;
    color: ${COLORS.LIGHT_BLUE};
  }
`;

const SelectNumberDropdown = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: ${TRANSITIONS.FAST};

  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    pointer-events: auto;
  `}
`;

const SelectNumberDropdownInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  left: 0;
  padding: 6px 0;
  flex-direction: column;
  background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
  box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
  border-radius: 8px;
  margin-top: 29px;
`;

const SelectNumberItem = styled.div`
  display: flex;
  padding: 4px 0;
  color: ${COLORS.LIGHT_BLUE};
  display: flex;
  justify-content: center;
  width: 100%;
  transition: ${TRANSITIONS.FAST};

  & > div {
    font-size: 12px;
    line-height: 150.68%;
  }

  &:hover {
    color: ${COLORS.BLUE};

     & > div {
       font-weight: bold;
     }
  }
`;

const ColumnItem = styled.div`
  width: 1px;
  height: 24px;
  background: ${COLORS.LIGHTER_BLUE};
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 8px;
  width: 32px;
  cursor: pointer;
`;

const SearchIcon = styled.div`
  position: absolute;
  opacity: 1;
  transition: ${TRANSITIONS.QUICK};
  pointer-events: none;
  width: 32px;
  height: 32px;

  ${(props) => props.icon && css`
      &::before {
          content: url(${props.icon});
          position: absolute;
          top: 2px;
          bottom: 0;
          display: flex;
          align-items: center;
          left: 10px;
      }
  `}
`;

const InputEl = styled.input`
  position: absolute;
  right: 0;
  border-radius: 16px;
  background: ${COLORS.WHITE};
  border: none;
  outline: none;
  font-size: 13px;
  line-height: 18px;
  height: 32px;
  cursor: pointer;
  width: 32px;
  padding: 0;
  transition: ${TRANSITIONS.FAST};
  
  &:focus {
    width: 391px;
    padding: 0 10px;
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);

    ${less.tabletL(css`
      width: 291px;
    `)}
  }

  ${({ value }) => value && css`
    width: 391px;
    padding: 0 10px;
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);

    ${less.tabletL(css`
      width: 291px;
    `)}
  `}
`;

const CellExtended = styled.span`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const PaginationContainer = styled.div`
  display: flex;
  margin-left: 16px;
  margin-right: 8px;
  white-space: nowrap;

  svg path {
    transition: ${TRANSITIONS.FAST};
  }

  & > div:hover {
    svg path {
      stroke: ${COLORS.LIGHT_BLUE};
    }
  }
`;

const FullLeftButtonContainer = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

const LeftButtonContainer = styled.div`
  margin-right: 4px;
  cursor: pointer;
`;

const RightButtonContainer = styled.div`
  margin-left: 4px;
  cursor: pointer;
`;

const FullRightButtonContainer = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const CreatedOnContainer = styled.div`
  display: flex;
  padding: 0 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 25px rgb(95 116 227 / 14%);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  margin-right: 8px;
  height: 30px;

  svg {
    margin-left: 8px;
    margin-bottom: 2px;
  }
`;

const CreatedOnHeader = styled.div`
  display: flex;
  flex-direction: space-between;
  align-items: center;
`;

const CalendarTitle = css`
  font-weight: bold;
  color: ${COLORS.BLUE};
`;

const DatePicker = styled.div`
    position: absolute;
    width: 219px;
    height: 212px;
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
    box-shadow: 0px 0px 25px rgba(55, 114, 182, 0.2);
    border-radius: 8px;
    padding: 16px;
    opacity: 0;
    transition: ${TRANSITIONS.FAST};
    pointer-events: none;
    left: -25%;
    top: 40px;

    ${({ isVisible }) => isVisible && css`
        opacity: 1;
        pointer-events: auto;
    `}
`;
