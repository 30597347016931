import React, { useState, useEffect } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload';

import {
  EditorGlobalStyles,
  NotesAddContainer,
  NotesTitle,
  NotesTitleEditor,
} from './styles';
import SVG from '../SVG';
import Typography from '../Typography';

function Editor({
  placeholder = '',
  value,
  onChange,
  isVisible,
  onChangeVisible,
}) {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleChangeVisibility = () => {
    onChangeVisible(!isVisible);
  };

  if (!isMounted) {
    return <div />;
  }

  return <div>
    {
      !isVisible
        ? <NotesAddContainer onClick={handleChangeVisibility}>
          <SVG.NotesButton />
          <Typography.TextS cssUnique={NotesTitle}>
            Notes
          </Typography.TextS>
        </NotesAddContainer>
        : <>
          <Typography.TextS cssUnique={NotesTitleEditor}>
            Notes
          </Typography.TextS>
          <EditorGlobalStyles />
          <CKEditor
            editor={ClassicEditor}
            config={{
              allowedContent: true,
              extraPlugins: [],
              removePlugins: ['MediaEmbed'],
              image: {
                toolbar: ['imageTextAlternative'],
                styles: ['full', 'alignLeft', 'alignRight'],
              },
              link: {
                decorators: {
                  isExternal: {
                    mode: 'manual',
                    label: 'Open in a new tab',
                    attributes: {
                      target: '_blank',
                    },
                  },
                  toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                      download: true,
                    },
                  },
                },
              },
              language: 'en',
              placeholder,
            }}
            data={value}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChange(data);
            }}
          />
        </>
    }
  </div>;
}

export default Editor;
