import axios from 'axios';

export default function loginSync({ username, password }) {
  const data = {
    username,
    password,
    submit: 'Submit',
    pathname: '/',
    hash: '',
  };

  const formData = new FormData();

  for (const key in data) {
    formData.append(key, data[key]);
  }

  return axios.post(
    'https://bob.americanenergyassets.com/login/plz',
    formData,
    {
      withCredentials: true,
    },
  );
}
