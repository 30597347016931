import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { COLORS, FONTS, TRANSITIONS } from '../../../styles/Theme';
import useOutsideClick from '../../../utils/useOutsideClick';

const SelectField = ({ value, options, onChange }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const dropdown = React.useRef(null);

  useOutsideClick(dropdown, () => {
    if (isMenuOpen) {
      onMenuClose();
    }
  });

  const colourStyles = React.useMemo(() => ({
    control: (styles) => ({
      ...styles,
      border: 'none',
      background: 'none',
      borderRadius: '0',
      boxShadow: 'none',
      width: '100%',
      height: '30px',
      minHeight: '0',
      '&:last-child span': {
        display: 'none !important',
      },
    }),
    option: (styles) => ({
      ...styles,
      fontSize: '12px',
      lineHeight: '150.68%',
      padding: '4px 16px',
      color: COLORS.BLACK_LIGHT,
      cursor: 'pointer',
      width: '100%',
      height: '30px',
      fontStyle: 'normal',
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        fontStyle: 'normal',
        background: COLORS.WHITE,
        color: COLORS.BLUE,
      },
      ':focus': {
        border: 'none',
      },
      ':active': {
        ...styles[':active'],
        border: 'none',
        fontStyle: 'normal',
      },
    }),
    input: (styles) => ({
      ...styles,
      display: 'none',
    }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({
      ...styles,
      fontWeight: 'normal',
      fontFamily: FONTS.OPEN_SANS,
      color: COLORS.BLUE,
      fontSize: '12px',
      lineHeight: '150.68%',
      width: '100%',
      height: '30px',
      margin: '0',
      top: '16px',
      maxWidth: '100%',
      padding: '6px 16px',
    }),
    menu: (styles) => ({
      ...styles,
      background: 'linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%)',
      boxShadow: '0px 0px 25px rgb(55 114 182 / 20%)',
      borderRadius: '8px',
      overflow: 'hidden',
      '& > div': {
        paddingBottom: '4px',
        paddingTop: '4px',
      },
    }),
    indicatorSeparator: (styles, { isFocused }) => ({
      ...styles,
      display: 'none',
      '& + div': {
        '& path': {
          fill: COLORS.BLUE,
        },
        cursor: 'pointer',
        transition: `${TRANSITIONS.FAST}`,
        transform: isFocused ? 'rotateX(180deg)' : null,
        marginTop: '-3px',
        width: '100%',
        justifyContent: 'flex-end',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontWeight: 'normal',
      height: '30px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 10px rgba(55, 114, 182, 0.13)',
      borderRadius: '8px',
      padding: 0,
      '& + div': {
        position: 'absolute',
        right: '0',
        width: '100%',
      },
    }),
  }), []);

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const handleChange = (value) => onChange(value);

  return (
    <div ref={dropdown}>
      <Select
        aria-labelledby="aria-label"
        inputId="aria-example-input"
        name="aria-live-color"
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onChange={handleChange}
        defaultValue={value}
        options={options.filter((i) => i.value !== value.value)}
        styles={colourStyles}
      />
    </div>
  );
};

SelectField.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default SelectField;
