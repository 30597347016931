import styled, { css } from 'styled-components/macro';
import { COLORS, TRANSITIONS } from '../../../styles/Theme';

export const BurgerMenuContainer = styled.div`
    transform: translateX(-208px);
    transition: ${TRANSITIONS.FAST};
    max-width: 208px;
    background: linear-gradient(51.64deg, #3772B6 -15.06%, #1D4E86 139.87%);
    position: fixed;
    height: calc(100% - 72px);
    z-index: 12;
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    width: 100%;
    svg {
        cursor: pointer;
    }

    ${({ isVisible }) => isVisible && css`
        transform: translateX(0);
    `}
`;

export const CloseIconContainer = styled.div`
    position: relative;
    transform: translateX(0px);
    transition: ${TRANSITIONS.FAST};
    height: 21px;
    width: 27px;
    cursor: pointer;
    margin: 17px 16px;
    margin-bottom: 12px;

    ${({ isVisible }) => !isVisible && css`
        transform: translateX(216px);

        path {
            fill: ${COLORS.BLUE};
        }
    `}
`;

export const ButtonIcon = styled.span`
    height: 3px;
    width: 27px;
    position: relative;
    top: 0px;
    display: block;


    &:before, &:after {
        content: '';
        width: 27px;
        height: 3px;
        position: absolute;
        left: 0;
        background-color: #A6B1DC;
        z-index: 1;
        transition: ${TRANSITIONS.FAST};
    } 

    &:before {
        transform: rotate(45deg);
        background-color: ${COLORS.WHITE};
        top: 6px;
    }

    &:after {
        transform: rotate(-45deg);
        background-color: ${COLORS.WHITE};
        top: 6px;
    }

    ${({ isVisible }) => !isVisible && css`
        background: ${COLORS.BLUE};

        &:before {
            top: 12px;
        }

        &:before, &:after {
            transform: rotate(0);
            background: ${COLORS.BLUE};
        } 
    `}
`;

export const StyledWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 0;
    overflow-y: hidden;
    position: relative;
    opacity: 0;
    transition: ${TRANSITIONS.NORMAL};

    ${({ isVisible }) => isVisible && css`
        height: ${({ quantity }) => `${(quantity) * 36}px`};
        opacity: 1;
        overflow-y: auto;
    `}

    ${({ isFirst }) => isFirst && css`
        height: calc(100% - 50px);
        opacity: 1;
        margin-left: 0;
        overflow-y: auto;
    `}

    &::-webkit-scrollbar {
        width: 5px;
        background: linear-gradient(51.64deg,#3772B6 -15.06%,#1D4E86 139.87%);
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${COLORS.LIGHT_BLUE};
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${COLORS.BLUE};
        cursor: pointer;
    }
`;

export const StyledWrapper2 = styled.div`
  padding: 6.5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0px;
  cursor: pointer;
  transition: ${TRANSITIONS.NORMAL};
  position: relative;
  padding-left: ${(props) => `${(props.level * 8) + 17}px`};

  &:hover {
        background: ${COLORS.BLUE};
    }

  ${({ orgId, currentOrganization }) => orgId === currentOrganization && css`
      background: ${COLORS.BLUE};
      margin-left: ${(props) => `${(props.level * 8)}px`};
      padding-left: 17px;
      background: ${COLORS.WHITE} !important;
      border-radius: 8px 0px 0px 8px;

      & > div {
        font-weight: 600;
        color: ${COLORS.BLUE};
      }
  `}

  ${({ isHasList }) => isHasList && css`
      margin: 5px 0;
  `}

  svg {
      path {
          stroke: ${COLORS.WHITE};
      }

      transition: ${TRANSITIONS.FAST};
      transform: rotateZ(-90deg);
      margin-left: 5px;
  }

  ${({ isVisible }) => isVisible && css`
        & + div {
            height: 100%;
            opacity: 1;

            & > div {
                opacity: 1;
            }
        }

        svg {
            transform: rotateZ(0deg);
        }
  `}

  ${({ firstLevel }) => firstLevel && css`
    padding-left: 17px;
  `}
`;

export const FacilityContainer = styled.div`
    transition: ${TRANSITIONS.NORMAL};
`;

export const SidebarItem = css`
    font-weight: 500;
    color: ${COLORS.WHITE};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const FacilityIcon = styled.img`
    margin-right: 6px;
`;

export const EmptyImg = styled.div`
    width: 14px;
    height: 14px;
`;
