import axios from 'axios';
import ROUTES from '../api/routes';
import { TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';
import logoutSync from './logoutSync';

export default function login({ username, password }) {
  const data = JSON.stringify({
    username,
    password,
  });

  logoutSync();

  return axios.post(
    ROUTES.LOGIN,
    data,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
      withCredentials: true,
    },
  )
    .then(({ data }) => {
      localStorage.setItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME, JSON.stringify(data));
    });
}
