import { css } from 'styled-components/macro';

export const devices = {
  desktop: 1920,
  laptopXL: 1680,
  laptop: 1440,
  laptopS: 1280,
  tabletL: 1024,
  tablet: 768,
  mobile: 600,
};

const less = {};
const more = {};
const lessH = {};

for (const value in devices) {
  less[value] = (cssProp) => css`
    @media (max-width: ${devices[value]}px) {
      ${cssProp}
    }
  `;

  more[value] = (cssProp) => css`
    @media (min-width: ${devices[value]}px) {
      ${cssProp}
    }
  `;

  lessH[value] = (cssProp) => css`
    @media (max-height: ${devices[value]}px) {
      ${cssProp}
    }
`;
}

export { less, more, lessH };
