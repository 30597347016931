import {
  SET_AUTHENTICATION,
  SET_USER,
  SET_FACILITIES,
  SET_TASKS_MANAGER,
  SET_MEDIA_INFO,
  SET_IS_LOADING,
  SET_ORGANIZATION_ID,
  SET_FACILITY_NAME,
} from '../types/types';

export const setAuthorizedAction = (payload) => ({
  type: SET_AUTHENTICATION,
  payload,
});

export const setUserAction = (payload) => ({
  type: SET_USER,
  payload,
});

export const setFacilities = (payload) => ({
  type: SET_FACILITIES,
  payload,
});

export const setTasksManager = (payload) => ({
  type: SET_TASKS_MANAGER,
  payload,
});

export const setMediaInfo = (payload) => ({
  type: SET_MEDIA_INFO,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_IS_LOADING,
  payload,
});

export const setOrganizationId = (payload) => ({
  type: SET_ORGANIZATION_ID,
  payload,
});

export const setFacilityName = (payload) => ({
  type: SET_FACILITY_NAME,
  payload,
});
