import axios from 'axios';
import ROUTES from '../api/routes';
import { TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';

export default function getBuildingsSearch(search = '') {
  const { accessToken } = JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME)) || {};

  return axios.get(
    `${ROUTES.GET_BUILDINGS}?search=${search}`,
    {
      headers: {
        accept: 'application/json',
        Authorization: accessToken,
      },
    },
  )
    .then(({ data }) => data)
    .catch((err) => err);
}
