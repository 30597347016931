import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SVG from '../SVG';
import Typography from '../Typography';
import useOutsideClick from '../../../utils/useOutsideClick';

import {
  AccountContainer,
  AccountInfoContainer,
  AccountData,
  ButtonsContainer,
  AccountInfoButton,
  AccountInfoTitle,
  AccountNameTitle,
  LogoutTitle,
  LogoutButton,
} from './styles';

// images
import accountIcon from './images/account.png';
import logout from '../../../services/logout';

const AccountInfoPopup = ({ isVisible, onChangeVisability }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const accountDropdown = useRef(null);

  const handleChangeVisability = () => {
    onChangeVisability();
  };

  useOutsideClick(accountDropdown, () => {
    if (isVisible) handleChangeVisability();
  });

  const handleLogoutClick = () => {
    dispatch(logout());
    handleChangeVisability();
  };

  return (
    <AccountContainer ref={accountDropdown} isVisible={isVisible}>
      <AccountInfoContainer>
        <AccountData>
          <img src={accountIcon} alt="logo" />
          <Typography.TextS cssUnique={AccountNameTitle}>
            {user?.name}
          </Typography.TextS>
        </AccountData>
        <div onClick={handleChangeVisability}>
          <SVG.CloseIcon />
        </div>
      </AccountInfoContainer>

      <ButtonsContainer>
        <AccountInfoButton>
          <a target='_blank' href={`http://bob.americanenergyassets.com/user/${user?.id}`}>
            <SVG.AccountInfo />
            <Typography.TextS cssUnique={AccountInfoTitle}>
              Account Information
            </Typography.TextS>
          </a>
        </AccountInfoButton>
        <LogoutButton onClick={handleLogoutClick}>
          <SVG.Logout />
          <Typography.TextS cssUnique={LogoutTitle}>
            Log out
          </Typography.TextS>
        </LogoutButton>
      </ButtonsContainer>

    </AccountContainer>
  );
};

AccountInfoPopup.propTypes = {
  isVisible: PropTypes.bool,
  onChangeVisability: PropTypes.func,
};

export default AccountInfoPopup;
