import React, { useState } from 'react';
import styled from 'styled-components/macro';

// images
import background from './images/back.jpeg';
import RecoveryForm from '../../components/RecoveryForm/RecoveryForm.jsx';
import LogoAuthentication from '../../components/LogoAuthentication/LogoAuthentication.jsx';
import SuccessPopup from '../../components/SuccessPopup/SuccessPopup.jsx';

const RecoveryPage = () => {
  const [isSuccessPopup, setIsSuccessPopup] = useState(false);
  const [emailText, setEmailText] = useState('');

  const CONTENT = {
    title: 'Your password reset email has been sent!',
    content: `We have sent a password reset email to your email address: ${emailText}`,
  };

  const handleChangeSuccessStatus = (email) => () => {
    setIsSuccessPopup(true);
    setEmailText(email);
  };
  return (
        <Container>
            <LogoAuthentication />
            {
                isSuccessPopup
                  ? <SuccessPopup
                        title={CONTENT.title}
                        content={CONTENT.content}
                    />
                  : <RecoveryForm
                        onChangeSuccessStatus={handleChangeSuccessStatus}
                    />
            }
        </Container>
  );
};

export default RecoveryPage;

const Container = styled.div`
    background: url(${background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;
