import styled, { css } from 'styled-components/macro';
import { COLORS, TRANSITIONS } from '../../../styles/Theme';

export const AccountContainer = styled.div`
    width: 305px;
    height: 102px;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: ${TRANSITIONS.FAST};
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
    box-shadow: 0px 0px 25px rgba(55, 114, 182, 0.2);
    border-radius: 8px;
    z-index: 2; 
    top: 58px;
    right: 16px;

    ${({ isVisible }) => isVisible && css`
        opacity: 1;
        pointer-events: auto;
    `}
`;

export const AccountInfoContainer = styled.div`
    padding: 15px 16px;
    
    svg {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        path {
            fill: ${COLORS.BLUE};
        }
    }
`;

export const AccountData = styled.div`
    display: flex;
    align-items: center;

    img {
        margin-right: 16px;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
`;

export const AccountInfoButton = styled.div`
    display: flex;
    background: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    font-weight: 600;
    align-items: center;
    padding: 7px 16px;
    border-bottom-left-radius: 8px;
    width: 188px;
    cursor: pointer;

    svg {
        margin-right: 6px;
    }

    a {
        color: ${COLORS.WHITE};
        text-decoration: none;
        display: flex;
    }
`;

export const AccountInfoTitle = css`
    font-weight: bold;
`;

export const LogoutTitle = css`
    font-weight: bold;
`;

export const AccountNameTitle = css`
    font-weight: bold;
`;

export const LogoutButton = styled.div`
    display: flex;
    color: ${COLORS.BLUE};
    padding: 7px 21px;
    border: 1px solid ${COLORS.BLUE};
    border-bottom-right-radius: 8px;
    width: 117px;
    cursor: pointer;

    svg {
        margin-right: 6px;
    }
`;
