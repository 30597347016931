import styled, { css } from 'styled-components/macro';
import sharedProps from '../../../styles/sharedProps';

// Images
import arrowTop from '../../../images/arrowTop.png';
import { COLORS, FONTS, TRANSITIONS } from '../../../styles/Theme';
import { less } from '../../../styles';

export const TableContainer = styled.section`
  margin-top: 8px;
  position: relative;
  width: inherit;

  ${less.laptopS(css`
    width: calc(100vw - 48px);

    ${({ isVisible }) => isVisible && css`
      width: calc(100vw - 48px - 208px);
    `}
  `)}

  ${less.tabletL(css`
    width: calc(100vw - 48px);
  `)}
`;

export const TableHeader = styled.div`
display: flex;
padding: 0 12px;
align-items: center;
height: 52px;
`;

export const HeaderTitle = css`
margin-left: 42px;
font-weight: bold;
margin-right: 16px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: calc(100vh - 72px - 38px - 17px - 94px - 16px - 86px - 8px - 51px - 16px - 36px + 54px);

  ${({ isEmpty }) => !isEmpty && css`
    overflow-y: hidden;
  `}

  ${less.laptopS(css`
    min-height: calc(100vh - 72px - 38px - 17px - 94px - 16px - 48px - 8px - 51px - 16px - 36px - 22px);
  `)}

  ${less.tabletL(css`
    min-height: calc(100vh - 72px - 38px - 17px - 94px - 16px - 48px - 8px - 51px - 16px -36px - 110px);
  `)}

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: ${COLORS.LIGHTER_BLUE};
    border-radius: 8px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.LIGHT_BLUE};
    border-radius: 8px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.BLUE};
    cursor: pointer;
  }
`;

export const THEAD = styled.thead`
background: linear-gradient(51.64deg, #3772B6 -15.06%, #1D4E86 139.87%);
position: sticky;
top: 0;
z-index: 1;
width: 100%;

${({ isStatusVisible }) => isStatusVisible && css`
  z-index: 2;
`}

& > tr {
  height: 54px;
  padding: 0 12px;

  &:hover {
    background: linear-gradient(51.64deg, #3772B6 -15.06%, #1D4E86 139.87%);
  }
}

${less.laptopS(css`
  min-width: 1340px;
`)}
`;

export const TBODY = styled.tbody`
position: relative;
width: 100%;
border-bottom-left-radius: 16px;
border-bottom-right-radius: 16px;
z-index: 0;

&:hover {
  z-index: 1;
}

${less.laptopS(css`
  min-width: 1340px;
`)}
`;

export const TH = styled.th`
  color: ${COLORS.WHITE};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0 12px;
  text-align: left;
  cursor: pointer;

& > div:first-child {
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
  }

  ${({ isAssociated }) => isAssociated && css`
    & > div:first-child {
      font-weight: bold;
      width: 80px;
    }
  `}

  ${({ columnButton }) => columnButton && css`
    justify-content: center;
    width: auto;
  `}

  ${({ expander }) => expander && css`
    display: none;
  `}
`;

export const TR = styled.tr`
  display: flex;
  justify-content: space-between;
  transition: ${TRANSITIONS.FAST};
  position: relative;
  padding: 0 12px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
  }

  &:hover {
    background: ${COLORS.LIGHTER_BLUE};
  }

  ${({ isExpanded }) => isExpanded && css`
    background: ${COLORS.WHITE} !important;
    padding: 0;
  `}

  ${({ isOnlyStatus }) => isOnlyStatus && css`
    justify-content: flex-end;
  `}
`;

export const TD = styled.td`
  padding: 8px 12px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-align: left;
  left: 0;
  word-break: break-word;

  & > img {
    width: 30px;
    margin-right: 8px;
  }
  
  ${({ columnButton }) => columnButton && css`
    justify-content: center;
    width: auto;
  `}

  ${({ expander }) => expander && css`
    position: absolute;
    width: 100%;
    height: 100%;
  `}

  ${({ isStatusButton }) => isStatusButton && css`
    & > div {
      z-index: 1;
    }
  `}

  ${({ isExpanded }) => isExpanded && css`
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%) !important;
    border-radius: 8px;
    margin: 8px;
  `}

  ${({ isMaxHeight }) => isMaxHeight && css`
    & > div:nth-child(1) {
      max-height: 144px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      width: 100%;
    }
  `}

  ${({ isTooltip }) => isTooltip && css`
    z-index: 2;
    position: relative;
    cursor: default;

    &:hover > div {
      opacity: 1;
    }
  `}
`;

export const LoginRadioWrapp = styled.div`
  color: ${COLORS.PALESILVER};
  display: flex;

  input {
  cursor: pointer;

  &:focus {
      cursor: pointer;
    }
  }
`;

export const ProjectsItemMore = styled.div`
  justify-self: flex-end;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1;

&:hover {
  > div {
    opacity: 1;
    visibility: visible;
  }
}
`;

export const ProjectsItemPopup = styled.div`
  display: grid;
  grid-gap: 20px 0;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  background: ${COLORS.WHITE};
  padding: 15px 15px 10px;
  border: 1px solid ${COLORS.PALESILVER};
  visibility: hidden;
  opacity: 0;
  color: ${COLORS.BROWN};
  transition: ${TRANSITIONS.NORMAL};
  z-index: 2;

  &:before {
    content: "";
    background: ${COLORS.WHITE};
    transform: rotate(-45deg) translateY(-10px);
    width: 12px;
    height: 12px;
    position: absolute;
    top: 0;
    right: 18px;
    border-right: 1px solid ${COLORS.PALESILVER};
    border-top: 1px solid ${COLORS.PALESILVER};
    z-index: 1;
}

div {
  cursor: pointer;
}
`;

export const Points = styled.div`
  transform: rotateZ(90deg);
  display: flex;
  justify-content: center;
`;

export const CustomCheckbox = styled.input`
  height: 16px;
  width: 16px;
  border: 1px solid ${COLORS.PALESILVER};
  position: relative;
  ${sharedProps};

&::after {
  content: url(${arrowTop});
  display: none;
  width: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

${(props) => props.value && css`
  background: ${COLORS.BROWN};
  border: none;

  &::after {
    display: block;
  }
`}
`;

export const TableTabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLORS.SEPAR};
`;

export const TabItem = styled.div`
  padding: 16px;
  position: relative;
  font-family: ${FONTS.LATO};
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: ${COLORS.SILVERPINK};
  cursor: pointer;

&::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: ${COLORS.BROWN};
  transition: ${TRANSITIONS.FAST};
  left: 0;
  bottom: 0;
}

&:hover {
  &::after {
    width: 100%;
  }
}

${(props) => props.active && css`
  color: ${COLORS.BROWN};
  &::after {
    width: 100%;
  }
`}
`;

export const ButtonDelete = css`
  font-family: ${FONTS.LATO};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.BROWN};
  padding: 6px 12px;
  width: auto;
  height: 28px;
`;

export const AllSelectTitle = css`
font-weight: bold;
color: ${COLORS.DARKBROWN};
margin-right: 16px;
margin-left: 42px;
`;

export const ButtonSelect = styled.div`
  border: 1px solid ${COLORS.BROWN};
  padding: 6px 12px;
  margin-right: 16px;
  color: ${COLORS.BROWN};
  display: flex;
`;

export const AddButton = css`
  font-family: ${FONTS.LATO};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 12px;
  height: auto;
  width: auto;
`;

export const SortButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;

  svg {
    width: 9px;
    margin-left: 3px;
    transition: ${TRANSITIONS.FAST};
  }

  svg:last-child {
    transform: rotateZ(180deg);
    margin-top: 3px;
  }
`;

export const PlusButton = styled.div`
  display: flex;
`;

export const RowSubComponent = styled.div`
  padding: 8px 0;
  width: 100%;
`;

export const SubComponentTitle = css`
  color: ${COLORS.LIGHT_BLUE};
  font-weight: bold;
  margin-bottom: 16px;
  display: inline;
`;

export const SubComponentMainData = styled.div`
  display: flex;
  flex-direction: column;
  blockquote {
      border-left: 5px solid ${COLORS.LIGHT_BLUE};
  }
  h2 {
      font-family: ${FONTS.OPEN_SANS};
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      text-transform: capitalize;
      color: ${COLORS.BLACK_LIGHT};
  }
  h3 {
      font-family: ${FONTS.OPEN_SANS};
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      text-transform: capitalize;
      color: ${COLORS.BLACK_LIGHT};
  }
  h4 {
      font-family: ${FONTS.OPEN_SANS};
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: ${COLORS.BLACK_LIGHT};
  }
  p {
      font-family: ${FONTS.OPEN_SANS};
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 26px;
      color: ${COLORS.BLACK};
  }
  i {
      font-style: italic;
      font-family: ${FONTS.OPEN_SANS};
      font-weight: normal;
      font-size: 15px;
      line-height: 26px;
  }
  b, strong {
      font-weight: bold;
      color: ${COLORS.BLACK};
  }
  a {
      color: ${COLORS.LIGHT_BLUE};
      text-decoration: none;
  }
  ul {
      list-style-type: disc;
      list-style-position: inside;
  }
  ol {
      list-style-type: decimal;
      list-style-position: inside;
  }
  ul ul, ol ul {
      list-style-position: inside;
      margin-left: 34px;
      li {
          margin-left: 0;
          margin-block-start: 8px;
          margin-block-end: 8px;
          &::before {
              border: 1px solid ${COLORS.RED};
              background: transparent;
          }
      }
  }
  ol ol, ul ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      li {
          &::before {
              display: none;
          }
      }
  }
  ol li, ul li {
      list-style: inherit;
      display: list-item;
      margin-left: 22px;
      margin-block-start: 8px;
      margin-block-end: 8px;
      margin-inline-end: 0px;
      font-family: ${FONTS.LATO};
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 26px;
      color: ${COLORS.BLACK};
  }
  ul li {
      position: relative;
      &::before {
          content: '';
          display: block;
          position: absolute;
          left: -22px;
          top: 10px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: ${COLORS.RED};
      }
  }
`;

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div > div {
    min-width: 108px;
    width: 100%;
  }

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }

  & > div {
    width: 15%;
  }
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div > div {
    min-width: 134px;
    width: 100%;
  }

  & > div:not(:last-child) {
    margin-bottom: 8px;
    white-space: nowrap;
  }
`;

export const LastContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  & > div > div {
    min-width: 80px;
    width: 100%;
    /* white-space: nowrap; */
  }

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }

  & > div {
    width: 49%;
  }
`;

export const FacilityItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  margin-bottom: 24px;

  ${({ notes }) => notes && css`
    & > div:last-child {
      max-width: 80%;
    }
  `}
  
  & > div:first-child {
    color: ${COLORS.BLACK_LIGHT};
    margin-right: 16px;
    font-weight: bold;
    white-space: nowrap;
    width: 100%;
    /* max-width: 600px; */
  }
  p {
    white-space: normal;
  }

  figure {
    display: flex;
    flex-direction: column-reverse;
    margin: 1em auto;

    img {
      /* max-width: min-content; */
      max-width: 100%;
      width: fit-content;
    }
    
    table {
      width: fit-content;

      td {
        min-width: 2em;
        padding: .4em;
        border: 1px solid #bfbfbf;
        white-space: break-spaces;
      } 
    }
  }

  ul {
    list-style: unset;
  }

  ol {
    list-style: decimal;
    list-style-position: inside;
    margin-top: calc(0.6em * 1.5);
  }

  li {
    white-space: pre-line;
    list-style: inherit;
    display: list-item;
    margin-left: 22px;
    margin-block-start: 4px;
    margin-block-end: 4px;
    margin-inline-end: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 26px;
    color: #000000;
  }
`;

export const FacilityItem = css`

`;

export const FirstItemTitle = css`
`;

export const MiddleItemTitle = css`
  min-width: 134px;
`;

export const LastItemTitle = css`
  min-width: 80px;
`;

export const FacilityItemValue = css``;

export const ButtonEdit = styled.div`
  padding: 7px 16px;
  background: ${COLORS.LIGHT_BLUE};
  border-radius: 8px;
  justify-content: center;
  width: 91px;
  cursor: pointer;
  display: inline-flex;
  margin-left: 8px;
  margin-bottom: 25px;

  & div {
    color: ${COLORS.WHITE};
    font-weight: bold;
  }
`;

export const TableFooter = styled.div`
  background: linear-gradient(51.64deg,#3772B6 -15.06%,#1D4E86 139.87%);
  height: 36px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const StatusHoverContainer = styled.div`
  padding: 4px 8px;
  background: #C4C4C4;
  border-radius: 8px;
  position: absolute !important;
  right: 50%;
  transform: translateX(calc(50%));
  bottom: 28px;
  opacity: 0;
  pointer-events: none;
  transition: ${TRANSITIONS.FAST};
  width: max-content;
  background: rgba(44, 66, 92, 0.77);
  max-width: 200px;
  z-index: 13;
  transition-delay: ${TRANSITIONS.FAST};

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0;
    bottom: -12px;
    width: 0;
    right: 50%;
    transform: translateX(25%);
    border: 6px solid transparent;
    border-bottom-color: rgba(44, 66, 92, 0.77);
    transform: rotatez(-180deg);
  }
`;

export const TitleDescription = css`
  font-size: 12px;
  color: ${COLORS.WHITE};
`;
