import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { STATUS_CODE_UNAUTHORIZED } from '../../../constants/constants';
import getTasksLatestCompleted from '../../../services/getTasksLatestCompleted';
import formatToStringGap from '../../../utils/formatToStringGap';
import Statuses from '../Statuses';
import Typography from '../Typography';
import {
  BlockHeader,
  BlockContainer,
  BlockTableHeader,
  TitleDescription,
  TitleSaving,
  TitleStatus,
  BlockTableContent,
  BlockTableContainer,
} from './styles';

const LatestCompletedBlock = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);
  const [completedTasks, setCompletedTasks] = useState([]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getTasksLatestCompleted())
        .then((data) => {
          if (data.response?.status === STATUS_CODE_UNAUTHORIZED) {
            setCompletedTasks([]);
            return;
          }
          setCompletedTasks(data);
        });
    }
  }, [organizationId]);

  return (
    <>
      <BlockHeader>
        <Typography.TextS>
          Latest completed tasks
        </Typography.TextS>
      </BlockHeader>
      <BlockContainer>
        <BlockTableHeader>
          <TitleDescription>
            <Typography.TextS>
              Task Description
            </Typography.TextS>
          </TitleDescription>
          <TitleSaving>
            <Typography.TextS>
              Saving ($)
            </Typography.TextS>
          </TitleSaving>
          <TitleStatus>
            <Typography.TextS>
              Status
            </Typography.TextS>
          </TitleStatus>
        </BlockTableHeader>
        <BlockTableContent>
          {
            completedTasks.map(({
              id, title, saving,
            }) => (
              <BlockTableContainer key={id}>
                <TitleDescription>
                  <Typography.TextS>
                    {title}
                  </Typography.TextS>
                </TitleDescription>
                <TitleSaving>
                  <Typography.TextS>
                    {formatToStringGap(saving)}
                  </Typography.TextS>
                </TitleSaving>
                <TitleStatus>
                  <Statuses.CompletedStatus />
                </TitleStatus>
              </BlockTableContainer>
            ))
          }
        </BlockTableContent>
      </BlockContainer>
    </>
  );
};

export default LatestCompletedBlock;
