import React, { memo, useCallback, useState } from 'react';
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Legend,
  Cell,
} from 'recharts';
import { less } from '../../../../styles';
import { COLORS, FONTS } from '../../../../styles/Theme';

const COLORS_DATA = [
  COLORS.GREEN_DARK,
  COLORS.ORANGE_DARK,
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 0) * cos;
  const sy = cy + (outerRadius + 0) * sin;
  const mx = window.innerWidth > less.tabletL
    ? cx + (outerRadius + 15) * cos
    : cx + (outerRadius + 7) * cos;
  const my = window.innerWidth > less.tabletL
    ? cy + (outerRadius + 15) * sin
    : cy + (outerRadius + 7) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={COLORS.BLUE}
        fontSize={13}
      >{`${value}%`}</text>
    </g>
  );
};

const App = memo(({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  return (
    <div style={{ width: '100%', height: 'calc(100% - 30px)', padding: '16px 0' }}>
      <ResponsiveContainer>
        <PieChart >
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            innerRadius={'55%'}
            outerRadius={'65%'}
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {data.map((item, index) => (
              <Cell key={`cell-${index}`} fill={COLORS_DATA[index]} />
            ))}
          </Pie>
          <Legend
            wrapperStyle={{
              fontFamily: `${FONTS.OPEN_SANS}`,
              fontSize: '13px',
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
});

export default App;
