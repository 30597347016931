import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Typography from '../Typography';

import {
  LoginContainer,
  LoginInnerContainer,
  LoginTitle,
  LoginFormContainer,
  RegistrationLink,
  StyledInput,
  ButtonSubmit,
  ErrorMessage,
} from './styles';
import { signUpClientValidatorsMap } from '../../../utils/validatorsMap';
import SVG from '../SVG';

import signup from '../../../services/signup';

const FIELDS = ['name', 'customer', 'email', 'password'];

const RegisterForm = ({ onChangeSuccessStatus, history }) => {
  const [name, setName] = useState('');
  const [customer, setCustomer] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isNameValid, setIsNameValid] = useState(true);
  const [isCustomerValid, setIsCustomerValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [error, setError] = useState('');
  const [isSubmitBlocked, setIsSubmitBlocked] = useState(true);

  useEffect(() => {
    setIsSubmitBlocked(validate(true));
  }, [name, customer, email, password]);

  const handleNameChanges = (value) => {
    setName(value);
  };

  const handleCustomerChanges = (value) => {
    setCustomer(value);
  };

  const handleEmailChanges = (value) => {
    setEmail(value);
  };

  const handlePasswordChanges = (value) => {
    setPassword(value);
  };

  const validate = (isButton) => {
    let isValid = true;

    const field = {
      name,
      customer,
      email,
      password,
    };

    const fieldChangeState = {
      name: setIsNameValid,
      customer: setIsCustomerValid,
      email: setIsEmailValid,
      password: setIsPasswordValid,
    };

    FIELDS.forEach((name) => {
      let errorTexts;
      const validators = signUpClientValidatorsMap[name];

      validators && validators.forEach((validator) => {
        if (errorTexts) {
          return;
        }

        const filedErrorTexts = validator(field[name]);

        if (!filedErrorTexts) {
          isValid = false;
          errorTexts = filedErrorTexts;
        }
      });

      !isButton && fieldChangeState[name](errorTexts === undefined);
    });
    return !isValid;
  };

  const onBlur = (name) => () => {
    const field = {
      name,
      customer,
      email,
      password,
    };

    const fieldChangeState = {
      name: setIsNameValid,
      customer: setIsCustomerValid,
      email: setIsEmailValid,
      password: setIsPasswordValid,
    };

    let errorTexts;
    const validators = signUpClientValidatorsMap[name];

    validators
      && validators.forEach((validator) => {
        if (errorTexts) {
          return;
        }

        const isEmptyString = field[name].trim() === '';
        const filedErrorTexts = isEmptyString || validator(field[name]);

        if (filedErrorTexts) {
          errorTexts = filedErrorTexts;
        }
      });

    fieldChangeState[name](!!errorTexts);
  };

  const handleClickBack = () => {
    history.goBack();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const isNotValid = validate();

    if (isNotValid) {
      return;
    }

    const data = {
      username: name,
      customer,
      email,
      password,
    };

    setError('');

    signup(data)
      .then(onChangeSuccessStatus(email))
      .catch((err) => {
        setError(err.response?.data?.errors?.[0] || err.response?.data?.message);
      });
  };

  return (
    <LoginContainer>
      <LoginInnerContainer>
        <Typography.H3 cssUnique={LoginTitle}>
          Registration
        </Typography.H3>
        <LoginFormContainer autoComplete="off" onSubmit={onSubmit}>
          <StyledInput
            labelText="Username"
            type="text"
            id="input-name"
            value={name}
            onChange={handleNameChanges}
            isError={!isNameValid}
            onBlur={onBlur('name')}
          />

          <StyledInput
            labelText="Customer"
            type="text"
            id="input-customer"
            value={customer}
            onChange={handleCustomerChanges}
            isError={!isCustomerValid}
            onBlur={onBlur('customer')}
          />

          <StyledInput
            labelText="Email Address"
            type="email"
            id="input-email"
            value={email}
            onChange={handleEmailChanges}
            isError={!isEmailValid}
            onBlur={onBlur('email')}
          />

          <StyledInput
            labelText="Password"
            type="password"
            id="input-password"
            value={password}
            onChange={handlePasswordChanges}
            isError={!isPasswordValid}
            onBlur={onBlur('password')}
            lastEl
          />

          <ButtonSubmit
            type="submit"
            blocked={isSubmitBlocked}
          >
            Create account
          </ButtonSubmit>
          {error && <ErrorMessage>
            <Typography.TextS>
              {error}
            </Typography.TextS>
          </ErrorMessage>}
        </LoginFormContainer>
        <RegistrationLink onClick={handleClickBack}>
          <div>
            <SVG.ArrowBack />
            Back
          </div>
        </RegistrationLink>
      </LoginInnerContainer>
    </LoginContainer>
  );
};

RegisterForm.propTypes = {
  onChangeSuccessStatus: PropTypes.func,
};

export default withRouter(RegisterForm);
