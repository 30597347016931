import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Typography from '../Typography';

import {
  LoginContainer,
  LoginInnerContainer,
  SuccessTitle,
  SuccessDescription,
  LinkSubmit,
} from './styles';

const SuccessPopup = ({
  title, content, registration, isRegister, onReturn,
}) => (
  <LoginContainer>
    <LoginInnerContainer registration={registration}>
      <Typography.H5 cssUnique={SuccessTitle}>
        {title}
      </Typography.H5>

      <Typography.TextS cssUnique={SuccessDescription}>
        {content}
      </Typography.TextS>

      {
        <LinkSubmit onClick={isRegister ? onReturn : null}>
          {
            isRegister
              ? <a>Back</a>
              : <Link to="/login">
                Log in
              </Link>
          }
        </LinkSubmit>
      }

    </LoginInnerContainer>
  </LoginContainer>
);

SuccessPopup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  registration: PropTypes.bool,
  isRegister: PropTypes.bool,
};

export default SuccessPopup;
