import React from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '../Typography';

import {
  ItemLink,
  FooterContainer,
  LeftContainer,
  RightContainer,
  FooterBottomData,
} from './styles';

const Footer = ({ history, dashboard }) => {
  const redirectToTaskManager = () => {
    history.push('/project-center/task-manager');
  };

  return (
    <FooterContainer>
      <LeftContainer>
        <Typography.TextS cssUnique={ItemLink}>
          <a href="https://americanenergyassets.com/" target="_blank" rel="noreferrer">
            Home
          </a>
        </Typography.TextS>

        {
          dashboard
          && <Typography.TextS onClick={redirectToTaskManager} cssUnique={ItemLink}>
            Dashboard
          </Typography.TextS>
        }

        <Typography.TextS cssUnique={ItemLink}>
          <a href="https://americanenergyassets.com/contact-us/" target="_blank" rel="noreferrer">
            Contact Us
          </a>
        </Typography.TextS>

      </LeftContainer>

      <RightContainer>
        <Typography.TextS cssUnique={FooterBottomData}>
          © 2021 American Energy Assets
        </Typography.TextS>
      </RightContainer>
    </FooterContainer>
  );
};

export default withRouter(Footer);
