import styled, { css } from 'styled-components/macro';
import { less, lessH } from '../../../styles';

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 213px;

    img:first-child {
        width: 52px;
        margin-top: 24px;
        margin-left: 2px;
    }

    img:last-child {
        width: 213px;
    }

    ${({ isSignUp }) => isSignUp && css`
        cursor: pointer;
    `}

    ${less.tabletL(css`
        transform: scale(.8);
    `)};

    ${lessH.tablet(css`
        transform: scale(.8);
    `)};
`;
