import styled, { css } from 'styled-components/macro';
import { COLORS } from '../../../styles/Theme';

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 0 0;
`;

export const ItemLink = css`
    font-size: 12px;
    color: ${COLORS.GREY_LIGHTER};
    margin-right: 24px;
    cursor: pointer;
    
    a{
        font-size: 12px;
        color: ${COLORS.GREY_LIGHTER};
        text-decoration: none;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
`;

export const RightContainer = styled.div`

`;

export const FooterBottomData = css`
    color: ${COLORS.GREY_LIGHTER};
`;
