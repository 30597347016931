import styled, { css } from 'styled-components/macro';
import { COLORS } from '../../../styles/Theme';

export const InfoContainer = styled.div`
    padding: 24px;
    background: ${COLORS.WHITE};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 16px;
    background: ${COLORS.WHITE};
    margin-bottom: 16px;
    min-height: 35%;
    height: 100%;
`;

export const DoughnutChartInnerContainer = styled.div`
  position: relative; 
  max-width: 84px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  &:before {
    content: '93 Items';
    position: absolute;
    pointer-events: none;
    width: 38px;
    text-align: center;
    }
`;

export const FacilityTitle = css`
    font-weight: bold;
    color: ${COLORS.BLUE};
    margin-bottom: 15px;
`;

export const FacilityInfoContainer = styled.div``;

export const FacilityItemContainer = styled.div`
    display: flex;
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        text-decoration: none;
    }
`;

export const FacilityItemTitle = css`
    margin-right: 8px;
    font-weight: bold;
    min-width: 143px;
    max-width: 143px;
`;

export const FacilityItemValue = css``;
export const FacilityItemMember = css`
    color: ${COLORS.LIGHT_BLUE};
`;

export const DoughnutChartContainer = styled.div`
    max-width: 408px;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const LeftColumn = styled.div`
    display: flex;
    align-items: center;
`;

export const DoughnutStatisticBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: 26px;
    height: 50px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-content: flex-start;
    position: relative;
`;

export const DoughnutStatusItem = styled.div`
    width: 89px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 150.68%;
    color: ${COLORS.BLACK_LIGHT};

    & > div:first-child {
        margin-right: 8px;
    }
`;

export const DownloadButton = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: ${COLORS.LIGHTER_BLUE};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
`;
