import axios from 'axios';
import ROUTES from '../api/routes';

export default function recovery({ email }) {
  const data = JSON.stringify({
    email,
  });

  return axios.post(
    ROUTES.RECOVERY,
    data,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    },
  )
    .then(({ data }) => data)
    .catch((err) => err);
}
