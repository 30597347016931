import React, {
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components/macro';

import { COLORS, FONTS, TRANSITIONS } from '../../../styles/Theme';
import useOutsideClick from '../../../utils/useOutsideClick';
import SVG from '../SVG';

const SelectField = ({
  onLoadOptions, value, onChange,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdown = useRef(null);

  useOutsideClick(dropdown, () => {
    if (isMenuOpen) onMenuClose();
  });

  const colourStyles = useMemo(() => ({
    control: (styles) => ({
      ...styles,
      border: 'none',
      borderRadius: '16px',
      width: '391px',
      minHeight: '0',
      boxShadow: 'none !important',
      '&:last-child span': {
        display: 'none !important',
      },
    }),
    option: (styles) => ({
      ...styles,
      color: COLORS.BLACK_LIGHT,
      padding: '6px 0',
      fontSize: '13px',
      lineHeight: '18px',
      cursor: 'pointer',
      width: 'auto',
      fontStyle: 'normal',
      display: 'inherit',
      textAlign: 'left',
      background: COLORS.WHITE,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      transition: TRANSITIONS.FAST,
      ':hover': {
        color: COLORS.BLUE,
        fontWeight: 'bold',
      },
      ':focus': {
        border: 'none',
      },
      ':active': {
        ...styles[':active'],
        border: 'none',
        background: 'white',
        fontStyle: 'normal',
        color: COLORS.BLUE,
      },
    }),
    input: (styles) => ({
      ...styles,
      paddingLeft: '14px',
    }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({
      ...styles,
      fontWeight: 'bold',
      fontFamily: FONTS.SF_PRO_TEXT,
      color: isMenuOpen ? COLORS.BLACK_DARK : COLORS.WHITE,
      fontSize: '14px',
      lineHeight: '16px',
      width: '100%',
      margin: '0',
      top: '0',
      padding: '12px',
      maxWidth: '100%',
      transform: 'none',
      paddingRight: '28px',
      transition: TRANSITIONS.FAST,
    }),
    loadingMessage: (styles) => ({
      ...styles,
      textAlign: 'left !important',
      fontFamily: FONTS.SF_PRO_TEXT,
      padding: '10px 0',
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      textAlign: 'left !important',
      padding: '10px 0',
    }),
    loadingIndicator: (styles) => ({
      ...styles,
      position: 'absolute',
      right: 0,
    }),
    menu: (styles) => ({
      ...styles,
      background: 'white',
      padding: '0 10px',
      borderRadius: '8px',
      overflow: 'hidden',
      marginTop: '4px',
      marginBottom: '8px',
      fontFamily: FONTS.SF_PRO_TEXT,
      boxShadow: '0px 0px 10px rgba(55, 114, 182, 0.13)',
      fontSize: '12px',
      '& > div::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
      },
      '& > div::-webkit-scrollbar-thumb': {
        background: 'linear-gradient(93.81deg, #F86E0B 0%, #E82521 100%)',
        borderRadius: '3px',
      },
      '& > div': {
        paddingBottom: '0px',
        paddingTop: '0px',
      },
    }),
    indicatorSeparator: (styles, { isFocused }) => ({
      ...styles,
      display: 'none',
      '& + div': {
        display: 'none',
        '& path': {
          fill: isMenuOpen ? COLORS.BLACK_DARK : COLORS.WHITE,
        },
        cursor: 'pointer',
        transition: `${TRANSITIONS.FAST}`,
        transform: isFocused ? 'rotateX(180deg)' : null,
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '40px',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      height: '32px',
      borderRadius: '16px',
      background: 'white',
      textAlign: 'left',
      color: COLORS.WHITE,
      transition: TRANSITIONS.FAST,
      padding: '0px 10px',
      border: 'none !important',
      outline: 'none !important',
      boxShadow: '0px 0px 10px rgba(55, 114, 182, 0.13) !important',
    }),
  }), [isMenuOpen]);

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const loadOptions = (inputValue, callback) => {
    onLoadOptions(inputValue, callback);
  };

  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <>
      <Container ref={dropdown}>
        <SVG.SearchIcon />
        <AsyncSelect
          cacheOptions
          onMenuClose={onMenuClose}
          onMenuOpen={onMenuOpen}
          loadOptions={loadOptions}
          onChange={handleChange}
          styles={colourStyles}
          placeholder={''}
          isLoading={false}
          value={value}
        />
      </Container>
    </>
  );
};

SelectField.propTypes = {
  onLoadOptions: PropTypes.func.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default SelectField;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 38px;
  border: none !important;
  outline: none !important;
  }

  svg {
    transition: ${TRANSITIONS.FAST};
    opacity: 1;
    position: absolute;
    left: 8px;
    z-index: 1;
    pointer-events: none;
  }
`;
