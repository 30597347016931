import styled, { css } from 'styled-components/macro';
import sharedProps from '../../../styles/sharedProps';
import { COLORS, TRANSITIONS } from '../../../styles/Theme';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15px;
  margin-bottom: 28px;
  height: 44px;

  svg {
    position: absolute;
    right: 16px;
    margin: auto;
    top: 0;
    bottom: 0;
    cursor: pointer;
    transition: ${TRANSITIONS.FAST};
  }

  ${({ isValid }) => !isValid && css`
    svg {
      right: 56px;
    }
  `}

  ${sharedProps};
`;

export const Label = styled.label`
  top: 31px;
  left: 16px;
  font-size: 13px;
  line-height: 149.18%;
  position: absolute;
  transform: translateY(-100%);
  transition: ${TRANSITIONS.FAST};
  pointer-events: none;

  ${({ isValid }) => (!isValid || isValid !== null
    ? css`
        color: ${COLORS.LIGHT_BLUE};
      `
    : css`
        color: ${COLORS.RED_LIGHT};
      `)}
`;

export const Description = styled.div`
  top: -20px;
  right: 0;
  font-size: 13px;
  line-height: 149.18%;
  position: absolute;
  color: ${COLORS.GREY_LIGHTER2};
`;

export const InputEl = styled.input`
  height: 100%;
  font-size: 13px;
  line-height: 149.18%;
  border: none;
  width: 100%;
  color: ${COLORS.BLACK};
  outline: none;
  box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
  border-radius: 8px;
  padding: 12px 16px;

  ${({ typeCustom }) => typeCustom === 'password' && css`
    -webkit-text-security: disc;
  `}

  ${(props) => props.type === 'password' && css`
    -webkit-text-security: square;
  `}

  ${(props) => props.value.length > 0 && css`
    & + label {
      top: -2px;
      left: 16px;
      font-size: 12px;
      line-height: 150.68%;
      transform: translateY(-100%);
    }
  `}

  &:focus + label {
    top: -2px;
    left: 16px;
    font-size: 12px;
    line-height: 150.68%;
    transform: translateY(-100%);
  }

  ${({ isValid }) => (!isValid || isValid !== null
    ? null
    : css`
          color: ${COLORS.RED_LIGHT};
        `)}
`;

export const TextAreaEl = styled.textarea`
  height: 100%;
  font-size: 13px;
  line-height: 149.18%;
  border: none;
  width: 100%;
  color: ${COLORS.BLACK};
  outline: none;
  box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
  border-radius: 8px;
  padding: 12px 16px;

  ${(props) => props.type === 'password' && css`
    -webkit-text-security: square;
  `}

  ${(props) => props.value.length > 0 && css`
    & + label {
      top: -2px;
      left: 16px;
      font-size: 12px;
      line-height: 150.68%;
      transform: translateY(-100%);
    }
    `}

  &:focus + label {
    top: -2px;
    left: 16px;
    font-size: 12px;
    line-height: 150.68%;
    transform: translateY(-100%);
  }

  ${({ isValid }) => (!isValid || isValid !== null
    ? null
    : css`
      color: ${COLORS.RED_LIGHT};
    `)}
`;

export const SVGContainer = styled.div`
  svg path {
    ${({ isValid }) => (!isValid || isValid !== null
    ? css`
          stroke: ${COLORS.LIGHT_BLUE};
        `
    : css`
            stroke: ${COLORS.RED_LIGHT} !important;
          `)}
  }
`;

export const SVGContainerQuenstion = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  background: #EE6485;
  right: 16px;
  margin: auto;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: ${TRANSITIONS.FAST};
  z-index: 1;


  svg {
    right: 9px !important;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  ${({ isValid }) => isValid && css`
    opacity: 1;
    pointer-events: auto;
  `}

  &:hover + div {
    pointer-events: auto;
    & > div {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;

export const ErrorModal = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 47px;
  right: 0;
  pointer-events: none;
`;

export const ErrorModalContainer = styled.div`
  position: relative;
  width: 194px;
  border-radius: 8px;
  padding: 8px;
  background: ${COLORS.RED_LIGHT};
  opacity: 0;
  pointer-events: none;
  transition: ${TRANSITIONS.FAST};

  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    height: 0;
    top: 60px;
    width: 0;
    left: calc(100% - 43px);
    border: 15px solid transparent;
    border-bottom-color: ${COLORS.RED_LIGHT};
    transform: rotateX(180deg)
    }
`;

export const ErrorModalTitle = css`
  font-size: 12px;
  color: ${COLORS.WHITE};
`;

export const CustomContainer = styled.div`
  width: 9px;
  height: 9px;
  position: relative;
  margin: 0;
  cursor: pointer;
  margin-right: 9px;

  &:after {
    content: '';
    position: absolute;
    height: 9px;
    width: 9px;
    border: 1px solid ${COLORS.LIGHT_BLUE};
    border-radius: 2px;
    transition: ${TRANSITIONS.FAST};
    box-sizing: border-box;
    background: ${COLORS.WHITE};
    left: 0;

    ${({ type }) => type === 'radio' && css`
      border-radius: 50%;
    `}
  }

  ${({ value }) => value && css`
    &:after {
      background: ${COLORS.LIGHT_BLUE};
    }
  `}
`;

export const CustomCheckbox = styled.input`
  width: 0px;
  height: 0px;
  opacity: 0;
  pointer-events: none;
  position: relative;
  ${sharedProps};
`;
