import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getTasksActions from '../../../services/getTasksActions.js';
import { COLORS } from '../../../styles/Theme.js';
import BarLine from '../charts/BarLine/BarLine.jsx';
import Typography from '../Typography';
import {
  BlockHeader,
  BlockContainer,
} from './styles';

const CHART_DATA = {
  selectId: 0,
  datasets: [
    {
      label: '$',
      backgroundColor: [
        COLORS.PURPLE,
        COLORS.LIGHT_BLUE2,
        COLORS.BLUE,
        COLORS.GREEN_DARK,
        COLORS.RED_LIGHT,
        COLORS.YELLOW,
      ],
    },
  ],
};

const ActionsBlock = memo(() => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (organizationId) {
      dispatch(getTasksActions())
        .then((data) => {
          const labels = data.map((i) => i.title);
          const newData = data.map((i) => i.totalSavings);

          setChartData({
            ...CHART_DATA,
            labels,
            datasets: CHART_DATA.datasets.map((i) => ({ ...i, data: newData })),
          });
        })
        .catch((err) => err);
    }
  }, [organizationId]);
  return (
    <>
      <BlockHeader>
        <Typography.TextS>
          Actions
        </Typography.TextS>
      </BlockHeader>
      <BlockContainer>
        <BarLine data={chartData} isTitle />
      </BlockContainer>
    </>
  );
});

export default ActionsBlock;
