const DOMAIN_API = process.env.REACT_APP_PUBLIC_API;
const API = `${DOMAIN_API}/api`;

const AUTH = {
  LOGIN: `${API}/login`,
  CHECK_ACCOUNT: `${API}/profile`,
  RECOVERY: `${API}/password-recovery`,
  REFRESH: `${API}/auth-token-refresh`,
  SIGNUP: `${API}/signup`,
};

const FACILITIES = {
  GET_BUILDINGS: `${API}/buildings`,
};

const TASKTS = {
  GET_TASKS: `${API}/tasks`,
};

const ROUTES = {
  ...AUTH,
  ...FACILITIES,
  ...TASKTS,
};

export default ROUTES;
