export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  LIGHTER_BLUE: '#E4EDF4',
  GREY_LIGHT: '#F8F8FB',
  BLACK_LIGHT: '#43454D',
  BLUE: '#1D4E86',
  LIGHT_BLUE: '#639FE3',
  LIGHT_BLUE2: '#B1CFF1',
  LIGHT_BLUE3: '#3772B6',
  RED_LIGHT: '#EE6485',
  GREY_LIGHTER: 'rgba(44, 66, 92, 0.77)',
  GREY_LIGHTER2: '#ACBCCF',
  ORANGE_DARK: '#FFA841',
  GREEN_DARK: '#45AAB0',
  PURPLE: '#8E6CED',
  YELLOW: '#F7DB85',
};

export const FONTS = {
  OPEN_SANS: 'OpenSans',
};

export const TRANSITIONS = {
  QUICK: '.25s',
  FAST: '.3s',
  NORMAL: '.5s',
  LONG: '1s',
};
