import styled, { css } from 'styled-components/macro';
import { COLORS } from '../../../styles/Theme';

export const BlockHeader = styled.div`
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
    border-radius: 8px;
    padding: 6px;
    display: flex;
    justify-content: center;
    background:${COLORS.WHITE};

    div {
        color: ${COLORS.BLUE};
        font-weight: bold;
    }
`;

export const BlockContainer = styled.div`
    margin: 8px 24px;
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 8px;
`;

export const BlockTableHeader = styled.div`
    padding: 8px 10px 5px;
    border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TitleChart = css`
    color: ${COLORS.BLUE};
    font-weight: bold;
    margin-top: 13px;
    text-align: center;
`;

export const HeaderTitle = styled.div`
    color: ${COLORS.BLUE};
    font-weight: normal;

    & > div {
        font-size: 12px;
        line-height: 150.68%;
    }

`;

export const TitleDescription = styled(HeaderTitle)`
    max-width: 106px;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const TitleSaving = styled(HeaderTitle)`
    max-width: 72px;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const TitleStatus = styled(HeaderTitle)`
    max-width: 48px;
    width: 100%;
`;

export const BlockTableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 2px 10px;

    & > div {
        color: ${COLORS.BLACK};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
    }
`;

export const BlockTableContent = styled.div``;
