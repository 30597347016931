import styled, { css } from 'styled-components/macro';
import { less } from '../../../styles';
import { COLORS, TRANSITIONS } from '../../../styles/Theme';

export const HeaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    background: none;
    border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    z-index: 100;
    background: linear-gradient(107.41deg,#F8F8FB 49.07%,#FFFCF3 109.41%);
`;

export const HeaderLeftColumn = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderRightColumn = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    
    img {
        margin-right: 8px;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    img:last-child{
        position: relative;
        transform: translateY(3px);
    }
`;

export const PersonName = styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 149.18%;
    color: ${COLORS.BLACK};
`;

export const InputContainer = styled.div`
    margin-left: 38px;
    width: 391px;
    display: flex;
    align-items: center;
    position: relative;

    ${less.tabletL(css`
        width: 291px;
    `)}
`;

export const SearchIcon = styled.div`
    position: absolute;
    opacity: 1;
    transition: ${TRANSITIONS.QUICK};
    pointer-events: none;

    ${(props) => props.icon && css`
        &::before {
            content: url(${props.icon});
            position: absolute;
            top: 2px;
            bottom: 0;
            display: flex;
            align-items: center;
            left: 10px;
        }
    `}
`;

export const InputEl = styled.input`
    width: 100%;
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
    border-radius: 16px;
    background: ${COLORS.WHITE};
    border: none;
    outline: none;
    font-size: 13px;
    line-height: 18px;
    height: 32px;
    padding: 0 10px;

    &:focus + div {
        opacity: 0;
    }

    /* &:focus + div + div > div {
        ${(props) => !!props.value && css`
            opacity: 1;
            pointer-events: auto;
            z-index: 3;
        `}
    } */
`;

export const SuggestionsContainer = styled.div`
    position: absolute;
    top: 42px;
    left: 0;
    width: 100%;
    z-index: 2;
    opacity: 1;
    pointer-events: auto;
`;

export const SuggestionItem = styled.div`
    padding: 4px 0;
    font-size: 13px;
    line-height: 18px;

    a {
        text-decoration: none;
        color: ${COLORS.BLACK_LIGHT};
        width: 100%;
        display: inline-block;
    }
`;

export const SuggestionsInnerContainer = styled.div`
    width: 100%;
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
    background: ${COLORS.WHITE};
    border-radius: 8px;
    transition: ${TRANSITIONS.QUICK};
    padding: 10px;
    height: 100%;
`;
