import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import ROUTES from '../api/routes';

import {
  setAuthorizedAction,
  setUserAction,
  setFacilities,
  setLoading,
} from '../store/actions';
import authRefresh from '../helpers/auth-refresh';
import getAccessToken from '../helpers/getAccessToken';

createAuthRefreshInterceptor(axios, authRefresh);

export default function account() {
  return (dispatch) => axios.get(
    ROUTES.CHECK_ACCOUNT,
    {
      headers: {
        accept: 'application/json',
        Authorization: getAccessToken(),
      },
    },
  )
    .then(({ data }) => {
      if (data) {
        dispatch(setUserAction(data.user));
        dispatch(setFacilities(data.facilities));
        dispatch(setAuthorizedAction(true));
        dispatch(setLoading(false));
      }
      return data.user;
    })
    .catch(() => {
      dispatch(setUserAction(null));
      dispatch(setAuthorizedAction(false));
      dispatch(setLoading(false));
    });
}
