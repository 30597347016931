import styled, { css } from 'styled-components/macro';
import { COLORS, TRANSITIONS } from '../../../styles/Theme';
import { Input } from '../FormComponents/index.jsx';
import { Button } from '../Buttons/styles';
import { less, lessH } from '../../../styles';

export const LoginContainer = styled.div`
    position: relative;
    width: 568px;
    height: 324px;
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
    border-radius: 16px;

    ${less.tabletL(css`
        width: 508px;
        height: 284px;
    `)}

    ${lessH.tablet(css`
        width: 508px;
        height: 284px;
    `)}
`;

export const LoginInnerContainer = styled.div`
    padding: 48px 64px;
    width: 100%;
    height: 100%;

    ${less.tabletL(css`
        padding: 36px 44px;
    `)}

    ${lessH.tablet(css`
        padding: 36px 44px;
    `)}
`;

export const LoginTitle = css`
    color: ${COLORS.BLUE};
    font-weight: bold;
    text-align: center;
    margin-bottom: 29px;

    ${less.tabletL(css`
        margin-bottom: 24px;
    `)}

    ${lessH.tablet(css`
        margin-bottom: 24px;
    `)}
`;

export const LoginFormContainer = styled.form`
    position: relative;
`;

export const RegistrationLink = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;

    svg {
        margin-right: 8px;
    }

    a {
        color: ${COLORS.BLUE};
        font-weight: 600;
        font-weight: bold;
        display: flex;
        align-items: center;
        text-decoration: none !important;
    }
`;

export const AccountTitle = css`
    font-weight: bold;
    color: ${COLORS.BLACK_LIGHT};
    margin-right: 4px;
`;

export const StyledInput = styled(Input)`
    input {
        transition: ${TRANSITIONS.FAST};
        color: ${COLORS.BLACK_LIGHT}
    }

    ${(props) => props.lastEl && css`
        margin-bottom: 0px;
    `}

    ${(props) => props.isError && css`
      color: ${COLORS.RED_LIGHT};

      input {
        color: ${COLORS.RED_LIGHT};
      }

      svg path {
        stroke: ${COLORS.RED_LIGHT} !important;
      }

      label {
        color: ${COLORS.RED_LIGHT};
      }
    `}
`;

export const ForgotPassTitle = css`
    text-align: center;
    font-weight: 600;
    margin-top: 24px; 
`;

export const ButtonSubmit = styled(Button)`
    margin-top: 36px;
    font-weight: bold;

    ${less.tabletL(css`
        margin-top: 24px;
    `)}

    ${lessH.tablet(css`
        margin-top: 24px;
    `)}
`;

export const ErrorMessage = styled.div`
    color: ${COLORS.RED_LIGHT};
    position: absolute;
    bottom: -18px;
    font-weight: 600;
    left: 0;

    div {
        font-weight: bold;
    }
`;
