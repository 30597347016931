import styled, { css } from 'styled-components/macro';
import { COLORS } from '../../../styles/Theme';
import { less, lessH } from '../../../styles';

export const LoginContainer = styled.div`
    position: relative;
    width: 568px;
    height: 280px;
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
    border-radius: 16px;

    ${less.tabletL(css`
        width: 508px;
        height: 234px;
    `)}

    ${lessH.tablet(css`
        width: 508px;
        height: 234px;
    `)}
`;

export const LoginInnerContainer = styled.div`
    padding: 48px 64px;
    width: 100%;
    height: 100%;

    ${({ registration }) => registration && css`
        div {
            max-width: 341px;
        }
    `}

    ${less.tabletL(css`
        padding: 36px 44px;
    `)}

    ${lessH.tablet(css`
        padding: 36px 44px;
    `)}
`;

export const SuccessTitle = css`
    color: ${COLORS.BLUE};
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;

    ${less.tabletL(css`
        margin-bottom: 18px;
    `)}

    ${lessH.tablet(css`
        margin-bottom: 18px;
    `)}
`;

export const SuccessDescription = css`
    color: ${COLORS.BLUE};
    text-align: center;
    margin-bottom: 24px;
    margin-top: 30px;
    margin: auto;
`;

export const LinkSubmit = styled.a`
    margin-top: 49px;
    width: 100%;
    height: 48px;
    display: flex;
    background: #1D4E86;
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    
    a {
        color: ${COLORS.WHITE};
        text-decoration: none;
    }

    ${less.tabletL(css`
        margin-top: 44px;
    `)}

    ${lessH.tablet(css`
        margin-top: 44px;
    `)}
`;
