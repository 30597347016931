import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import useOutsideClick from '../../../utils/useOutsideClick';
import { CB } from '../FormComponents/index.jsx';
import SVG from '../SVG';
import Typography from '../Typography';
import { COLUMNS_TASK_MANAGER } from '../../../constants/ui';

import {
  StatusDropdown,
  StatusButtonContainer,
  StatusTitle,
  StatusDropdownContent,
  StatusDropdownInnerContainer,
  StatusCheckboxItem,
  StatusCheckboxItemTitle,
  ButtonsContainer,
  AcceptButton,
  AcceptButtonTitle,
  ClearButton,
  ClearButtonTitle,
  SelectedTitle,
} from './styles';

const FieldDropdown = memo(({
  data,
  title,
  width,
  cWidth,
  component,
  isCustomCheckbox,
  onAcceptClick,
  onChangeVisibility,
  isVisible,
  onChange,
  exception,
  large,
  isError,
}) => {
  const [isVisibleStatus, setIsVisibleStatus] = useState(isVisible || false);
  const [statusData, setStatusData] = useState(() => data);
  const [isDisabled, setIsDisabled] = useState(true);
  const [previousData, setPreviousData] = useState(() => data);
  const [checkboxesCount, setCheckboxesCount] = useState(0);

  const statusDropdown = useRef(null);

  useOutsideClick(statusDropdown, () => {
    if (isVisibleStatus) {
      handleClickStatus();
      !large && setStatusData(previousData);
      setPreviousData({});
    }
  });

  useEffect(() => {
    !isEmpty(statusData) && setIsDisabled(() => previousData === statusData);
    const count = statusData.filter((i) => i.isChecked).length;
    large && setCheckboxesCount(count);
    large && onChange(statusData);
  }, [statusData]);

  useEffect(() => {
    if (isVisibleStatus) {
      setPreviousData(statusData);
    }
  }, [isVisibleStatus]);

  useEffect(() => {
    setStatusData(data);
  }, [data]);

  const handleClickStatus = () => {
    onChangeVisibility && onChangeVisibility(!isVisibleStatus);
    setIsVisibleStatus(!isVisibleStatus);
  };

  const handleCheck = (id) => () => {
    setStatusData((prev) => prev.map((status) => (
      status.id === id
        ? ({
          ...status,
          isChecked: !status.isChecked,
        })
        : status)));
  };

  const handleClearButton = () => {
    if (exception) {
      setStatusData(COLUMNS_TASK_MANAGER);
    } else {
      setStatusData((prev) => prev.map((status) => ({ ...status, isChecked: false })));
    }
  };

  const handleAcceptClick = () => {
    if (isDisabled) return;
    onAcceptClick(statusData);
    handleClickStatus();
  };

  return (
    <StatusDropdown
      isCustomCheckbox={isCustomCheckbox}
      isVisible={isVisibleStatus}
    >
      <StatusButtonContainer
        width={width}
        isVisible={isVisibleStatus}
        onClick={handleClickStatus}
        isCustomCheckbox={isCustomCheckbox}
        large={large}
        count={checkboxesCount}
        isValid={isError}
      >
        {
          component || <>
            <Typography.TextS cssUnique={StatusTitle}>{title}</Typography.TextS>
            {
              checkboxesCount > 0
              && <Typography.TextS cssUnique={SelectedTitle}>
                Equipment selected ({checkboxesCount})
              </Typography.TextS>
            }
            {
              large
                ? <SVG.ArrowDropDownLarge />
                : <SVG.ArrowDropdown />
            }
          </>
        }
      </StatusButtonContainer>
      <StatusDropdownContent
        ref={statusDropdown}
        width={cWidth}
        isVisible={isVisibleStatus}
        isCustomCheckbox={isCustomCheckbox}
        large={large}
      >
        <StatusDropdownInnerContainer
          large={large}
        >
          {
            statusData
              .filter(({ id }) => id && id !== exception)
              .map(({ id, name, isChecked }) => (
                <StatusCheckboxItem
                  key={id}
                  onClick={handleCheck(id)}
                  large={large}
                >
                  <CB
                    name={name}
                    value={isChecked}
                  />
                  <Typography.TextS
                    cssUnique={StatusCheckboxItemTitle}
                  >
                    {name}
                  </Typography.TextS>
                </StatusCheckboxItem>
              ))
          }
        </StatusDropdownInnerContainer>
        {
          !large
          && <ButtonsContainer>
            <AcceptButton isDisabled={isDisabled} onClick={handleAcceptClick}>
              <Typography.TextS cssUnique={AcceptButtonTitle}>
                Accept
              </Typography.TextS>
            </AcceptButton>
            <ClearButton onClick={handleClearButton}>
              <Typography.TextS cssUnique={ClearButtonTitle}>
                {exception ? 'Reset' : 'Clear'}
              </Typography.TextS>
            </ClearButton>
          </ButtonsContainer>
        }
      </StatusDropdownContent>
    </StatusDropdown>
  );
});

export default FieldDropdown;

FieldDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  width: PropTypes.number,
  cWidth: PropTypes.number,
  component: PropTypes.object,
  isCustomCheckbox: PropTypes.bool,
  onAcceptClick: PropTypes.func,
  onChange: PropTypes.func,
};
