import styled, { createGlobalStyle } from 'styled-components/macro';

import { COLORS, FONTS, TRANSITIONS } from '../../../styles/Theme';

// images
import arrowLeft from './images/arrowLeft.svg';
import arrowRight from './images/arrowRight.svg';

export const DatepickerWrapper = styled.div`
  width: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  line-height: 149.18%;
  text-align: center;
  color: ${COLORS.LIGHT_BLUE};

  & input {
    height: 24px;
    border: none;
    width: 100%;
    font-style: italic;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    background: transparent;
    color: transparent;
    background: beige;
    padding: 0;
    outline: none;
  }
`;

export const CloseIcon = styled.div`
    position: absolute;
    right: -30px;
    width: 30px;
    top: 0;
    height: 30px;
    background: #f1f3f4;
    box-shadow: 0px 0px 25px rgb(55 114 182 / 20%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    svg path {
      fill: ${COLORS.RED_LIGHT};
    }

    svg {
      width: 12px;
      margin: 0 !important;
    }
`;

export const GlobalStyle = createGlobalStyle`
.react-datepicker {
  position: absolute;
  top: 50px;
  height: calc(100% - 50px - 16px);
  border-radius: 4px;
  border: none;
  background: transparent;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day {
  width: 25px;
  height: 22px;
  margin: 1px;
  font-family: ${FONTS.OPEN_SANS};
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 149.18%;
  text-align: center;
  color: ${COLORS.BLACK_LIGHT};
  border-radius: 2.97183px;
  outline: none;
  transition: ${TRANSITIONS.FAST};
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.react-datepicker__day--disabled {
  color: ${COLORS.LIGHT_BLUE} !important;
  cursor: default !important;
  &:hover {
    color: ${COLORS.GREY_LIGHTER2} !important;
    cursor: default !important;
  }
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: ${COLORS.LIGHT_BLUE} !important;
  cursor: default !important;

  &:hover {
    color: ${COLORS.LIGHT_BLUE} !important;
  }
}

.react-datepicker__month-dropdown {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.react-datepicker__month-container {
  left: 0;
  top: -10px;
  position: relative;

  & > div:last-child {
    display: flex;
    justify-content: space-around;
    height: 100%;
    flex-direction: column;
    top: 0;
    position: relative;
  }
}

.react-datepicker__month 
.react-datepicker__month-text, 
.react-datepicker__month 
.react-datepicker__quarter-text {
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  color: ${COLORS.LIGHT_BLUE};
}

.react-datepicker__month-wrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.react-datepicker__month-text:hover, 
.react-datepicker__quarter-text:hover, 
.react-datepicker__year-text:hover {
  border-radius: 0 !important;
  background: none !important;
  color: ${COLORS.BLUE};
  font-weight: bold;
  outline: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: ${COLORS.WHITE};
}

.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
  background: white;
  color: ${COLORS.LIGHT_BLUE};
  outline: none;
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  background: transparent !important;
  cursor: default !important;

  &:hover {
    color: transparent !important;
    background: transparent !important;
    cursor: default !important;
  }
}

.react-datepicker__header {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
  border-radius: 4px;
  top: -12px;
  height: 24px;
  z-index: 0;
  font-size: 13px;
  line-height: 149.18%;
  text-align: center;
  color: ${COLORS.LIGHT_BLUE};
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.react-datepicker__current-month {
  font-family: ${FONTS.OPEN_SANS};
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 149.18%;
  text-align: center;
  box-shadow: 0px 0.495305px 0.495305px rgb(0 14 51 / 5%);
  border-radius: 4px;
  text-align: center;
  background: white;
  padding: 2px 8px;
  min-width: 111px;
  width: auto;
  color: ${COLORS.LIGHT_BLUE};
  white-space: nowrap;
}

.react-datepicker__navigation {
  top: 26px;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  width: 14px;
  border: none;
  outline: none;
  top: -34px;
  box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
  background-color: ${COLORS.WHITE};
  border-radius: 4px;
  height: 24px;
}

.react-datepicker__navigation--next {
  right: 0;
  background: url(${arrowRight});
  background-position: center center;
  background-repeat: no-repeat;
}

.react-datepicker__navigation--previous {
  left: 0;
  background: url(${arrowLeft});
  background-position: center center;
  background-repeat: no-repeat;
}

.react-datepicker__day-names {
}

.react-datepicker__day-name {
  width: 25px;
  height: 22px;
  margin: 1px;
  font-family: ${FONTS.OPEN_SANS};
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 149.18%;
  text-align: center;
  color: ${COLORS.BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header react-datepicker-year-header {
  display: none;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  color: ${COLORS.BLUE};
  background: ${COLORS.WHITE};
  font-weight: bold;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  cursor: default;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: ${COLORS.BLUE} !important;
  color: ${COLORS.WHITE} !important;
}

.react-datepicker__day--keyboard-selected {
  background: ${COLORS.WHITE} !important;
  color: ${COLORS.BLACK_LIGHT} !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: ${COLORS.WHITE} !important;
  background: ${COLORS.BLUE} !important;
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  background: transparent !important;
  cursor: default !important;
  pointer-events: none !important;

  &:hover {
    color: transparent !important;
    background: transparent !important;
    cursor: default !important;
  }
}
`;
