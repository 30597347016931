import styled, { css } from 'styled-components/macro';
import sharedProps from '../../../styles/sharedProps';
import { COLORS, TRANSITIONS } from '../../../styles/Theme';

export const Button = styled.button`
font-style: normal;
font-weight: normal;
font-size: 15px;
color: ${COLORS.WHITE};
background: ${COLORS.BLUE};
height: 48px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
outline: none;
border: none;
cursor: pointer;
transition: ${TRANSITIONS.FAST};
position: relative;
z-index: 1;
overflow: hidden;
box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
border-radius: 8px;

${(props) => props.blocked && css`
  background: ${COLORS.LIGHT_BLUE2} !important;
  pointer-events: none;
  
  &::before,
  &::after {
    display: none !important;
  }
`}
${sharedProps};

&::before,
&::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: -1;
  transition: ${TRANSITIONS.FAST};
}

${(props) => !props.blocked && css`
  &:hover {
    color: ${COLORS.WHITE};
  }
`}
`;
