import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import SVG from '../SVG';
import Typography from '../Typography';
import {
  BurgerMenuContainer,
  CloseIconContainer,
  ButtonIcon,
  StyledWrapper,
  StyledWrapper2,
  FacilityContainer,
  SidebarItem,
} from './styles';

import { setFacilityName, setOrganizationId } from '../../../store/actions';

import { ADMIN_ROLE, FACILITY_NAME, ORGANIZATION_ID } from '../../../constants/constants';

const CREATE_USER_DATA = {
  id: -9999999,
  Name: 'Create User',
  parentId: -9999999,
  isCreate: true,
};

const Facility = (props) => {
  const {
    Name,
    name,
    quantity,
    level,
    firstLevel,
    id,
    isCreate,
    history,
    isVisible,
  } = props;
  const dispatch = useDispatch();
  const [isVisibl, setIsVisibl] = useState(false || isVisible);
  const organizationId = useSelector(({ data }) => data.organizationId);

  useEffect(() => {
    setIsVisibl(isVisible);
  }, [isVisible]);

  const hasList = (level) => {
    if (level.organizations) {
      return level.organizations.length;
    }

    if (level.buildings) {
      return level.buildings.length;
    }
  };

  const handleClickOrganization = ({ id, name }) => {
    if (id !== -9999999) {
      dispatch(setOrganizationId(id));
      dispatch(setFacilityName(name));
      localStorage.setItem(ORGANIZATION_ID, id);
      localStorage.setItem(FACILITY_NAME, name);
    }
  };

  const handleIsVisible = (facility, isCreate) => (e) => {
    e.stopPropagation();
    if (!facility.organizations && !facility.buildings) {
      handleClickOrganization(facility);
    }

    if (!isCreate) {
      if (hasList(facility)) {
        facility.onChangeVisible(!isVisibl);
        setIsVisibl(!isVisibl);
      }
    } else {
      history.push('/sign-up');
    }
  };

  return (<>
    <StyledWrapper2
      quantity={quantity + level}
      onClick={handleIsVisible(props, isCreate)}
      isVisible={isVisibl}
      level={level}
      firstLevel={firstLevel}
      orgId={id}
      currentOrganization={+organizationId}
      isHasList={hasList(props)}
      title={Name || name}
    >
      <Typography.TextS cssUnique={SidebarItem}>{Name || name}</Typography.TextS>
      {hasList(props) ? <SVG.ArrowMenu /> : null}
    </StyledWrapper2>
  </>
  );
};

Facility.propTypes = {
  firstLevel: PropTypes.bool,
  facilities: PropTypes.array,
  level: PropTypes.number,
};

const FacilityTree = (props) => {
  const [quantity, setQuantity] = useState(props.facilities.length);

  const hasList = (level) => {
    if (level.organizations) {
      return level.organizations.length;
    }

    if (level.buildings) {
      return level.buildings.length;
    }
  };

  const level = props.level || 0;
  const handleChangeVisible = (list) => () => {
    setQuantity(list.length);
  };

  return (
    <StyledWrapper
      isFirst={props.firstLevel}
      quantity={quantity}
      level={level}
    >
      {props.facilities.map((facility, i) => <FacilityContainer
        key={`level-${level}-${i}`}
      >
        <Facility
          index={i}
          level={level}
          firstLevel={props.firstLevel}
          quantity={quantity}
          onChangeVisible={handleChangeVisible(
            [
              ...(facility.organizations
                ? facility.organizations
                : []),
              ...(facility.buildings
                ? facility.buildings
                : []),
            ],
          )}
          history={props.history}
          {...facility}
        />
        {hasList(facility) ? <FacilityTree
          facilities={
            [
              ...(facility.organizations
                ? facility.organizations
                : []),
              ...(facility.buildings
                ? facility.buildings
                : []),
            ]}
          level={level + 1}
        /> : null}
      </FacilityContainer>)}
    </StyledWrapper>
  );
};

FacilityTree.propTypes = {
  firstLevel: PropTypes.bool,
  facilities: PropTypes.array,
  level: PropTypes.number,
};

const BurgerMenu = memo(({ isVisible, onChangeVisability, history }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const facilities = useSelector(({ data }) => data.facilities);
  const organizationId = useSelector(({ data }) => data.organizationId);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isEmpty(facilities)) {
      const isAdmin = user?.role === ADMIN_ROLE;

      if (isAdmin) {
        setData([...facilities, CREATE_USER_DATA]);
      } else {
        setData(facilities);
      }
    }
  }, [facilities]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const organizationIdStorage = localStorage.getItem(ORGANIZATION_ID);
      const facilityName = localStorage.getItem(FACILITY_NAME);

      setRecursivelyFalse(data);
      getSubMenuItemId(data, +organizationIdStorage);

      if (!organizationIdStorage) {
        const { id, name } = getSubMenuItem(data);
        dispatch(setOrganizationId(id));
        dispatch(setFacilityName(name));
        localStorage.setItem(ORGANIZATION_ID, id);
        localStorage.setItem(FACILITY_NAME, name);
      } else {
        dispatch(setOrganizationId(organizationIdStorage));
        dispatch(setFacilityName(facilityName));
      }
    }
  }, [data, organizationId]);

  const getSubMenuItem = (subMenuItems) => {
    if (subMenuItems) {
      for (let i = 0; i < subMenuItems.length; i++) {
        if (!subMenuItems[i]?.buildings && !subMenuItems[i]?.organizations) {
          return subMenuItems[i];
        }

        subMenuItems[i].isVisible = true;
        const found = getSubMenuItem([
          ...(subMenuItems[i]?.organizations
            ? subMenuItems[i]?.organizations
            : []),
          ...(subMenuItems[i]?.buildings
            ? subMenuItems[i]?.buildings
            : []),
        ]);
        if (found) return found;
      }
    }
  };

  const getSubMenuItemId = (subMenuItems, id) => {
    if (subMenuItems) {
      for (let i = 0; i < subMenuItems.length; i++) {
        if (subMenuItems[i]?.id === id) {
          return subMenuItems[i];
        }
        subMenuItems[i].isVisible = false;
        const found = getSubMenuItemId([
          ...(subMenuItems[i]?.organizations
            ? subMenuItems[i]?.organizations
            : []),
          ...(subMenuItems[i]?.buildings
            ? subMenuItems[i]?.buildings
            : []),
        ], id);
        if (found) {
          const obj = subMenuItems.find((i) => i.id === (found.orgId || found.parentId));
          obj.isVisible = true;
          return getSubMenuItemId(subMenuItems, found.orgId || found.parentId);
        }
      }
    }
  };

  const setRecursivelyFalse = (subMenuItems) => {
    if (subMenuItems) {
      for (let i = 0; i < subMenuItems.length; i++) {
        subMenuItems[i].isVisible = false;
        const found = setRecursivelyFalse([
          ...(subMenuItems[i]?.organizations
            ? subMenuItems[i]?.organizations
            : []),
          ...(subMenuItems[i]?.buildings
            ? subMenuItems[i]?.buildings
            : []),
        ]);
        if (found) return found;
      }
    }
  };

  const handleChangeVisability = () => {
    onChangeVisability(!isVisible);
  };

  return (
    <BurgerMenuContainer isVisible={isVisible}>
      <CloseIconContainer
        isVisible={isVisible}
        onClick={handleChangeVisability}
      >
        <ButtonIcon isVisible={isVisible} />
      </CloseIconContainer>
      <FacilityTree facilities={data} firstLevel history={history} />
    </BurgerMenuContainer>
  );
});

BurgerMenu.propTypes = {
  isVisible: PropTypes.bool,
  onChangeVisability: PropTypes.func,
};

export default BurgerMenu;
