import styled, { css } from 'styled-components/macro';
import { COLORS, TRANSITIONS } from '../../../styles/Theme';
import { less, lessH } from '../../../styles';

export const StatusDropdown = styled.div`
    position: relative;
    margin-right: 8px;
    z-index: 2;

    ${({ isVisible }) => isVisible && css`
        z-index: 4;
    `}

    ${({ isCustomCheckbox }) => isCustomCheckbox && css`
        margin-right: 0;
    `}
`;

export const StatusButtonContainer = styled.div`
    width: ${({ width }) => `${width}px`};
    height: 30px;
    background: ${COLORS.WHITE};
    box-shadow: 0px 0px 25px rgba(95, 116, 227, 0.14);
    border-radius: 8px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: ${TRANSITIONS.FAST};
    justify-content: space-between;
    
    svg {
        transition: ${TRANSITIONS.FAST};
        path {
            transition: ${TRANSITIONS.FAST};
            fill: ${COLORS.LIGHT_BLUE}!important;
        }
    }

    ${({ isVisible }) => isVisible && css`
        background: ${COLORS.WHITE};

        div {
            color: ${COLORS.LIGHT_BLUE};
        }

        svg {
            transform: rotateX(180deg);
            path {
                stroke: ${COLORS.WHITE};
            }
        }
    `}

    ${({ large, isValid }) => large && css`
        height: 42px;
        box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
        padding: 12px 16px;

        div {
            color: ${COLORS.LIGHT_BLUE};
            font-weight: normal;
            transition: ${TRANSITIONS.FAST};
        }

        ${!isValid
    ? css`
                div {
                    color: ${COLORS.LIGHT_BLUE};
                }
            `
    : css`
                div {
                    color: ${COLORS.RED_LIGHT};
                }
            `};
    `};

    ${({ large, isVisible }) => large && isVisible && css`
        & > div {
            font-size: 12px;
            line-height: 150.68%;
            transform: translateY(-180%);
        }
    `};

    ${({ large, count }) => (large && count > 0) && css`
        & > div {
            font-size: 12px;
            line-height: 150.68%;
            transform: translateY(-180%);
        }
    `}

    ${({ isCustomCheckbox, isVisible }) => isCustomCheckbox && css`
        background: none;
        padding: 10px 0;

        svg {
            transform: none;

            path {
                fill: ${isVisible && COLORS.WHITE} !important;
                stroke: unset !important;
            }

            rect {
                fill: ${isVisible && COLORS.LIGHT_BLUE};
            }
        }
    `}

    /* ${({ isValid }) => (!isValid
    ? css`
        div {
            color: ${COLORS.LIGHT_BLUE};
        }
      `
    : css`
        div {
            color: ${COLORS.RED_LIGHT};
        }
      `)}; */
`;

export const StatusTitle = css`
    font-weight: bold;
    color: ${COLORS.BLUE};
`;

export const StatusDropdownContent = styled.div`
    width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
    opacity: 0;
    pointer-events: none;
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
    box-shadow: 0px 0px 25px rgba(55, 114, 182, 0.2);
    border-radius: 8px;
    transition: ${TRANSITIONS.FAST};
    padding: 6px 0 0 0;
    position: absolute;
    top: 34px;
    z-index: 123123;

    ${({ large }) => large && css`
        top: 50px;
    `}

    ${({ isVisible }) => isVisible && css`
        opacity: 1;
        pointer-events: auto;
    `}

    ${({ isCustomCheckbox, isVisible }) => isCustomCheckbox && css`
        right: calc(50% - 18px);

        & div:not(:last-child) {
            color: ${isCustomCheckbox && isVisible && COLORS.BLACK};
        }
    `}
`;

export const StatusDropdownInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 420px;
    height: auto;
    overflow: auto;
    margin-bottom: 6px;

    ${({ large }) => large && css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 12px 6px;
    `};

    &::-webkit-scrollbar {
        width: 5px;
        background: ${COLORS.LIGHTER_BLUE};
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${COLORS.LIGHT_BLUE};
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${COLORS.BLUE};
        cursor: pointer;
    }

    ${less.laptop(css`
        max-height: calc(200px - 46px);
    `)}

    ${lessH.tablet(css`
        max-height: calc(200px - 46px);
    `)}
`;

export const StatusCheckboxItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 10px;
    cursor: pointer;
    white-space: nowrap;
    transition: ${TRANSITIONS.FAST};

    ${({ large }) => large && css`
        min-width: 50%;
    `};

    &:hover {
        background: white;
        border-radius: 8px;
    }
`;

export const StatusCheckboxItemTitle = css`
    font-size: 12px;
    line-height: 150.68%;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const AcceptButton = styled.div`
    width: 50%;
    padding: 7px;
    background: ${COLORS.LIGHT_BLUE2};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-bottom-left-radius: 8px;
    cursor: pointer;
    transition: ${TRANSITIONS.FAST};

    ${({ isDisabled }) => !isDisabled && css`
        background: ${COLORS.LIGHT_BLUE};
    `}
`;

export const AcceptButtonTitle = css`
    font-weight: bold;
    color: ${COLORS.WHITE};
`;

export const ClearButton = styled.div`
    width: 50%;
    padding: 7px;
    border:  1px solid ${COLORS.LIGHT_BLUE};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
`;

export const ClearButtonTitle = css`
    font-weight: bold;
    color: ${COLORS.LIGHT_BLUE};
`;

export const SelectedTitle = css`
    position: absolute;
    top: 46px;
    font-size: 13px;
    color: ${COLORS.BLACK_LIGHT} !important;
`;
