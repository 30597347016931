import { css } from 'styled-components/macro';
import { FONTS } from './Theme';

const sharedProps = ({
  mt,
  mb,
  px,
  color,
  center,
  fontStyle,
  cssUnique,
  backgroundColor,
}) => css`
  margin-top: ${mt};
  color: ${color};
  font-style: ${fontStyle};
  background-color: ${backgroundColor};
  ${cssUnique};

  ${px && `padding-left: ${px}; padding-right: ${px};`}
  ${mb && `margin-bottom: ${mb};`}
  ${center && 'text-align: center;'}

  ${(props) => props.openSans
    && css`
      font-family: ${FONTS.OPEN_SANS};
    `};
`;

export default sharedProps;
