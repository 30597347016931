import React, { memo, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '../Typography';

import {
  InfoContainer,
  FacilityTitle,
  FacilityInfoContainer,
  FacilityItemContainer,
  FacilityItemTitle,
  FacilityItemValue,
} from './styles';

import getUtilitiesDetails from '../../../services/getUtilitiesDetails';
import formatToStringGap from '../../../utils/formatToStringGap';

const MyUtilityBlock = memo(() => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);
  const [utilityData, setUtilityData] = useState({});

  useEffect(() => {
    if (organizationId) {
      dispatch(getUtilitiesDetails())
        .then((data) => {
          setUtilityData(data);
        });
    }
  }, [organizationId]);

  return (
    <InfoContainer>
      <Typography.TextS cssUnique={FacilityTitle}>
        {!!Object.keys(utilityData).length && `${utilityData?.info?.name} has ${utilityData?.info?.accountsCount} utility accounts`}
      </Typography.TextS>
      {
        !!Object.keys(utilityData).length
        && <FacilityInfoContainer>
          <FacilityItemContainer>
            <Typography.TextS
              cssUnique={FacilityItemTitle}
            >
              Total Utility Spend:
            </Typography.TextS>

            <Typography.TextS
              cssUnique={FacilityItemValue}
            >
              {!!Object.keys(utilityData).length && `${utilityData?.totalUtilitySpend?.unit}${formatToStringGap(utilityData?.totalUtilitySpend?.value)}`}
            </Typography.TextS>
          </FacilityItemContainer>
          {
            utilityData?.providers?.map((provider, i) => (
              <FacilityItemContainer key={i}>
                <Typography.TextS
                  cssUnique={FacilityItemTitle}
                >
                  {provider.title}
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {provider.providers[0]}
                </Typography.TextS>
              </FacilityItemContainer>
            ))
          }
          <FacilityItemContainer>
            <Typography.TextS
              cssUnique={FacilityItemTitle}
            >
              Latest Common Bill
            </Typography.TextS>

            <Typography.TextS
              cssUnique={FacilityItemValue}
            >
              {!!Object.keys(utilityData).length && `${utilityData?.latestCommonBill?.month} ${utilityData?.latestCommonBill?.day} '${utilityData?.latestCommonBill?.year}`}
            </Typography.TextS>
          </FacilityItemContainer>
        </FacilityInfoContainer>
      }
    </InfoContainer>
  );
});

export default withRouter(MyUtilityBlock);
