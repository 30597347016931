import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  HeaderContainer,
  HeaderLeftColumn,
  LogoContainer,
  HeaderRightColumn,
  PersonName,
} from './styles';

import AccountInfoPopup from '../AccountInfoPopup/AccountInfoPopup.jsx';
import getBuildingsSearch from '../../../services/getBuildingsSearch';
import AsyncSelect from '../AsyncSelect/index.jsx';

// images
import logo1 from './images/logo1.svg';
import logo2 from './images/logo2.svg';
import avatar from './images/avatar.svg';
import { setFacilityName, setOrganizationId } from '../../../store/actions';
import { FACILITY_NAME, ORGANIZATION_ID } from '../../../constants/constants';

const BLOCKED_ROUTES = ['/login', '/sign-up', '/recovery', '/templates/docx'];

const Header = memo(({ location, history }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const isLoading = useSelector(({ data }) => data.isLoading);

  const [searchValue, setSeachValue] = useState('');
  const [isVisibleAccountPopup, setIsVisibleAccountPopup] = useState(false);

  const loadOptions = async (inputValue, callback) => {
    if (!inputValue) return;
    const data = await getBuildingsSearch(inputValue);
    const formattedData = data.map((i) => ({ ...i, value: i.name, label: i.name }));
    callback(formattedData);
  };

  const handleChangeAsyncSelect = ({ label, name, id }) => {
    setSeachValue(label);
    dispatch(setOrganizationId(id));
    dispatch(setFacilityName(name));
    localStorage.setItem(ORGANIZATION_ID, id);
    localStorage.setItem(FACILITY_NAME, name);
  };

  const handleClickAccount = () => {
    setIsVisibleAccountPopup(!isVisibleAccountPopup);
  };

  const handleLogoClick = () => {
    history.push('/');
  };

  if (BLOCKED_ROUTES.some((i) => i === location.pathname)) return null;

  if (isLoading) return <div />;

  return (
    <HeaderContainer>
      <HeaderLeftColumn>
        <LogoContainer onClick={handleLogoClick}>
          <img src={logo1} alt="logo" />
          <img src={logo2} alt="logo" />
        </LogoContainer>
        {
          <AsyncSelect
            onLoadOptions={loadOptions}
            value={searchValue}
            onChange={handleChangeAsyncSelect}
          />
        }
      </HeaderLeftColumn>
      <HeaderRightColumn onClick={handleClickAccount}>
        <img src={avatar} alt="avatar" />
        <PersonName>{user?.name}</PersonName>
      </HeaderRightColumn>
      <AccountInfoPopup
        isVisible={isVisibleAccountPopup}
        onChangeVisability={handleClickAccount}
      />
    </HeaderContainer>
  );
});

Header.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Header);
