import { ORGANIZATION_ID, TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';
import { setAuthorizedAction } from '../store/actions';
import logoutSync from './logoutSync';

export default function logout() {
  return (dispatch) => {
    localStorage.removeItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME);
    localStorage.removeItem(ORGANIZATION_ID);

    dispatch(setAuthorizedAction(false));
    logoutSync();
  };
}
