import React from 'react';
import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import { COLORS } from '../../../../styles/Theme';

const options = {
  indexAxis: 'y',
  elements: {
    point: {
      radius: 2,
      backgroundColor: COLORS.BLACK_LIGHT,
    },
  },
  // responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
      color: 'black',
    },
    title: {
      display: true,
      text: 'Savings',
      align: 'center',
      position: 'bottom',
      font: {
        weight: 'normal',
        size: 10,
      },
    },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(44, 66, 92, 0.77)',
      backdropFilter: 'blur(22px)',
      cornerRadius: 8,
      titleAlign: 'center',
      titleFont: {
        weight: 'normal',
      },
      displayColors: false,
    },
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: false,
        padding: 0,
        callback(value) {
          return `$${value}`;
        },
      },
      grid: {
        display: true,
        drawTicks: false,
        drawBorder: false,
      },
    },
    y: {
      ticks: {
        color: COLORS.BLACK_LIGHT,
        crossAlign: 'far',
        padding: 12,
      },
      grid: {
        display: false,
        drawBorder: false,
        drawTicks: false,
      },
    },
  },
};

const BarLine = ({ data, isTitle }) => {
  const newOptions = !isTitle
    ? ({
      ...options,
      plugins: {
        ...options.plugins,
        title: {
          display: false,
        },
      },
    })
    : options;
  return <Bar data={data} options={newOptions} />;
};

BarLine.propTypes = {
  data: PropTypes.object,
};

export default BarLine;
