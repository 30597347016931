import styled from 'styled-components/macro';
import { COLORS, FONTS } from '../../styles/Theme';
import sharedProps from '../../styles/sharedProps';

// ======TITLES======

const StyledH1 = styled.h1`
  font-style: normal;
  font-weight: 800;
  font-size: 50.0954px;
  line-height: 68px;
  font-family: ${FONTS.OPEN_SANS};
  ${sharedProps};
`;

// H3
const StyledH3 = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 49px;
  font-family: ${FONTS.OPEN_SANS};
  ${sharedProps};
`;

const StyledH4 = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  font-family: ${FONTS.OPEN_SANS};
  ${sharedProps};
`;

// H5
const StyledH5 = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  font-family: ${FONTS.OPEN_SANS};
  ${sharedProps};
`;

const StyledH6 = styled.h6`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-family: ${FONTS.OPEN_SANS};
  ${sharedProps};
`;

// ======TEXTS======

const StyledTextM = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.3;
  ${sharedProps};
`;

const StyledTextS = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height:  149.18%;
  ${sharedProps};
`;

// ======LINKS======

const StyledA = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-decoration-line: underline;
  color: ${COLORS.LIGHT_BLUE};
  ${sharedProps};

  a {
    color: ${COLORS.LIGHT_BLUE};
  }
`;

export default {
  H1: StyledH1,
  H3: StyledH3,
  H4: StyledH4,
  H5: StyledH5,
  H6: StyledH6,
  TextM: StyledTextM,
  TextS: StyledTextS,
  A: StyledA,
};
