import React from 'react';
import styled, { css } from 'styled-components/macro';
import { withRouter } from 'react-router-dom';

// images
import { useSelector } from 'react-redux';
import logo from './images/logo.svg';
import logoHuge from './images/logoHuge.png';
import logo404 from './images/404.png';
import Oops from './images/Oops.svg';

import Typography from '../../components/Typography';
import { COLORS } from '../../../styles/Theme';
import { Button } from '../../components/Buttons/styles';
import Footer from '../../components/Footer/Footer.jsx';
import SVG from '../../components/SVG';
import { less } from '../../../styles';

const NotFoundPage = ({ history }) => {
  const isAuthorized = useSelector(({ auth }) => auth.authorized);

  const handleBackClick = () => {
    if (isAuthorized) {
      history.goBack();
    } else {
      history.push('/login');
    }
  };

  return (
    <Container>
      <ImgBlueDiv />
      <ImgLogoHugeContainer>
        <ImgLogoHuge src={logoHuge} alt="logoHuge" />
      </ImgLogoHugeContainer>
      <ImgLogo404Container>
        <ImgLogo404 src={logo404} alt="logo404" />
      </ImgLogo404Container>
      <Header>
        <img src={logo} alt="logo" />
      </Header>
      <MainContent>
        <img src={Oops} alt="" />
        <Typography.H5 cssUnique={SomethingTitle}>
          Something went wrong
        </Typography.H5>

        <ButtonBack
          onClick={handleBackClick}
        >
          <SVG.ArrowWhiteBack />
          Back
        </ButtonBack>
      </MainContent>
      <FooterContainer>
        <Footer dashboard />
      </FooterContainer>
    </Container>
  );
};

export default withRouter(NotFoundPage);

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: linear-gradient(107.41deg, #F8F8FB 49.07%, #FFFCF3 109.41%);
    overflow: hidden;
`;

const Header = styled.div`
    padding: 24px;
`;

const ImgLogoHugeContainer = styled.div`
    position: relative;
    right: 0;
    top: 10%;

    &:before {
        content: '';
        position: absolute;
        background: rgba(247, 219, 133, 0.14);
        filter: blur(165.3px);
        width: 500px;
        height: 500px;
        right: 0;
        top: -200px;
    }

    &:after {
        content: '';
        position: absolute;
        background: rgba(99, 159, 227, 0.3);
        filter: blur(140.593px);
        width: 500px;
        height: 500px;
        right: 0;
        top: 300px;
    }

    ${less.tabletL(css`
      top: 20%;
    `)}
`;

const ImgBlueDiv = styled.div`
    position: relative;

    &:before {
        content: '';
        position: absolute;
        background: rgba(214, 243, 254, 0.19);
        filter: blur(154.439px);
        width: 500px;
        height: 500px;
        left: 20%;
    }
`;

const ImgLogo404Container = styled.div`
    position: relative;
    right: 5%;
    top: 58%;
`;

const ImgLogoHuge = styled.img`
    position: absolute;
    right: 0;
    height: 75vh;

    ${less.tabletL(css`
      height: 55vh;
    `)}
`;

const ImgLogo404 = styled.img`
    position: absolute;
    right: 15%;
    top: 60%;
    height: 30vh;

    ${less.tabletL(css`
      height: 20vh;
    `)}
`;

const MainContent = styled.div`
  position: absolute;
  left: 20%;
  top: 40%;

  ${less.laptopS(css`
    left: 10%;
  `)}

  ${less.tabletL(css`
    top: 20%;
  `)}
`;

const SomethingTitle = css`
    color: ${COLORS.GREY_LIGHTER2};
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 40px;
`;

const ButtonBack = styled(Button)`
  width: 209px;
  font-weight: bold;

  svg path {
    stroke: ${COLORS.WHITE};
  }

  svg {
    margin-right: 8px;
  }
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 16px;
  width: 100%;
`;
