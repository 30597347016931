import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { COLORS, TRANSITIONS } from '../../../styles/Theme';
import { less, lessH } from '../../../styles';

import FacilityInfoBlock from '../../components/FacilityInfoBlock/FacilityInfoBlock.jsx';
import LatestCompletedBlock from '../../components/LatestCompletedBlock/LatestCompletedBlock.jsx';
import TotalEnergyCostBlock from '../../components/TotalEnergyCostBlock/TotalEnergyConstBlock.jsx';
import BenchmarkInfoBlock from '../../components/BenchmarkInfoBlock/BenchmarkInfoBlock.jsx';
import LatestAddedBlock from '../../components/LatestAddedBlock/LatestAddedBlock.jsx';
import ActionsBlock from '../../components/ActionsBlock/ActionsBlock.jsx';
import Footer from '../../components/Footer/Footer.jsx';

const MainPage = ({ isVisible }) => (
  <MainPageContainer isVisible={isVisible}>
    <MainContentGrid isVisible={isVisible}>
      <FacilityInfoBlock />
      <Block2>
        <LatestCompletedBlock />
      </Block2>
      <Block3>
        <TotalEnergyCostBlock />
      </Block3>
      <Block4>
        <BenchmarkInfoBlock />
      </Block4>
      <Block5>
        <LatestAddedBlock />
      </Block5>
      <Block6>
        <ActionsBlock />
      </Block6>
    </MainContentGrid>
    <Footer />
  </MainPageContainer>
);

MainPage.propTypes = {
  isVisible: PropTypes.bool,
};

export default MainPage;

const MainPageContainer = styled.div`
    width: 100%;
    transition: ${TRANSITIONS.FAST};
    padding: 17px 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 38px;
    height: calc(100vh - 72px - 38px);

    ${less.laptopS(css`
      ${({ isVisible }) => isVisible && css`
        height: 100%;
      `}
    `)}

    ${less.tabletL(css`
      height: 100%;
    `)}

    ${lessH.tablet(css`
      height: 100%;
    `)}
`;

const MainContentGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    grid-template-rows: 94px 1fr 1fr;

    grid-auto-rows: 100px;
    grid-template-areas: "facilityInfo facilityInfo facilityInfo"
                         "latestCompleted totalEnergy benchmarkInfo"
                         "latestAdded Actions benchmarkInfo";
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    height: 100%;
    justify-content: space-between;
    margin-bottom: 8px;

    ${less.laptopS(css`
        ${({ isVisible }) => isVisible && css`
            grid-template-columns: repeat(2, minmax(200px, 1fr));
            grid-template-rows: 94px 319px 319px auto;
            grid-template-areas:
                "facilityInfo facilityInfo"
                "latestCompleted totalEnergy"
                "latestAdded Actions"
                "benchmarkInfo benchmarkInfo";
        `}
    `)}

    ${less.tabletL(css`
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        grid-template-rows: 94px 319px 319px auto;
        grid-template-areas:
            "facilityInfo facilityInfo"
            "latestCompleted totalEnergy"
            "latestAdded Actions"
            "benchmarkInfo benchmarkInfo";
        `)
}
      @media(max-width: 1024px) and (min-height: 1024px) {
        height: calc(100vh - 178px);
      }
`;

const Block2 = styled.div`
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 16px;
    background: ${COLORS.WHITE};
    grid-area: latestCompleted;
`;
const Block3 = styled.div`
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 16px;
    background: ${COLORS.WHITE};
    grid-area: totalEnergy;
`;
const Block4 = styled.div`
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 16px;
    background: ${COLORS.WHITE};
    grid-area: benchmarkInfo;
`;
const Block5 = styled.div`
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 16px;
    background: ${COLORS.WHITE};
    grid-area: latestAdded;
`;

const Block6 = styled.div`
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 16px;
    background: ${COLORS.WHITE};
    grid-area: Actions;
`;
