import styled, { createGlobalStyle, css } from 'styled-components';
import { COLORS, FONTS } from '../../../styles/Theme';

export const EditorGlobalStyles = createGlobalStyle`
    .ck-editor__main > div {
        padding: 16px !important;
    }

    .ck-button {
        background-color: ${COLORS.LIGHTORANGE} !important;
        margin: 0 !important;
    }

    .ck-toolbar__separator {
        background: transparent !important;
    }
    
    .ck-on.ck-button {
        color: ${COLORS.BLACK} !important;
    }

    .ck.ck-button:not(.ck-disabled):hover
    ,a.ck.ck-button:not(.ck-disabled):hover {
        background: ${COLORS.WHITE};

        svg {
            fill: ${COLORS.BLACK_LIGHT};
        }
    }

    .ck.ck-toolbar {
        background: ${COLORS.LIGHT_BLUE};
    }

    .ck.ck-editor {
        box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
        border-radius: 8px;
        border: 1px solid ${COLORS.LIGHT_BLUE};
    }

    .ck-file-dialog-button + button {
        display: none !important;
    }

    .ck.ck-reset_all, .ck.ck-reset_all * {
        /* color: ${COLORS.WHITE}; */

        &:hover {
            .ck.ck-button:not(.ck-disabled) svg, a.ck.ck-button:not(.ck-disabled) svg {
                color: ${COLORS.BLACK_LIGHT};
            }
        }
    }

    .ck-media__wrapper[data-oembed-url*="youtube.com"] .ck-media__placeholder__icon * {
        display: none !important;
    }

    .ck-rounded-corners
    .ck.ck-editor__main>.ck-editor__editable,
    .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners,
    .ck-rounded-corners
    .ck.ck-editor__top
    .ck-sticky-panel
    .ck-toolbar,
    .ck.ck-editor__top
    .ck-sticky-panel.ck-toolbar.ck-rounded-corners {
        border-radius: 8px;
        border: none !important;
        box-shadow: none !important;
        margin: 2px;
        color: ${COLORS.BLACK} !important;
    }

    .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
        z-index: 1;
    }

    .ck.ck-button.ck-disabled, a.ck.ck-button.ck-disabled {
        display: none;
    }

    .ck-content .image>figcaption {
        caption-side: top;
    }

    .ck.ck-content {
        font-feature-settings: 'pnum' on, 'lnum' on;
        padding: 1px;
        color: ${COLORS.WHITE} !important;

        figure {
            width: 100%;
        } 

        blockquote {
            border-left: 5px solid ${COLORS.LIGHT_BLUE};
        }

        h2 {
            font-family: ${FONTS.OPEN_SANS};
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 40px;
            text-transform: capitalize;
            color: ${COLORS.BLACK_LIGHT};
        }

        h3 {
            font-family: ${FONTS.OPEN_SANS};
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            text-transform: capitalize;
            color: ${COLORS.BLACK_LIGHT};
        }

        h4 {
            font-family: ${FONTS.OPEN_SANS};
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: ${COLORS.BLACK_LIGHT};
        }

        p {
            font-family: ${FONTS.OPEN_SANS};
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 26px;
            color: ${COLORS.BLACK};
        }

        i {
            font-style: italic;
            font-family: ${FONTS.OPEN_SANS};
            font-weight: normal;
            font-size: 15px;
            line-height: 26px;
        }

        b, strong {
            font-weight: bold;
            color: ${COLORS.BLACK};
        }

        a {
            color: ${COLORS.LIGHT_BLUE};
            text-decoration: none;
        }

        td span {
            white-space: break-spaces;
            word-break: break-all;
        }

        ul {
            list-style-type: disc;
            list-style-position: inside;
        }

        ol {
            list-style-type: decimal;
            list-style-position: inside;
        }

        ul ul, ol ul {
            list-style-position: inside;
            margin-left: 34px;

            li {
                margin-left: 0;
                margin-block-start: 8px;
                margin-block-end: 8px;

                &::before {
                    border: 1px solid ${COLORS.RED};
                    background: transparent;
                }
            }
        }
        ol ol, ul ol {
            list-style-type: lower-latin;
            list-style-position: inside;

            li {
                &::before {
                    display: none;
                }
            }
        }


        ol li, ul li {
            list-style: inherit;
            display: list-item;
            margin-left: 22px;
            margin-block-start: 8px;
            margin-block-end: 8px;
            margin-inline-end: 0px;
            font-family: ${FONTS.LATO};
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 26px;
            color: ${COLORS.BLACK};
        }

        ul li {
            position: relative;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: -22px;
                top: 10px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: ${COLORS.RED};
            }
        }
    }
`;

export const NotesAddContainer = styled.div`
    display: inline-flex;
    cursor: pointer;
    
    svg {
        margin-right: 8px;
    }
`;

export const NotesTitle = css`
    color: ${COLORS.LIGHT_BLUE};
`;

export const NotesTitleEditor = css`
    color: ${COLORS.LIGHT_BLUE};
    margin-left: 16px;
`;
