import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';

import { LABELS_MONTH, BORDER_COLOR, BACKGROUND_COLOR } from '../../../constants/ui.js';
import getBenchmakrEpaRatingData from '../../../services/getBenchmakrEpaRatingData.js';
import getBenchmarkAnnualData from '../../../services/getBenchmarkAnnualData.js';
import getBenchmarkEUI from '../../../services/getBenchmarkEUI.js';
import getBenchmarkInsightData from '../../../services/getBenchmarkInsightData.js';
import { COLORS } from '../../../styles/Theme.js';
import formatToString from '../../../utils/formatToString.js';
import formatToStringGap from '../../../utils/formatToStringGap.js';

import BarVerticalLine from '../charts/BarVerticalLine/BarVerticalLine.jsx';
import LineHorizontal2Chart from '../charts/LineHorizontal2Chart/LineHorizontal2Chart.jsx';
import SelectField from '../SelectField/SelectField.jsx';
import Typography from '../Typography.js';

const SELECT_DATA = [
  {
    id: 0,
    route: '/insight',
    value: 'Facility insight',
    label: 'Facility insight',
  },
  {
    id: 1,
    route: '/annual-utility-spend',
    value: 'Annual utility spent',
    label: 'Annual utility spent',
  },
  {
    id: 2,
    route: '/annual-utility-spend',
    value: 'EUI - Energy usage index',
    label: 'EUI - Energy usage index',
  },
  {
    id: 3,
    route: '/epa-rating',
    value: 'EPA rating',
    label: 'EPA rating',
  },
];

const CHART_DATA = [
  {
    selectId: 0,
  },
  {
    selectId: 1,
    labels: LABELS_MONTH,
  },
  {
    selectId: 2,
    labels: LABELS_MONTH,
  },
  {
    selectId: 3,
    labels: LABELS_MONTH,
  },
];

const getInitialChartData = (i, year) => ({
  type: 'line',
  borderColor: BORDER_COLOR[i],
  backgroundColor: BACKGROUND_COLOR[i],
  fill: true,
  label: year,
  borderWidth: 2,
});

const BenchmarkInfoBlock = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);
  const [selectData, setSelectData] = useState(SELECT_DATA[0]);
  const [chartData, setChartData] = useState(CHART_DATA[0]);
  const [insightData, setInsightData] = useState({});
  const [epaAverage, setEpaAverage] = useState(0);
  const [currentlyNumber, setCurrentlyNumber] = useState('0');

  useEffect(() => {
    if (organizationId) {
      handleChangeSelectId(selectData);
      // dispatch(getBenchmarkInsightData(selectData.route))
      //   .then((data) => {
      //     debugger;
      //     setInsightData(data);
      //   });
    }
  }, [organizationId]);

  const getUniqueYear = (data) => [...new Set(data.map((item) => item.year))]
    .sort((a, b) => (a > b ? 1 : -1));

  const handleChangeSelectId = (value) => {
    const dataChart = CHART_DATA.find((i) => i.selectId === value.id);
    setSelectData(value);
    switch (value.id) {
      case 0: {
        dispatch(getBenchmarkInsightData(value.id))
          .then((data) => {
            setInsightData(data);
          });
        break;
      }
      case 1: {
        dispatch(getBenchmarkAnnualData(value.id))
          .then((data) => {
            const charts = getUniqueYear(data).map((year, i) => {
              const newData = data.filter((item) => item.year === year);
              const dataWithIndex = [];
              newData.forEach((i) => {
                dataWithIndex[+i.month - 1] = +i.cost.value;
              });
              return ({
                ...getInitialChartData(i, year),
                data: dataWithIndex,
              });
            });

            setChartData({ ...dataChart, datasets: charts });
          });
        break;
      }
      case 2: {
        dispatch(getBenchmarkEUI(value.id))
          .then((data) => {
            const charts = getUniqueYear(data).map((year, i) => {
              const newData = data.filter((item) => item.year === year);
              const dataWithIndex = [];
              newData.forEach((i) => {
                dataWithIndex[+i.month - 1] = +i.energyUsageIndex.value;
              });
              return ({
                ...getInitialChartData(i, year),
                data: dataWithIndex,
              });
            });

            setCurrentlyNumber(Math.trunc(charts[charts.length - 1]
              .data[charts[charts.length - 1].data.length - 1]));
            setChartData({ ...dataChart, datasets: charts });
          });
        break;
      }
      case 3: {
        dispatch(getBenchmakrEpaRatingData(value.id))
          .then((data) => {
            const charts = getUniqueYear(data.epaRatings).map((year, i) => {
              const newData = data.epaRatings.filter((item) => item.year === year);
              const dataWithIndex = [];
              newData.forEach((i) => {
                dataWithIndex[+i.month - 1] = i.rating;
              });
              return ({
                ...getInitialChartData(i, year),
                data: dataWithIndex,
              });
            });

            setEpaAverage(data.facilityAverage);
            setChartData({ ...dataChart, datasets: charts });
          });
        break;
      }
      default: {
        break;
      }
    }
  };

  const getCorrectChart = (id) => {
    switch (id) {
      case 0: {
        return <FacilityInsightContainer>
          {
            <Typography.TextS cssUnique={FacilityInsightTitle1}>
              {
                !!Object.keys(insightData).length
                  ? `${insightData.city}, ${insightData?.state} - ${formatToString(insightData.area?.value)} ${insightData.area?.unit}`
                  : ''
              }
            </Typography.TextS>
          }
          <Typography.TextS cssUnique={FacilityInsightTitle2}>
            Utility Summary
          </Typography.TextS>
          <FacilityInfoContainer>
            <FacilityItemContainer>
              <Typography.TextS
                cssUnique={FacilityItemTitle}
              >
                Energy Usage Index:
              </Typography.TextS>
              {
                <Typography.TextS
                  cssUnique={FacilityItemValue}>
                  {!!Object.keys(insightData).length
                    ? `${insightData.energyUsageIndex?.value || '$'} ${insightData.energyUsageIndex?.unit || '0'}`
                    : ''}
                </Typography.TextS>
              }
            </FacilityItemContainer>

            <FacilityItemContainer>
              <Typography.TextS
                cssUnique={FacilityItemTitle}
              >
                Annual Utility Spend:
              </Typography.TextS>

              <Typography.TextS
                cssUnique={FacilityItemValue}
              >
                {!!Object.keys(insightData).length
                  ? `${insightData.annualUtilitySpend?.unit || '$'} ${formatToStringGap(insightData.annualUtilitySpend?.value) || '0'}`
                  : ''}
              </Typography.TextS>
            </FacilityItemContainer>
            <FacilityItemContainer>
              <Typography.TextS
                cssUnique={FacilityItemTitle}
              >
                Latest Utility Bill:
              </Typography.TextS>

              <Typography.TextS
                cssUnique={FacilityItemValue}
              >
                {!!Object.keys(insightData).length
                  ? `${formatToStringGap(insightData.latestUtilityBill?.year)} ‘${insightData.latestUtilityBill?.month}`
                  : ''}
              </Typography.TextS>
            </FacilityItemContainer>
          </FacilityInfoContainer>
        </FacilityInsightContainer>;
      }
      case 1: {
        return <ChartAnnualContainer>
          <Typography.TextS cssUnique={AnnualTitle}>
            Rolling 12 Month Utility Spend
          </Typography.TextS>
          <ChartInnerAnnualContainer>
            <LineHorizontal2Chart data={chartData} />
          </ChartInnerAnnualContainer>
        </ChartAnnualContainer>;
      }
      case 2: {
        return <ChartAnnualContainer>
          <Typography.TextS cssUnique={AnnualTitle}>
            {`Currently ${currentlyNumber || '0'} kBty / ft2 / yr`}
          </Typography.TextS>
          <ChartInnerContainer>
            <LineHorizontal2Chart leftTitle="EPA Rating" data={chartData} />
          </ChartInnerContainer>
        </ChartAnnualContainer>;
      }
      case 3: {
        return <ChartAnnualContainer>
          <Typography.TextS cssUnique={AnnualTitle}>
            {`Facility Average: ${epaAverage || '0'}`}
          </Typography.TextS>
          <ChartInnerContainer>
            <LineHorizontal2Chart leftTitle="kBtu / ft2 / yr" data={chartData} />
          </ChartInnerContainer>
        </ChartAnnualContainer>;
      }
      default: {
        return <BarVerticalLine data={chartData} />;
      }
    }
  };

  return (
    <>
      <BlockHeader>
        <Typography.TextS>
          Benchmark Information
        </Typography.TextS>
      </BlockHeader>
      <BlockContainer>
        <SelectField
          value={selectData}
          options={SELECT_DATA}
          onChange={handleChangeSelectId}
        />
        {getCorrectChart(selectData.id)}
      </BlockContainer>
    </>
  );
};

export default BenchmarkInfoBlock;

const BlockHeader = styled.div`
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
    border-radius: 8px;
    padding: 6px;
    display: flex;
    justify-content: center;
    background:${COLORS.WHITE};

    div {
        color: ${COLORS.BLUE};
        font-weight: bold;
    }
`;

const BlockContainer = styled.div`
    padding: 11px 24px;
`;

const ChartAnnualContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const ChartInnerAnnualContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ChartInnerContainer = styled.div``;

const FacilityInsightContainer = styled.div`
  width: 100%;
`;

const FacilityInsightTitle1 = css`
  color: ${COLORS.BLACK_LIGHT};
  text-align: center;
  padding: 24px;
`;

const FacilityInsightTitle2 = css`
  color: ${COLORS.BLUE};
  margin-bottom: 25px;
  text-align: center;
`;

const FacilityInfoContainer = styled.div`
  padding: 0 19px;
`;

const FacilityItemContainer = styled.div`
    display: flex;
    margin-bottom: 8px;
    justify-content: space-around;
    
    &:last-child {
        margin-bottom: 0;
    }
`;

const FacilityItemTitle = css`
    margin-right: 3px;
    font-weight: bold;
    max-width: 132px;
    width: 100%;
    color: ${COLORS.BLUE};
`;

const FacilityItemValue = css`
  color: ${COLORS.BLACK_LIGHT};
  max-width: 104px;
  width: 100%;
`;

const AnnualTitle = css`
  color: ${COLORS.BLUE};
  font-weight: bold;
  text-align: center;
  padding: 24px 0;
`;
