import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
} from 'recharts';
import styled, { css } from 'styled-components/macro';
import { COLORS } from '../../../../styles/Theme';
import Typography from '../../Typography';
import formatToStringGap from '../../../../utils/formatToStringGap';

const STATUS_COLORS = {
  1: COLORS.LIGHTER_BLUE,
  6: COLORS.GREEN_DARK,
  3: COLORS.BLUE,
  15: COLORS.PURPLE,
  4: COLORS.YELLOW,
  5: COLORS.RED_LIGHT,
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipCustom>
        <Typography.TextS cssUnique={StatusTitle}>
          Status {payload[0].payload.percentage}%
        </Typography.TextS>

        <TooltipItem>
          {payload[0].payload.statusDescription}:
          <Typography.TextS cssUnique={DataItemTitle}>
            {payload[0].payload.tasksCount}
          </Typography.TextS>
        </TooltipItem>

        <TooltipItem>
          Latest activity:
          <Typography.TextS cssUnique={DataItemTitle}>
            {format(new Date(payload[0]?.payload?.latestActivity), 'MM/dd/yyyy')}
          </Typography.TextS>
        </TooltipItem>

        <Typography.TextS cssUnique={SavingTitle}>
          <b>${formatToStringGap(payload[0]?.payload?.totalSavings)}</b> in Est Savings Opportunity.
        </Typography.TextS>
      </TooltipCustom>
    );
  }

  return null;
};

const DoughnutChart = ({ data }) => (
  <PieChart width={84} height={84}>
    <Pie
      stroke="none"
      dataKey="value"
      data={data}
      innerRadius={33}
      outerRadius={42}
      paddingAngle={0}
    >
      {data.map((item, index) => (
        <Cell key={`cell-${index}`} fill={STATUS_COLORS[item.statusId]} />
      ))}
    </Pie>
    <Tooltip
      left={84}
      top={-4}
      position={{ x: 86, y: 4 }}
      content={<CustomTooltip />}
    />
  </PieChart>
);

export default DoughnutChart;

DoughnutChart.propTypes = {
  data: PropTypes.array.isRequired,
};

const TooltipCustom = styled.div`
  background: rgba(44, 66, 92, 0.77);
  backdrop-filter: blur(22px);
  border-radius: 8px;
  min-width: 231px;
  width: 100%;
  padding: 10px;
  color: ${COLORS.WHITE};
  white-space: nowrap;
`;

const StatusTitle = css`
  font-size: 12px;
  line-height: 136.68%;
  font-weight: bold;
  color: ${COLORS.WHITE};
  margin-right: 4px;
`;

const TooltipItem = styled.div`
  font-size: 12px;
  line-height: 136.68%;
  display: flex;
  align-items: center;
`;

const DataItemTitle = css`
  font-weight: bold;
  margin-left: 4px;
`;

const SavingTitle = css`
  b {
    font-weight: bold;
  }
`;
