import axios from 'axios';
import ROUTES from '../api/routes';
import { TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';

const refreshAuthLogic = (failedRequest) => {
  const {
    userId,
    refreshToken,
  } = JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME)) || {};

  const data = JSON.stringify({
    userId,
    refreshToken,
  });

  return axios.post(
    ROUTES.REFRESH,
    data,
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    },
  ).then(({ data }) => {
    localStorage.setItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME, JSON.stringify(data));
    // eslint-disable-next-line no-param-reassign
    failedRequest.response.config.headers.Authorization = data.accessToken;
    return Promise.resolve();
  })
    .then(location.reload);
};

export default refreshAuthLogic;
