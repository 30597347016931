import React, {
  memo,
  useEffect,
  useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash';

import DoughnutChart from '../charts/DoughnutChart/DoughnutChart.jsx';
import Typography from '../Typography';
import getBuildingsDetails from '../../../services/getBuildingsDetails';

import {
  InfoContainer,
  FacilityTitle,
  FacilityInfoContainer,
  FacilityItemContainer,
  FacilityItemTitle,
  FacilityItemValue,
  FacilityItemMember,
  DoughnutChartContainer,
  LeftColumn,
  DoughnutStatisticBlock,
  DoughnutStatusItem,
  DoughnutChartInnerContainer,
} from './styles';
import Statuses from '../Statuses.js';

const TASK_MANAGER_PAGE = '/project-center/task-manager';

const FacilityInfoBlock = memo(({ location, chartData = [] }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);
  const [info, setInfo] = useState({});
  const [currentTime, setCurrentTime] = useState('');
  const [totalItems, setTotalItems] = useState('0');

  useEffect(() => {
    if (organizationId) {
      dispatch(getBuildingsDetails())
        .then((data) => {
          setInfo(data);
        });
    }
  }, [organizationId]);

  useEffect(() => {
    !isEmpty(chartData) ? setTotalItems(chartData.reduce((acc, res) => acc + res.tasksCount, 0)) : setTotalItems('0');
  }, [chartData, organizationId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!info?.timezone) {
        setCurrentTime('-');
        return;
      }
      const withUtc = moment().utcOffset(info?.timezone);
      // const isMinHour = moment(withUtc, info?.timezone).isDST() && info?.dst;
      const date = moment(withUtc).add(1, 'hours').format('ddd, D MMM YYYY, h:mm a UTC');
      setCurrentTime(date);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [info]);

  return (
    <InfoContainer>
      <LeftColumn>
        <Typography.H5 cssUnique={FacilityTitle}>
          {info?.name}
        </Typography.H5>
        <FacilityInfoContainer>
          <FacilityItemContainer>
            <Typography.TextS
              cssUnique={FacilityItemTitle}
            >
              Member Of:
            </Typography.TextS>

            <a href="http://bob.americanenergyassets.com/group/143.html" target="_blank">
              <Typography.TextS
                cssUnique={FacilityItemMember}>
                {!isEmpty(info) ? info?.orgName : '—'}
              </Typography.TextS>
            </a>
          </FacilityItemContainer>

          <FacilityItemContainer>
            <Typography.TextS
              cssUnique={FacilityItemTitle}
            >
              Facility Location:
            </Typography.TextS>

            <Typography.TextS
              cssUnique={FacilityItemValue}
            >
              {!!info?.city ? `${info?.city}, ${info?.state}` : '—'}
            </Typography.TextS>
          </FacilityItemContainer>
          <FacilityItemContainer>
            <Typography.TextS
              cssUnique={FacilityItemTitle}
            >
              Local time:
            </Typography.TextS>

            <Typography.TextS
              cssUnique={FacilityItemValue}
            >
              {!isEmpty(info) ? currentTime : '—'}
            </Typography.TextS>
          </FacilityItemContainer>
        </FacilityInfoContainer>
      </LeftColumn>
      {
        TASK_MANAGER_PAGE === location.pathname
        && <DoughnutChartContainer>
          <DoughnutChartInnerContainer {...+totalItems && { items: totalItems }}>
            {!isEmpty(chartData) && <DoughnutChart data={chartData} />}
          </DoughnutChartInnerContainer>
          <DoughnutStatisticBlock>
            <DoughnutStatusItem>
              <Statuses.NewStatus />
              {!isEmpty(chartData)
                && chartData.find(({ statusId }) => statusId === 1)?.tasksCount || 0}
            </DoughnutStatusItem>
            <DoughnutStatusItem>
              <Statuses.PendingStatus />
              {!isEmpty(chartData)
                && chartData.find(({ statusId }) => statusId === 3)?.tasksCount || 0}
            </DoughnutStatusItem>
            <DoughnutStatusItem>
              <Statuses.PauseStatus />
              {!isEmpty(chartData)
                && chartData.find(({ statusId }) => statusId === 4)?.tasksCount || 0}
            </DoughnutStatusItem>
            <DoughnutStatusItem>
              <Statuses.ContinueStatus />
              {!isEmpty(chartData)
                && chartData.find(({ statusId }) => statusId === 15)?.tasksCount || 0}
            </DoughnutStatusItem>
            <DoughnutStatusItem>
              <Statuses.CancelStatus />
              {!isEmpty(chartData)
                && chartData.find(({ statusId }) => statusId === 5)?.tasksCount || 0}
            </DoughnutStatusItem>
            <DoughnutStatusItem>
              <Statuses.CompletedStatus />
              {!isEmpty(chartData)
                && chartData.find(({ statusId }) => statusId === 6)?.tasksCount || 0}
            </DoughnutStatusItem>
          </DoughnutStatisticBlock>
        </DoughnutChartContainer>
      }
    </InfoContainer>
  );
});

FacilityInfoBlock.propTypes = {
  location: PropTypes.object.isRequired,
  chartData: PropTypes.array,
};

export default withRouter(FacilityInfoBlock);
