import axios from 'axios';
import ROUTES from '../api/routes';
import { TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';

export default function signup({
  username, customer, email, password,
}) {
  const { accessToken } = JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME)) || {};

  const data = JSON.stringify({
    username,
    customer,
    email,
    password,
  });

  return axios.post(
    ROUTES.SIGNUP,
    data,
    {
      headers: {
        accept: 'application/json',
        Authorization: accessToken,
        'content-type': 'application/json',
      },
    },
  )
    .then(({ data }) => data)
    .then((err) => err);
}
