import axios from 'axios';

export default function logoutSync() {
  const data = {
    'fwData[redirectSource]': 'toolbar',
    'fwData[redirectUrl]': '',
  };

  const formData = new FormData();

  for (const key in data) {
    formData.append(key, data[key]);
  }

  return axios.post(
    'https://bob.americanenergyassets.com/element/76/logout/json?',
    formData,
    {
      withCredentials: true,
    },
  );
}
