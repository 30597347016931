import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import { COLORS, FONTS } from '../../../../styles/Theme';
import { CALENDAR_DATA } from '../../../../constants/ui';

const options = {
  elements: {
    line: {
      tension: 0.6,
    },
    point: {
      radius: 2,
    },
  },
  // responsive: true,
  plugins: {
    datalabels: {
      display: false,
      color: 'black',
    },
    legend: {
      position: 'bottom',
      labels: {
        color: COLORS.BLACK_LIGHT,
        boxWidth: 19,
        boxHeight: 11,
        font: FONTS.OPEN_SANS,
        padding: 24,
      },
      title: {
        padding: {
          top: 50,
        },
      },
    },
    title: {
      display: false,
      align: 'center',
      position: 'left',
      padding: {
        bottom: 8,
      },
      color: COLORS.GREY_LIGHTER2,
      font: {
        weight: 'normal',
        size: 10,
      },
    },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(44, 66, 92, 0.77)',
      backdropFilter: 'blur(22px)',
      cornerRadius: 8,
      titleAlign: 'center',
      titleFont: {
        weight: 'normal',
      },
      displayColors: false,
      callbacks: {
        label(context) {
          let label = context.dataset.label || '';

          if (label) {
            label = `${CALENDAR_DATA[context.parsed.x]} ${label}`;
          }
          return label;
        },
        title(context) {
          let title = context[0].formattedValue || '';

          if (title) {
            title = `$${title}`;
          }

          return title;
        },
      },
    },
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: false,
        color: COLORS.GREY_LIGHTER2,
        padding: 8,
      },
      grid: {
        display: false,
        drawBorder: false,
        drawTicks: false,
      },
    },
    y: {
      ticks: {
        color: COLORS.GREY_LIGHTER2,
        beginAtZero: false,
        padding: 8,
        callback(value) {
          return `$${value}`;
        },
      },
      grid: {
        drawBorder: false,
        drawTicks: false,
      },
    },
  },
};

const LineChart = memo(({ data: dataChart, leftTitle }) => {
  const data = (canvas) => ({
    ...dataChart,
    datasets: dataChart.datasets?.map((i) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, i.borderColor);
      gradient.addColorStop(0.5, i.backgroundColor);
      return ({
        ...i,
        backgroundColor: gradient,
      });
    }),
  });

  debugger;

  const optionsWithTitle = leftTitle
    ? ({
      ...options,
      plugins: {
        ...options.plugins,
        title: {
          ...options.plugins.title,
          display: true,
          text: leftTitle,
        },
      },
    })
    : options;

  return (
    <Line data={data} options={optionsWithTitle} />
  );
});

LineChart.propTypes = {
  data: PropTypes.object.isRequired,
  leftTitle: PropTypes.string,
};

export default LineChart;
