import styled, { css } from 'styled-components/macro';
import { less } from '../../../styles';
import { COLORS } from '../../../styles/Theme';

export const InfoContainer = styled.div`
    padding: 15px 24px;
    grid-area: facilityInfo;
    background: ${COLORS.WHITE};
    box-shadow: 0px 0px 10px rgb(55 114 182 / 13%);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    canvas {
        position: relative;
        width: 100% !important;
        height: 100% !important;
        max-width: 83px;
        max-height: 83px;
    }

    ${less.tabletL(css`
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    `)}
`;

export const DoughnutChartInnerContainer = styled.div`
  position: relative; 
  max-width: 84px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    width: 38px;
    text-align: center;

    ${({ items }) => items && css`
        content: ${`'${items} Items'`} !important;
    `}
  }
`;

export const FacilityTitle = css`
    font-weight: bold;
    max-width: 241px;
    margin-right: 24px;
`;

export const FacilityInfoContainer = styled.div``;

export const FacilityItemContainer = styled.div`
    display: flex;
    margin-bottom: 5px;
    white-space: nowrap;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        text-decoration: none;
    }
`;

export const FacilityItemTitle = css`
    margin-right: 8px;
    font-weight: bold;
`;

export const FacilityItemValue = css``;
export const FacilityItemMember = css`
    color: ${COLORS.LIGHT_BLUE};
`;

export const DoughnutChartContainer = styled.div`
    max-width: 382px;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const LeftColumn = styled.div`
    display: flex;
    align-items: center;

    ${less.tabletL(css`
        width: -webkit-fill-available;
        justify-content: space-between;
    `)}
`;

export const DoughnutStatisticBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    height: 50px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-content: flex-end;
    position: relative;
`;

export const DoughnutStatusItem = styled.div`
    width: 89px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 150.68%;
    color: ${COLORS.BLACK_LIGHT};

    & > div:first-child {
        margin-right: 8px;
    }
`;

export const DownloadButton = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: ${COLORS.LIGHTER_BLUE};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    cursor: pointer;
`;
