import React, { memo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { COLORS } from '../../../styles/Theme.js';
import BarVerticalLine from '../charts/BarVerticalLine/BarVerticalLine.jsx';
import LineHorizontal2Chart from '../charts/LineHorizontal2Chart/LineHorizontal2Chart.jsx';
import SelectField from '../SelectField/SelectField.jsx';
import Typography from '../Typography.js';
import BarLine from '../charts/BarLine/BarLine.jsx';

import getTaskManagerSavingsOverall from '../../../services/getTaskManagerSavingsOverall';
import getTaskManagerSavingsTotalizer from '../../../services/getTaskManagerSavingsTotalizer';
import getTaskManagerSavingsHistory from '../../../services/getTaskManagerSavingsHistory';

const SELECT_DATA = [
  {
    id: '0',
    value: 'Year-to-previous year Bill Analysis',
    label: 'Year-to-previous year Bill Analysis',
  },
  {
    id: '1',
    value: 'Year-to-baseline year Bill Analysis',
    label: 'Year-to-baseline year Bill Analysis',
  },
  {
    id: '2',
    value: 'Savings totalizer',
    label: 'Savings totalizer',
  },
  {
    id: '3',
    value: 'Savings history',
    label: 'Savings history',
  },
];

const CHART_DATA = [
  {
    selectId: '0',
    labels: ['Last Year Bill', 'Weather Effect', 'Rates Effect', 'Conservation Effect', 'This Year Bill'],
    datasets: [
      {
        type: 'line',
        label: '$',
        borderColor: COLORS.BLACK_LIGHT,
        fill: false,
        stepped: 'after',
        borderWidth: 1,
        minBarLength: 7,
      },
      {
        label: '$',
        borderColor: COLORS.BLACK_LIGHT,
        borderWidth: 1,
        minBarLength: 7,
        datalabels: {
          display: true,
          align: ['start', 'end', 'end', 'end', 'start'],
          anchor: ['end', 'end', 'end', 'end', 'end'],
        },
      },
    ],
  },
  {
    selectId: '1',
    labels: ['Last Year Bill', 'Weather Effect', 'Rates Effect', 'Conservation Effect', 'This Year Bill'],
    datasets: [
      {
        type: 'line',
        label: '$',
        borderColor: COLORS.BLACK_LIGHT,
        fill: false,
        stepped: 'after',
        borderWidth: 1,
        minBarLength: 7,
      },
      {
        label: '$',
        borderColor: COLORS.BLACK_LIGHT,
        borderWidth: 1,
        minBarLength: 7,
        datalabels: {
          display: true,
          align: ['start', 'end', 'end', 'end', 'start'],
          anchor: ['end', 'end', 'end', 'end', 'end'],
        },
      },
    ],
  },
  {
    selectId: '2',
    datasets: [
      {
        label: '$',
        borderColor: COLORS.BLACK_LIGHT,
        borderWidth: 1,
        backgroundColor: [
          COLORS.GREEN_DARK,
          COLORS.GREEN_DARK,
          COLORS.RED_LIGHT,
          COLORS.GREEN_DARK,
        ],
      },
    ],
  },
  {
    selectId: 3,
    labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    datasets: [
      {
        type: 'line',
        label: '2019',
        borderColor: COLORS.RED_LIGHT,
        backgroundColor: 'rgba(238,100,133, 0.1)',
        fill: true,
        borderWidth: 2,
      },
      {
        type: 'line',
        label: '2020',
        borderColor: COLORS.ORANGE_DARK,
        backgroundColor: 'rgba(255, 168, 65, 0.1)',
        fill: true,
        borderWidth: 2,
      },
      {
        type: 'line',
        label: '2021',
        borderColor: COLORS.GREEN_DARK,
        backgroundColor: 'rgba(69, 170, 176, 0.1)',
        fill: true,
        borderWidth: 2,
      },
    ],
  },
];

const TotalEnergyCostBlock = memo(() => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);

  const [selectData, setSelectData] = useState(SELECT_DATA[0]);
  const [chartData, setChartData] = useState({});

  const [charts, setCharts] = useState([]);

  useEffect(() => {
    if (organizationId) {
      Promise.all([
        dispatch(getTaskManagerSavingsOverall('previousYear')),
        dispatch(getTaskManagerSavingsOverall('baselineYear')),
        dispatch(getTaskManagerSavingsTotalizer()),
        dispatch(getTaskManagerSavingsHistory()),
      ])
        .then(([overallPrevious, overallBaseline, totalizer, history]) => {
          let charts = [];
          if (overallPrevious) {
            const overallPreviousData = getBarLineData(overallPrevious, '0');
            charts = [...charts, overallPreviousData];
          }
          if (overallBaseline) {
            const overallBaselineData = getBarLineData(overallBaseline, '1');
            charts = [...charts, overallBaselineData];
          }
          if (totalizer) {
            const totalizerData = getBarLineData(totalizer, '2');
            charts = [...charts, totalizerData];
          }
          if (history) {
            const historyData = getLineData(history, '3');
            charts = [...charts, historyData];
          }
          setCharts(charts);
        });
    }
  }, [organizationId]);

  useEffect(() => {
    const findEl = charts.find((i) => i.selectId === selectData.id);
    setChartData(findEl);
  }, [charts]);

  const handleChangeSelectId = (value) => {
    const data = charts.find((i) => i.selectId === value.id);

    setSelectData(value);
    setChartData(data);
  };

  const getBarLineData = (data, selectId) => {
    if (isEmpty(data)) return [];

    const barLineChart = selectId !== '2'
      ? [
        data.comparedCost,
        data.weatherEffect,
        data.ratesEffect,
        data.conservationEffect,
        data.thisYearBill,
      ] : [
        ...(!isEmpty(data.years) && data.years.map(({ savings }) => savings)),
        data.total,
      ];
    const edgeMeanPer = ((barLineChart[0] + barLineChart[4]) / 2) / 15;
    const barLineChartData = {
      ...CHART_DATA[+selectId],
      ...data.months && { title: `${data.months} months ending ${data.endingData}` },
      ...selectId === '2' && {
        labels: [...data.years.map(({ year }) => `Year ${year}`), 'Contract to date'],
      },
      selectId,
      edgeMeanPer,
      datasets: CHART_DATA[+selectId].datasets.map((i) => (i.type === 'line'
        ? ({
          ...i,
          data: [
            barLineChart[0],
            barLineChart[1] > 0
              ? edgeMeanPer + barLineChart[0] + barLineChart[1]
              : -edgeMeanPer + barLineChart[0] + barLineChart[1],
            barLineChart[2] > 0 && barLineChart[1] > 0
              ? edgeMeanPer + edgeMeanPer + barLineChart[0] + barLineChart[1] + barLineChart[2]
              : barLineChart[2] > 0 && barLineChart[1] < 0
                ? edgeMeanPer + -edgeMeanPer + barLineChart[0] + barLineChart[1] + barLineChart[2]
                : barLineChart[2] < 0 && barLineChart[1] > 0
                  ? -edgeMeanPer + edgeMeanPer + barLineChart[0] + barLineChart[1] + barLineChart[2]
                  : -edgeMeanPer
                  - edgeMeanPer + barLineChart[0] + barLineChart[1] + barLineChart[2],
            barLineChart[3] > 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 1 >  >  >
              ? edgeMeanPer + edgeMeanPer + edgeMeanPer
              + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
              : barLineChart[3] > 0 && barLineChart[2] > 0 && barLineChart[1] < 0 // 2
                ? edgeMeanPer + edgeMeanPer - edgeMeanPer
                + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                : barLineChart[3] > 0 && barLineChart[2] < 0 && barLineChart[1] < 0 // 3
                  ? edgeMeanPer - edgeMeanPer - edgeMeanPer
                  + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                  : barLineChart[3] > 0 && barLineChart[2] < 0 && barLineChart[1] > 0 // 4
                    ? edgeMeanPer - edgeMeanPer + edgeMeanPer
                    + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                    : barLineChart[3] < 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 5
                      ? -edgeMeanPer + edgeMeanPer + edgeMeanPer
                      + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                      : barLineChart[3] < 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 6
                        ? -edgeMeanPer - edgeMeanPer + edgeMeanPer
                        + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                        : barLineChart[3] < 0 && barLineChart[2] < 0 && barLineChart[1] < 0 // 7
                          ? -edgeMeanPer - edgeMeanPer - edgeMeanPer
                          + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                          : -edgeMeanPer + edgeMeanPer - edgeMeanPer
                          + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3],
            barLineChart[3] > 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 1 >  >  >
              ? edgeMeanPer + edgeMeanPer + edgeMeanPer
              + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
              : barLineChart[3] > 0 && barLineChart[2] > 0 && barLineChart[1] < 0 // 2
                ? edgeMeanPer + edgeMeanPer - edgeMeanPer
                + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                : barLineChart[3] > 0 && barLineChart[2] < 0 && barLineChart[1] < 0 // 3
                  ? edgeMeanPer - edgeMeanPer - edgeMeanPer
                  + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                  : barLineChart[3] > 0 && barLineChart[2] < 0 && barLineChart[1] > 0 // 4
                    ? edgeMeanPer - edgeMeanPer + edgeMeanPer
                    + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                    : barLineChart[3] < 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 5
                      ? -edgeMeanPer + edgeMeanPer + edgeMeanPer
                      + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                      : barLineChart[3] < 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 6
                        ? -edgeMeanPer - edgeMeanPer + edgeMeanPer
                        + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                        : barLineChart[3] < 0 && barLineChart[2] < 0 && barLineChart[1] < 0 // 7
                          ? -edgeMeanPer - edgeMeanPer - edgeMeanPer
                          + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                          : -edgeMeanPer + edgeMeanPer - edgeMeanPer
                          + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3],
          ],
        })
        : selectId === '2'
          ? ({
            ...i,
            data: [
              ...barLineChart,
            ],
          }) : ({
            ...i,
            data: [
              barLineChart[0],
              barLineChart[1] > 0
                ? barLineChart[1] + edgeMeanPer
                : barLineChart[1] - edgeMeanPer,
              barLineChart[2] > 0
                ? barLineChart[2] + edgeMeanPer
                : barLineChart[2] - edgeMeanPer,
              barLineChart[3] > 0
                ? barLineChart[3] + edgeMeanPer
                : barLineChart[3] - edgeMeanPer,
              barLineChart[3] > 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 1 >  >  >
                ? edgeMeanPer + edgeMeanPer + edgeMeanPer
                + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                : barLineChart[3] > 0 && barLineChart[2] > 0 && barLineChart[1] < 0 // 2
                  ? edgeMeanPer + edgeMeanPer - edgeMeanPer
                  + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                  : barLineChart[3] > 0 && barLineChart[2] < 0 && barLineChart[1] < 0 // 3
                    ? edgeMeanPer - edgeMeanPer - edgeMeanPer
                    + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                    : barLineChart[3] > 0 && barLineChart[2] < 0 && barLineChart[1] > 0 // 4
                      ? edgeMeanPer - edgeMeanPer + edgeMeanPer
                      + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                      : barLineChart[3] < 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 5
                        ? -edgeMeanPer + edgeMeanPer + edgeMeanPer
                        + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                        : barLineChart[3] < 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 6
                          ? -edgeMeanPer - edgeMeanPer + edgeMeanPer
                          + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                          : barLineChart[3] < 0 && barLineChart[2] < 0 && barLineChart[1] < 0 // 7
                            ? -edgeMeanPer - edgeMeanPer - edgeMeanPer
                            + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                            : -edgeMeanPer + edgeMeanPer - edgeMeanPer
                            + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3],
            ],
            isTwoMore: barLineChart[3] < 0 && barLineChart[2] < 0 && barLineChart[1] < 0,
            isLastBarMore: (barLineChart[3] > 0 && barLineChart[2] > 0 && barLineChart[1] > 0
              ? edgeMeanPer + edgeMeanPer + edgeMeanPer
              + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
              : barLineChart[3] > 0 && barLineChart[2] > 0 && barLineChart[1] < 0 // 2
                ? edgeMeanPer + edgeMeanPer - edgeMeanPer
                + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                : barLineChart[3] > 0 && barLineChart[2] < 0 && barLineChart[1] < 0 // 3
                  ? edgeMeanPer - edgeMeanPer - edgeMeanPer
                  + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                  : barLineChart[3] > 0 && barLineChart[2] < 0 && barLineChart[1] > 0 // 4
                    ? edgeMeanPer - edgeMeanPer + edgeMeanPer
                    + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                    : barLineChart[3] < 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 5
                      ? -edgeMeanPer + edgeMeanPer + edgeMeanPer
                      + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                      : barLineChart[3] < 0 && barLineChart[2] > 0 && barLineChart[1] > 0 // 6
                        ? -edgeMeanPer - edgeMeanPer + edgeMeanPer
                        + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                        : barLineChart[3] < 0 && barLineChart[2] < 0 && barLineChart[1] < 0 // 7
                          ? -edgeMeanPer - edgeMeanPer - edgeMeanPer
                          + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3]
                          : -edgeMeanPer + edgeMeanPer - edgeMeanPer
                          + barLineChart[0] + barLineChart[1] + barLineChart[2] + barLineChart[3])
              > barLineChart[4],
          })
      )),
    };
    return barLineChartData;
  };

  const getLineData = (data, selectId) => {
    const charts = !isEmpty(getUniqueYear(data)) && getUniqueYear(data).map((year, i) => {
      const newData = data.filter((item) => item.year === year);
      const dataWithIndex = [];
      newData.forEach((i) => {
        dataWithIndex[+i.month - 1] = +i.savings;
      });
      return ({
        ...CHART_DATA[+selectId][i],
        data: dataWithIndex,
      });
    });

    return {
      ...CHART_DATA[+selectId],
      selectId,
      datasets: CHART_DATA[+selectId].datasets.map((i, index) => ({
        ...i,
        ...charts[index],
      })),
    };
  };

  const getUniqueYear = (data) => [...new Set(data.map((item) => item.year))]
    .sort((a, b) => (a > b ? 1 : -1));

  return (
    <>
      <BlockHeader>
        <Typography.TextS>
          Total Energy Cost
        </Typography.TextS>
      </BlockHeader>
      <BlockContainer>
        <SelectField
          value={selectData}
          options={SELECT_DATA}
          onChange={handleChangeSelectId}
        />
        <Typography.TextS cssUnique={TitleChart}>
          {
            selectData.id === '3'
              ? 'Conservation Effect ($/mo)'
              : chartData?.title
                ? `${chartData?.title}`
                : ''
          }
        </Typography.TextS>
        {
          selectData.id === '3'
            ? <LineHorizontal2Chart data={chartData} />
            : selectData.id === '2'
              ? <BarLine data={chartData} isTitle={false} />
              : <BarVerticalLine data={chartData} />
        }
      </BlockContainer>
    </>
  );
});

export default TotalEnergyCostBlock;

const BlockHeader = styled.div`
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
    border-radius: 8px;
    padding: 6px;
    display: flex;
    justify-content: center;
    background:${COLORS.WHITE};

    div {
        color: ${COLORS.BLUE};
        font-weight: bold;
    }
`;

const BlockContainer = styled.div`
    padding: 12px 24px;

    canvas {
      max-height: 200px !important;
    }
`;

const TitleChart = css`
    color: ${COLORS.BLUE};
    font-weight: bold;
    margin-top: 13px;
    text-align: center;
`;
