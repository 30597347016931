import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'chart.js';
import chartPluginDatalabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';

import { COLORS } from '../../../../styles/Theme';
import formatToString from '../../../../utils/formatToString';

Chart.register([chartPluginDatalabels, annotationPlugin]);

const options = {
  indexAxis: 'y',
  elements: {
    point: {
      radius: [0, 2, 2, 2, 0],
      backgroundColor: COLORS.BLACK_LIGHT,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      display: false,
      color: 'black',
      backgroundColor: [
        'transparent',
        COLORS.WHITE,
        COLORS.WHITE,
        COLORS.WHITE,
        'transparent',
      ],
    },
    annotation: {
      annotations: {
        line1: {
          type: 'line',
          xMin: 0,
          xMax: 0,
          borderColor: 'black',
          borderWidth: 1,
        },
      },
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: true,
      backgroundColor: 'rgba(44, 66, 92, 0.77)',
      backdropFilter: 'blur(22px)',
      cornerRadius: 8,
      titleAlign: 'center',
      position: 'nearest',
      titleFont: {
        weight: 'normal',
      },
      filter(_) {
        const { type } = _.dataset;
        return type !== 'line';
      },
      displayColors: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      ticks: {
        color: COLORS.BLACK_LIGHT,
        crossAlign: 'far',
        padding: 0,
      },
      grid: {
        display: false,
        borderColor: 'transparent',
      },
    },
  },
};

const BarVerticalLine = ({ data }) => {
  const chartCanvasRef = useRef(null);
  const [isRebuildingCanvas, setIsRebuildingCanvas] = useState(false);
  useEffect(() => {
    if (!data?.datasets?.[0]?.data) return;
    const { edgeMeanPer } = data;
    const chartInstance = new Chart(chartCanvasRef.current, {
      type: 'bar',
      data: { ...data },
      options: {
        ...options,
        elements: {
          ...options.elements,
          bar: {
            ...options.elements.bar,
            backgroundColor: [
              COLORS.ORANGE_DARK,
              data?.datasets?.[1]?.data[1] > 0 ? COLORS.RED_LIGHT : COLORS.GREEN_DARK,
              data?.datasets?.[1]?.data[2] > 0 ? COLORS.RED_LIGHT : COLORS.GREEN_DARK,
              data?.datasets?.[1]?.data[3] > 0 ? COLORS.RED_LIGHT : COLORS.GREEN_DARK,
              COLORS.ORANGE_DARK,
            ],
          },
        },
        plugins: {
          ...options.plugins,
          datalabels: {
            ...options.plugins.datalabels,
            formatter(context) {
              if (data?.datasets?.[1]?.data[0] === context) {
                return formatToString(context);
              }
              if (data?.datasets?.[1]?.data[4] === context) {
                return formatToString(data?.datasets?.[1]?.isTwoMore
                  ? context + (3 * edgeMeanPer)
                  : data?.datasets?.[1]?.isLastBarMore
                    ? context - edgeMeanPer : context + edgeMeanPer);
              }
              return formatToString(context > 0 ? context - edgeMeanPer : context + edgeMeanPer);
            },
          },
          tooltip: {
            ...options.plugins.tooltip,
            callbacks: {
              ...options.plugins.tooltip.callbacks,
              label(context) {
                if (context?.dataset?.data[0] === context.raw) {
                  return formatToString(context.raw);
                }

                if (context?.dataset?.data[4] === context.raw) {
                  return formatToString(context?.dataset?.isTwoMore
                    ? context.raw + (3 * edgeMeanPer)
                    : context?.dataset?.isLastBarMore
                      ? context.raw - edgeMeanPer : context.raw + edgeMeanPer);
                }
                return formatToString(context.raw > 0
                  ? context.raw - edgeMeanPer
                  : context.raw + edgeMeanPer);
              },
            },
          },
        },
      },
    });
    setIsRebuildingCanvas(true);

    return () => { chartInstance.destroy(); };
  }, [data]);

  useEffect(() => {
    if (isRebuildingCanvas) {
      setIsRebuildingCanvas(false);
    }
  }, [isRebuildingCanvas]);

  return <>
    <canvas ref={chartCanvasRef} width="400" height="200" />
  </>;
};

BarVerticalLine.propTypes = {
  data: PropTypes.object,
};

export default BarVerticalLine;
