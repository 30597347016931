import styled from 'styled-components/macro';
import React, { memo, useState } from 'react';
import { withRouter } from 'react-router-dom';

// images
import background from './images/back.jpeg';
import RegisterForm from '../../components/RegisterForm/RegisterForm.jsx';
import LogoAuthentication from '../../components/LogoAuthentication/LogoAuthentication.jsx';
import SuccessPopup from '../../components/SuccessPopup/SuccessPopup.jsx';

const RegisterPage = memo(({ history }) => {
  const [isSuccessPopup, setIsSuccessPopup] = useState(false);
  const [emailText, setEmailText] = useState('');

  const CONTENT = {
    title: 'Registration is completed successfully!',
    content: `Email with the temporary password was sent to ${emailText}`,
    isRegister: true,
  };

  const handleReturnClick = () => {
    history.goBack();
  };

  const handleChangeSuccessStatus = (email) => () => {
    setIsSuccessPopup(true);
    setEmailText(email);
  };

  return (
    <Container>
      <LogoAuthentication />
      {
        isSuccessPopup
          ? <SuccessPopup
            title={CONTENT.title}
            content={CONTENT.content}
            isRegister={CONTENT.isRegister}
            onReturn={handleReturnClick}
            registration
          />
          : <RegisterForm
            onChangeSuccessStatus={handleChangeSuccessStatus}
          />
      }
    </Container>
  );
});

export default withRouter(RegisterPage);

const Container = styled.div`
    background: url(${background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;
