import React, {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  useTable, usePagination, useRowSelect, useExpanded,
} from 'react-table';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Images
import spin from './images/spin.gif';

import {
  TableContainer,
  Table,
  THEAD,
  TBODY,
  TH,
  TR,
  TD,
  SortButtons,
  PlusButton,
  RowSubComponent,
  SubComponentTitle,
  SubComponentMainData,
  FirstContainer,
  MiddleContainer,
  LastContainer,
  FacilityItemContainer,
  FirstItemTitle,
  MiddleItemTitle,
  LastItemTitle,
  FacilityItemValue,
  ButtonEdit,
  TableFooter,
  StatusHoverContainer,
  TitleDescription,
} from './styles';

import Typography from '../Typography';
import SVG from '../SVG';
import FieldDropdown from '../FieldDropdown/FieldDropdown.jsx';
import getTaskById from '../../../services/getTaskById';

const PlusButtonComponent = () => (
  <PlusButton>
    <SVG.PlusButton />
  </PlusButton>
);

const TaskManagerTable = memo(({
  columnCheckboxes,
  getTasks,
  initPageSize,
  tableColumns: columns,
  tableRows: data,
  onSort,
  sortBy,
  history,
  location,
  isVisible,
  meta,
  isLoading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    state: { pageIndex },
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: initPageSize },
    },
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const [currentPage, setCurrentPage] = useState(page);
  const [isStatusVisible, setIsStatusVisible] = useState(false);

  const handleChangeStatusVisibility = () => {
    setIsStatusVisible(!isStatusVisible);
  };

  const handleSortClick = (value) => () => {
    onSort(value);
  };

  const handleEditClick = (value) => () => {
    const { pathname } = location;
    history.push(`${pathname}/edit-task/${value}`);
  };

  const RowSubAsyncComponent = ({ row }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
      getTaskById(row.values.col1)
        .then((data) => {
          setData(data);
          setLoading(false);
        });
    }, []);

    if (loading) {
      return (
        <TD>
          <img src={spin} />
          <Typography.TextS>
            Loading...
          </Typography.TextS>
        </TD>
      );
    }

    return (
      <TD isExpanded>
        <RowSubComponent>
          <Typography.TextS cssUnique={SubComponentTitle}>
            {row.values.col4}
          </Typography.TextS>
          <ButtonEdit onClick={handleEditClick(row.values.col1)}>
            <Typography.TextS>
              Edit task
            </Typography.TextS>
          </ButtonEdit>
          <SubComponentMainData>
            <FirstContainer>
              <FacilityItemContainer>
                <Typography.TextS
                  cssUnique={FirstItemTitle}
                >
                  Category
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {row.values.col5}
                </Typography.TextS>
              </FacilityItemContainer>

              <FacilityItemContainer>
                <Typography.TextS
                  cssUnique={FirstItemTitle}
                >
                  Status
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {data?.status || '—'}
                </Typography.TextS>
              </FacilityItemContainer>

              <FacilityItemContainer>
                <Typography.TextS
                  cssUnique={FirstItemTitle}
                >
                  Est. Annual Savings
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {row.values.col19 ?? '—'}
                </Typography.TextS>
              </FacilityItemContainer>

              <FacilityItemContainer>
                <Typography.TextS
                  cssUnique={FirstItemTitle}
                >
                  Capital Required
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {data?.capitalRequired ?? '—'}
                </Typography.TextS>
              </FacilityItemContainer>

              <FacilityItemContainer>
                <Typography.TextS
                  cssUnique={FirstItemTitle}
                >
                  Create On
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {row.values.col22}
                </Typography.TextS>
              </FacilityItemContainer>

              <FacilityItemContainer>
                <Typography.TextS
                  cssUnique={FirstItemTitle}
                >
                  Last Updated
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {row.values.col23}
                </Typography.TextS>
              </FacilityItemContainer>

            </FirstContainer>

            <LastContainer>
              <FacilityItemContainer>
                <Typography.TextS
                  cssUnique={LastItemTitle}
                >
                  Task Description
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {row.values.col17}
                </Typography.TextS>
              </FacilityItemContainer>

              <FacilityItemContainer>
                <Typography.TextS
                  cssUnique={LastItemTitle}
                >
                  Associated Equipment
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  {row.values.col2}
                </Typography.TextS>
              </FacilityItemContainer>

            </LastContainer>

            <MiddleContainer>
              <FacilityItemContainer notes>
                <Typography.TextS
                  cssUnique={MiddleItemTitle}
                >
                  Notes
                </Typography.TextS>

                <Typography.TextS
                  cssUnique={FacilityItemValue}
                >
                  <div dangerouslySetInnerHTML={{ __html: data?.notes || '—' }} />
                </Typography.TextS>
              </FacilityItemContainer>
            </MiddleContainer>

          </SubComponentMainData>
        </RowSubComponent>
      </TD>
    );
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => <RowSubAsyncComponent row={row} />, [],
  );

  useEffect(() => {
    setCurrentPage(rows);
  }, [pageIndex, rows]);

  const handleOnAcceptClick = (value) => {
    getTasks(value);
  };

  return (
    <TableContainer
      isVisible={isVisible}
    >
      <Table
        isEmpty={currentPage.length > 5}
        {...getTableProps()}
      >
        <THEAD
          isStatusVisible={isStatusVisible}
        >
          {headerGroups.map((headerGroup, i) => (
            <TR
              isOnlyStatus={headerGroup.headers.length === 2}
              {...headerGroup.getHeaderGroupProps()}
              key={i}
            >
              {headerGroup.headers.map((column, i) => (
                <TH
                  {...column.getHeaderProps()}
                  key={i}
                  colSpan={0}
                  active={column.value === sortBy}
                  columnButton={column.value === 'column_button'}
                  expander={column.id === 'expander'}
                  status={column.id === 'statusId'}
                  onClick={(column.value !== 'column_button' && column.value !== 'statusId') ? handleSortClick(column.value) : null}
                  isAssociated={column.value === 'associatedEquipment'}
                >
                  {
                    column.value === 'column_button'
                      ? <FieldDropdown
                        data={columnCheckboxes}
                        width={18}
                        cWidth={230}
                        component={<PlusButtonComponent />}
                        onAcceptClick={handleOnAcceptClick}
                        onChangeVisibility={handleChangeStatusVisibility}
                        isCustomCheckbox
                        exception='column_button'
                        isVisible={isStatusVisible}
                      />
                      : <>
                        <Typography.TextS>
                          {column.render('Header')}
                        </Typography.TextS>
                        {
                          (column.value !== 'column_button' && column.value !== 'statusId')
                          && <SortButtons>
                            <SVG.ArrowTableTop />
                            <SVG.ArrowTableTop />
                          </SortButtons>
                        }
                      </>
                  }
                </TH>
              ))}
            </TR>
          ))}
        </THEAD>
        <TBODY {...getTableBodyProps()}>
          {
            isLoading
              ? <TR isExpanded>
                <TD>
                  <img src={spin} />
                  <Typography.TextS>Loading...</Typography.TextS>
                </TD>
              </TR>
              : !isEmpty(currentPage)
                ? currentPage.map((row) => {
                  prepareRow(row);
                  return (
                    <>
                      <TR
                        {...row.getRowProps()}
                        isSelected={row.isSelected}>
                        {row.cells.map((cell, i) => (
                          <TD
                            {...cell.getCellProps()}
                            columnButton={cell.column.id === 'col26'}
                            isStatusButton={cell.column.id === 'col27'}
                            expander={cell.column.id === 'expander'}
                            key={i}
                            isMaxHeight={cell.column.id === 'col17' || cell.column.id === 'col2'}
                            isTooltip={cell.column.id === 'col17' && cell.value}
                          >
                            <Typography.TextS>
                              {cell.render('Cell')}
                            </Typography.TextS>
                            {
                              cell.column.id === 'col17' && cell.value !== '—'
                              && <StatusHoverContainer>
                                <Typography.TextS cssUnique={TitleDescription}>
                                  {cell.value}
                                </Typography.TextS>
                              </StatusHoverContainer>
                            }
                          </TD>
                        ))
                        }
                      </TR>
                      {row.isExpanded ? (
                        <TR isExpanded>
                          {renderRowSubComponent({ row, colSpan: visibleColumns.length })}
                        </TR>
                      ) : null}
                    </>
                  );
                })
                : <TR isExpanded>
                  <TD>
                    <Typography.TextS>Items were not found</Typography.TextS>
                  </TD>
                </TR>
          }
        </TBODY>
      </Table>
      <TableFooter>
        <Typography.TextS>
          {`Total items: ${meta}`}
        </Typography.TextS>
      </TableFooter>
    </TableContainer>
  );
});

TaskManagerTable.propTypes = {
  initPageSize: PropTypes.number.isRequired,
  tableColumns: PropTypes.array.isRequired,
  tableRows: PropTypes.array.isRequired,
  getTasks: PropTypes.func.isRequired,
  columnCheckboxes: PropTypes.array,
  meta: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

TaskManagerTable.defaultProps = {
  tableColumns: [],
  tableRows: [],
  initPageSize: 10,
  activeRowId: '',
  columnCheckboxes: [],
};

export default withRouter(TaskManagerTable);
