import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';

import {
  LoginContainer,
  LoginInnerContainer,
  LoginTitle,
  LoginFormContainer,
  RegistrationLink,
  StyledInput,
  ButtonSubmit,
  ErrorMessage,
} from './styles';
import { recoveryValidatorsMap } from '../../../utils/validatorsMap';
import SVG from '../SVG';
import recovery from '../../../services/recovery';

const FIELDS = ['email'];

const RecoveryForm = ({ onChangeSuccessStatus }) => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [error, setError] = useState('');
  const [isSubmitBlocked, setIsSubmitBlocked] = useState(true);

  const handleEmailChanges = (value) => {
    setEmail(value);
  };

  const validate = (isButton) => {
    let isValid = true;

    const field = {
      email,
    };

    const fieldChangeState = {
      email: setIsEmailValid,
    };

    FIELDS.forEach((name) => {
      let errorTexts;
      const validators = recoveryValidatorsMap[name];

      validators && validators.forEach((validator) => {
        if (errorTexts) {
          return;
        }

        const filedErrorTexts = validator(field[name]);

        if (!filedErrorTexts) {
          isValid = false;
          errorTexts = filedErrorTexts;
        }
      });

      !isButton && fieldChangeState[name](errorTexts === undefined);
    });
    return !isValid;
  };

  const onBlur = (name) => () => {
    const field = {
      email,
    };

    const fieldChangeState = {
      email: setIsEmailValid,
    };

    let errorTexts;
    const validators = recoveryValidatorsMap[name];

    validators
      && validators.forEach((validator) => {
        if (errorTexts) {
          return;
        }

        const isEmptyString = field[name].trim() === '';
        const filedErrorTexts = isEmptyString || validator(field[name]);

        if (filedErrorTexts) {
          errorTexts = filedErrorTexts;
        }
      });
    setIsSubmitBlocked(validate(true));
    fieldChangeState[name](!!errorTexts);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const isNotValid = validate();

    if (isNotValid) {
      return;
    }

    setError('');
    recovery({
      email,
    })
      .then(({ response }) => {
        if (response.data.message) {
          setError(response.data.message);
          return;
        }
        onChangeSuccessStatus(email);
      });
  };

  return (
    <LoginContainer>
      <LoginInnerContainer>
        <Typography.H5 cssUnique={LoginTitle}>
          Enter your email to request password
        </Typography.H5>
        <LoginFormContainer autoComplete="off" onSubmit={onSubmit}>
          <StyledInput
            labelText="Email Address"
            type="email"
            id="input-email"
            value={email}
            onChange={handleEmailChanges}
            isError={!isEmailValid}
            onBlur={onBlur('email')}
          />

          <ButtonSubmit
            onClick={onSubmit}
            blocked={isSubmitBlocked}
          >
            Request password
          </ButtonSubmit>
          {error && <ErrorMessage>
            <Typography.TextS>
              {error}
            </Typography.TextS>
          </ErrorMessage>}

        </LoginFormContainer>
        <RegistrationLink>
          <Typography.H6>
            <a href='/login'>
              <SVG.ArrowBack />
              Back
            </a>
          </Typography.H6>
        </RegistrationLink>
      </LoginInnerContainer>
    </LoginContainer>
  );
};

RecoveryForm.propTypes = {
  onChangeSuccessStatus: PropTypes.func,
};

export default RecoveryForm;
