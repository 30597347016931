import axios from 'axios';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';

import ROUTES from '../api/routes';
import { FACILITY_NAME, TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';

export default function getTasksDownload({
  columns,
  search = '',
  statusCheck: status,
  categoryCheck: category,
  responsibleCheck: responsibleParty,
  initiatedCheck: initiatedBy,
  limit,
  offse: offset,
  sortSid: order,
  sortB: orderColumn = 'associatedEquipment',
  createdOnFrom,
  createdOnTo,
  updatedOnFrom,
  updatedOnTo,
  value,
}) {
  return (_, getStore) => {
    const { accessToken } = JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME)) || {};
    const facilityName = localStorage.getItem(FACILITY_NAME);

    const orderParsed = order < 0 ? 'desc' : 'asc';
    const { organizationId } = getStore().data;

    const setTimeTo = (date) => {
      const dateTo = new Date(date);
      dateTo.setHours(23, 59, 59);
      return dateTo;
    };

    const params = {
      buildingId: organizationId,
      columns,
      search,
      status,
      category,
      responsibleParty,
      initiatedBy,
      limit,
      offset,
      order: orderParsed,
      orderColumn,
      ...(createdOnFrom && { createdOnFrom: format(new Date(createdOnFrom), 'yyyy-MM-dd HH:mm:ss') }),
      ...(createdOnTo && { createdOnTo: format(setTimeTo(createdOnTo), 'yyyy-MM-dd HH:mm:ss') }),
      ...(updatedOnFrom && { updatedOnFrom: format(new Date(updatedOnFrom), 'yyyy-MM-dd HH:mm:ss') }),
      ...(updatedOnTo && { updatedOnTo: format(setTimeTo(updatedOnTo), 'yyyy-MM-dd HH:mm:ss') }),
    };

    return axios.get(
      `${ROUTES.GET_TASKS}/report/${value}/`,
      {
        headers: {
          accept: 'application/json',
          Authorization: accessToken,
        },
        params,
        responseType: 'blob',
      },
    )
      .then(({ data }) => {
        const date = format(new Date(), 'MM/dd/yyyy');
        fileDownload(data, `${facilityName}-Task manager-${date}.${value === 'docx' ? 'docx' : 'xlsx'}`);
      })
      .catch((err) => err);
  };
}
