import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

// images
import background from './images/back.jpeg';
import LoginForm from '../../components/LoginForm/LoginForm.jsx';
import LogoAuthentication from '../../components/LogoAuthentication/LogoAuthentication.jsx';

const LoginPage = ({ history }) => {
  const isAuthorized = useSelector(({ auth }) => auth.authorized);

  const redirectToMain = () => {
    history.push('/');
  };

  if (isAuthorized) {
    redirectToMain();
  }

  return (
        <Container>
            <LogoAuthentication />
            <LoginForm />
        </Container>
  );
};

export default withRouter(LoginPage);

const Container = styled.div`
    background: url(${background});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;
