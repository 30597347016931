import styled from 'styled-components/macro';
import { COLORS } from '../../../styles/Theme';

export const BlockHeader = styled.div`
    box-shadow: 0px 0px 10px rgba(55, 114, 182, 0.13);
    border-radius: 8px;
    padding: 6px;
    display: flex;
    justify-content: center;
    background:${COLORS.WHITE};

    div {
        color: ${COLORS.BLUE};
        font-weight: bold;
    }
`;

export const BlockContainer = styled.div`
    padding: 0px 24px;
`;
