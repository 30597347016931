import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TASK_STATUSES } from '../../../constants/ui';
import { STATUS_CODE_UNAUTHORIZED } from '../../../constants/constants';
import getTasksLatestAdded from '../../../services/getTasksLatestAdded';
import Typography from '../Typography';
import {
  BlockHeader,
  BlockContainer,
  BlockTableHeader,
  TitleDescription,
  TitleSaving,
  TitleStatus,
  BlockTableContent,
  BlockTableContainer,
} from './styles';

import formatToStringGap from '../../../utils/formatToStringGap';

const LatestAddedBlock = memo(() => {
  const dispatch = useDispatch();
  const organizationId = useSelector(({ data }) => data.organizationId);
  const [addedTasks, setAddedTasks] = useState([]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getTasksLatestAdded())
        .then((data) => {
          if (data.response?.status === STATUS_CODE_UNAUTHORIZED) {
            setAddedTasks([]);
            return;
          }
          setAddedTasks(data);
        });
    }
  }, [organizationId]);

  return (
    <>
      <BlockHeader>
        <Typography.TextS>
          Latest added tasks
        </Typography.TextS>
      </BlockHeader>
      <BlockContainer>
        <BlockTableHeader>
          <TitleDescription>
            <Typography.TextS>
              Task Description
            </Typography.TextS>
          </TitleDescription>
          <TitleSaving>
            <Typography.TextS>
              Saving ($)
            </Typography.TextS>
          </TitleSaving>
          <TitleStatus>
            <Typography.TextS>
              Status
            </Typography.TextS>
          </TitleStatus>
        </BlockTableHeader>
        <BlockTableContent>
          {
            addedTasks.map(({
              id, title, saving, taskStatusTypeId,
            }) => (
              <BlockTableContainer key={id}>
                <TitleDescription>
                  <Typography.TextS>
                    {title}
                  </Typography.TextS>
                </TitleDescription>
                <TitleSaving>
                  <Typography.TextS>
                    {formatToStringGap(saving)}
                  </Typography.TextS>
                </TitleSaving>
                <TitleStatus>
                  {TASK_STATUSES[taskStatusTypeId]}
                </TitleStatus>
              </BlockTableContainer>
            ))
          }
        </BlockTableContent>
      </BlockContainer>
    </>
  );
});

export default LatestAddedBlock;
