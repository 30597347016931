import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SVG from '../SVG';
import Typography from '../Typography';

import {
  InputWrapper,
  Label,
  InputEl,
  CustomContainer,
  CustomCheckbox,
  SVGContainer,
  SVGContainerQuenstion,
  ErrorModalContainer,
  ErrorModal,
  ErrorModalTitle,
  TextAreaEl,
  Description,
} from './styles';

const PASSWORD_TYPE = 'password';

export const CB = ({
  value, name, onChange, type, ...rest
}) => (
  <CustomContainer value={value}>
    <CustomCheckbox
      type={type || 'checkbox'}
      name={name}
      value={value}
      onChange={onChange}
      {...rest}
    />
  </CustomContainer>
);

CB.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export const TextArea = ({
  id,
  labelText,
  placeholder,
  className,
  onChange,
  onBlur,
  isError,
  validation,
  onFocus,
  value: inputValue,
}) => {
  const [value, setValue] = useState(inputValue || '');
  const [isValid, setIsValid] = useState(!isError);
  const [, setIsEmptyValue] = useState(value.trim() === '');

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const validate = (e) => {
    const { value } = e.target;
    const isEmptyValue = value.trim() === '';

    const isValid = isEmptyValue || validation && validation(value);
    setIsValid(isValid);
    onBlur(value, isValid);
    setIsEmptyValue(isEmptyValue);
  };

  return <InputWrapper isValid={!isError} className={className}>
    <TextAreaEl
      id={id}
      value={value}
      isValid={isValid}
      onChange={handleChange}
      onBlur={validate}
      placeholder={placeholder}
      validation={validation}
      onFocus={onFocus}
      autoComplete="new-password"
      maxLength="250"
    />
    <Label htmlFor={id} isValid={isValid}>
      {labelText}
    </Label>
    <Description>
      {`${value.length} / 250 characters max`}
    </Description>
  </InputWrapper>;
};

export const Input = ({
  id,
  labelText,
  type,
  placeholder,
  errorMessage,
  className,
  onChange,
  onBlur,
  isError,
  validation,
  onFocus,
  value: inputValue,
}) => {
  const [value, setValue] = useState(inputValue || '');
  const [isValid, setIsValid] = useState(!isError);
  const [, setIsEmptyValue] = useState(value.trim() === '');
  const [isHidePassword, setIsHidePassword] = useState(true);
  const isMozillaBrowser = useMemo(() => typeof InstallTrigger !== 'undefined', []);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const handleShowPassword = () => {
    setIsHidePassword(!isHidePassword);
  };

  const validate = (e) => {
    const { value } = e.target;
    const isEmptyValue = value.trim() === '';

    switch (type) {
      case 'email': {
        const isValid = isEmptyValue || /^[\w-\.]+@([\w-]+\.)+[\w-]{2, 4}$/g.test(value);
        setIsValid(isValid);
        onBlur(value, isValid);
        setIsEmptyValue(isEmptyValue);
        break;
      }
      case 'password':
      default: {
        const isValid = isEmptyValue || validation && validation(value);
        setIsValid(isValid);
        onBlur(value, isValid);
        setIsEmptyValue(isEmptyValue);
        break;
      }
    }
  };

  return (
    <InputWrapper isValid={!isError} className={className}>
      <InputEl
        id={id}
        type={!isMozillaBrowser ? 'text' : isHidePassword ? type : 'text'}
        value={value}
        isValid={isValid}
        onBlur={validate}
        onChange={handleChange}
        placeholder={placeholder}
        validation={validation}
        onFocus={onFocus}
        autoComplete="off"
        typeCustom={isHidePassword ? type : 'text'}
      />
      <Label htmlFor={id} isValid={isValid}>
        {labelText}
      </Label>
      {type === PASSWORD_TYPE && (
        <SVGContainer isValid={isValid} onClick={handleShowPassword}>
          {isHidePassword ? <SVG.HideIcon /> : <SVG.ShowIcon />}
        </SVGContainer>
      )}
      {type === PASSWORD_TYPE && (
        <>
          <SVGContainerQuenstion isValid={isError}>
            <SVG.QuestionIcon />
          </SVGContainerQuenstion>
          <ErrorModal>
            <ErrorModalContainer>
              <Typography.TextS cssUnique={ErrorModalTitle}>
                Password must be of minimum 8 characters length and contain at least 1 number
              </Typography.TextS>
            </ErrorModalContainer>
          </ErrorModal>
        </>
      )}
      <span>{errorMessage}</span>
    </InputWrapper>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isError: PropTypes.bool,
  validation: PropTypes.func,
  onFocus: PropTypes.func,
  isEmptyValue: PropTypes.bool,
};

Input.defaultProps = {
  onChange: () => { },
  onBlur: () => { },
};
