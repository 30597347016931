import React from 'react';
import { COLORS } from '../styles/Theme';
import Statuses from '../ui/components/Statuses';

export const LABELS_MONTH = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const CALENDAR_DATA = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const BACKGROUND_COLOR = [
  'rgba(238, 100, 133, 0.1)',
  'rgba(255, 168, 65, 0.1)',
  'rgba(69, 170, 176, 0.1)',
  'rgba(142, 108, 237, 0.1)',
];

export const BORDER_COLOR = [
  COLORS.ORANGE_DARK,
  COLORS.RED_LIGHT,
  COLORS.GREEN_DARK,
  COLORS.PURPLE,
];

export const TASK_STATUSES = {
  6: <Statuses.CompletedStatus />,
  1: <Statuses.NewStatus />,
  3: <Statuses.PendingStatus />,
  12: <Statuses.ContinueStatus />,
  4: <Statuses.PauseStatus />,
  5: <Statuses.CancelStatus />,
};

export const DOWNLOAD_DATA = [
  {
    id: 0, name: 'XLS', value: 'xls',
  },
  {
    id: 1, name: 'DOCX', value: 'docx',
  },
];

export const DOWNLOAD_DATA_SAVINGS = [
  {
    id: 0, name: 'XLSX', value: 'xlsx',
  },
  {
    id: 1, name: 'PPTX', value: 'pptx',
  },
];

export const SELECT_NUMBER_DATA = [
  {
    id: 0, value: 10, label: '10',
  },
  {
    id: 1, value: 25, label: '25',
  },
  {
    id: 2, value: 50, label: '50',
  },
  {
    id: 3, value: 100, label: '100',
  },
  {
    id: 4, value: 250, label: '250',
  },
];

export const COLUMNS_TASK_MANAGER = [
  {
    isChecked: true,
  },
  {
    id: 'id', name: 'Identifier', isChecked: true,
  },
  {
    id: 'associatedEquipment', name: 'Associated Equipment', isChecked: true,
  },
  {
    id: 'dynamicId', name: 'Dynamic ID', isChecked: false,
  },
  {
    id: 'title', name: 'Task', isChecked: true,
  },
  {
    id: 'statusId', name: 'Status', isChecked: true,
  },
  {
    id: 'categoryName', name: 'Category', isChecked: true,
  },
  {
    id: 'statusName', name: 'Status Name', isChecked: false,
  },
  {
    id: 'lastUserName', name: 'Last User Name', isChecked: false,
  },
  {
    id: 'manager', name: 'Manager', isChecked: false,
  },
  {
    id: 'assignedPerson', name: 'Assigned Person', isChecked: false,
  },
  {
    id: 'estImplementationOnSite', name: 'Est. Implementation On-Site Labor', isChecked: false,
  },
  {
    id: 'customGroup', name: 'Custom Group', isChecked: false,
  },
  {
    id: 'completedDate', name: 'Completed Date', isChecked: true,
  },
  {
    id: 'capitalRequired', name: 'Capital Required', isChecked: false,
  },
  {
    id: 'dateEntered', name: 'Date Entered', isChecked: false,
  },
  {
    id: 'expectedCompletion', name: 'Expected Completion Date', isChecked: false,
  },
  {
    id: 'taskSource', name: 'Task Source', isChecked: false,
  },
  {
    id: 'taskDescription', name: 'Task Description', isChecked: true,
  },
  {
    id: 'estImplementationCost', name: 'Est. Implementation Cost', isChecked: false,
  },
  {
    id: 'saving', name: 'Est. Annual Savings', isChecked: true,
  },
  {
    id: 'mappedItems', name: 'Mapped Items', isChecked: false,
  },
  {
    id: 'rebates', name: 'Rebates', isChecked: false,
  },
  {
    id: 'createdOn', name: 'Created On', isChecked: true,
  },
  {
    id: 'lastUpdated', name: 'Last Updated', isChecked: true,
  },
  {
    id: 'responsiblePartyName', name: 'Responsible Party', isChecked: true,
  },
  {
    id: 'initiatedByName', name: 'Initiated By', isChecked: true,
  },
  {
    id: 'column_button', isChecked: true,
  },
];

export const STATUS_DATA = [
  {
    id: 10, name: 'On Hold Seasonal Summer', isChecked: false,
  },
  {
    id: 11, name: 'On Hold Seasonal Winter', isChecked: false,
  },
  {
    id: 12, name: 'Continue to monitor', isChecked: false,
  },
  {
    id: 6, name: 'Completed', isChecked: false,
  },
  {
    id: 14, name: 'Within summary', isChecked: false,
  },
  {
    id: 15, name: 'Pending verification', isChecked: false,
  },
  {
    id: 1, name: 'New', isChecked: false,
  },
  {
    id: 3, name: 'In progress', isChecked: false,
  },
];

export const STATUS_DATA2 = [
  {
    id: 1, value: 'New', label: 'New',
  },
  {
    id: 3, value: 'In progress', label: 'In progress',
  },
  {
    id: 4, value: 'On Hold', label: 'On Hold',
  },
  {
    id: 5, value: 'Canceled', label: 'Canceled',
  },
  {
    id: 6, value: 'Completed', label: 'Completed',
  },
  {
    id: 15, value: 'Pending verification', label: 'Pending verification',
  },
];

export const RESPONSIBLE_DATA = [
  {
    id: 'C', name: 'Facility', isChecked: false,
  },
  {
    id: 'A', name: 'AEA', isChecked: false,
  },
  {
    id: 'B', name: 'Facility/AEA', isChecked: false,
  },
  {
    id: 'O', name: 'Other', isChecked: false,
  },
];

export const INITIATED_DATA = [
  {
    id: 'C', name: 'Facility', isChecked: false,
  },
  {
    id: 'A', name: 'AEA', isChecked: false,
  },
];

export const CATEGORY_DATA = [
  {
    id: 1, name: 'Legacy', label: 'Legacy', value: 'Legacy', isChecked: false,
  },
  {
    id: 2, name: 'Chiller staging', label: 'Chiller staging', value: 'Chiller staging', isChecked: false,
  },
  {
    id: 3, name: 'Valve repair', label: 'Valve repair', value: 'Valve repair', isChecked: false,
  },
  {
    id: 5, name: 'Damper repair', label: 'Damper repair', value: 'Damper repair', isChecked: false,
  },
  {
    id: 6, name: 'Programming', label: 'Programming', value: 'Programming', isChecked: false,
  },
  {
    id: 9, name: 'Cost avoidance', label: 'Cost avoidance', value: 'Cost avoidance', isChecked: false,
  },
  {
    id: 13, name: 'Sensors/controls', label: 'Sensors/controls', value: 'Sensors/controls', isChecked: false,
  },
  {
    id: 14, name: 'VFD', label: 'VFD', value: 'VFD', isChecked: false,
  },
  {
    id: 17, name: 'To be identified', label: 'To be identified', value: 'To be identified', isChecked: false,
  },
  {
    id: 18, name: 'Capital project', label: 'Capital project', value: 'Capital project', isChecked: false,
  },
  {
    id: 20, name: 'On site BAS', label: 'On site BAS', value: 'On site BAS', isChecked: false,
  },
  {
    id: 21, name: 'Insulation', label: 'Insulation', value: 'Insulation', isChecked: false,
  },
  {
    id: 22, name: 'Steam/Hot water', label: 'Steam/Hot water', value: 'Steam/Hot water', isChecked: false,
  },
];
