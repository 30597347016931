import React from 'react';
import styled, { css } from 'styled-components/macro';
import { COLORS, TRANSITIONS } from '../../styles/Theme';
import SVG from './SVG';
import Typography from './Typography';

const CompletedStatus = () => (
    <StatusMainContainer>
        <StatusContainer color={COLORS.GREEN_DARK}>
            <SVG.CompletedStatus />
        </StatusContainer>
        <StatusHoverContainer>
            <Typography.TextS cssUnique={TitleDescription}>
                Task completed
            </Typography.TextS>
        </StatusHoverContainer>
    </StatusMainContainer>
);

const NewStatus = () => (
    <StatusMainContainer>
        <StatusContainer color={COLORS.LIGHT_BLUE2}>
            <SVG.NewStatus />
        </StatusContainer>
        <StatusHoverContainer>
            <Typography.TextS cssUnique={TitleDescription}>
                Task has been created/opened but not acknowledged
            </Typography.TextS>
        </StatusHoverContainer>
    </StatusMainContainer>
);

const PendingStatus = () => (
    <StatusMainContainer>
        <StatusContainer color={COLORS.BLUE}>
            <SVG.PendingStatus />
        </StatusContainer>
        <StatusHoverContainer>
            <Typography.TextS cssUnique={TitleDescription}>
                Task in progress
            </Typography.TextS>
        </StatusHoverContainer>
    </StatusMainContainer>
);

const ContinueStatus = () => (
    <StatusMainContainer>
        <StatusContainer color={COLORS.PURPLE}>
            <SVG.ContinueStatus />
        </StatusContainer>
        <StatusHoverContainer>
            <Typography.TextS cssUnique={TitleDescription}>
                Continue to Monitor
            </Typography.TextS>
        </StatusHoverContainer>
    </StatusMainContainer>
);

const PauseStatus = () => (
    <StatusMainContainer>
        <StatusContainer color={COLORS.YELLOW}>
            <SVG.PauseStatus />
        </StatusContainer>
        <StatusHoverContainer>
            <Typography.TextS cssUnique={TitleDescription}>
                Task is held up, see comments
            </Typography.TextS>
        </StatusHoverContainer>
    </StatusMainContainer>
);

const CancelStatus = () => (
    <StatusMainContainer>
        <StatusContainer color={COLORS.RED_LIGHT}>
            <SVG.CancelStatus />
        </StatusContainer>
        <StatusHoverContainer>
            <Typography.TextS cssUnique={TitleDescription}>
                Task canceled
            </Typography.TextS>
        </StatusHoverContainer>
    </StatusMainContainer>
);

export default {
  CompletedStatus,
  NewStatus,
  PendingStatus,
  ContinueStatus,
  PauseStatus,
  CancelStatus,
};

const StatusContainer = styled.div`
    width: 48px;
    height: 21px;
    border-radius: 8px;
    display: flex;    
    justify-content: center;
    align-items: center;
    position: relative;

    ${({ color }) => color && css`
        background: ${color};
    `};

    &:hover + div {
        opacity: 1;
        pointer-events: auto;
    }
`;

const StatusHoverContainer = styled.div`
    padding: 4px 8px;
    background: #C4C4C4;
    border-radius: 8px;
    position: absolute;
    right: calc(-25% - 4px);
    bottom: 28px;
    opacity: 0;
    pointer-events: none;
    transition: ${TRANSITIONS.FAST};
    width: max-content;
    background: rgba(44, 66, 92, 0.77);
    max-width: 200px;
    z-index: 13;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 0;
        bottom: -12px;
        width: 0;
        right: 34px;
        border: 6px solid transparent;
        border-bottom-color: rgba(44, 66, 92, 0.77);
        transform: rotatez(-180deg);
    }
`;

const TitleDescription = css`
    font-size: 12px;
    color: ${COLORS.WHITE};
`;

const StatusMainContainer = styled.div`
    position: relative;
`;
