import axios from 'axios';
import ROUTES from '../api/routes';
import { TOKEN_CLIENT_LOCAL_STORAGE_NAME } from '../constants/constants';

export default function getTasksLatestAdded() {
  return (_, getStore) => {
    const { accessToken } = JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOCAL_STORAGE_NAME)) || {};
    const { organizationId } = getStore().data;

    return axios.get(
      `${ROUTES.GET_TASKS}/latest-added/?buildingId=${organizationId}&limit=6`,
      {
        headers: {
          accept: 'application/json',
          Authorization: accessToken,
        },
      },
    )
      .then(({ data }) => data)
      .catch((err) => err);
  };
}
